import React, { useState } from 'react'
import { FormField } from '../../../app/model/form-fields';
import { DynamicForm } from '../../form-builder/form';

export const Shipping = () => {
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [formData, setFormData] = useState<FormField[]>([
        {
            title: "input",
            type: "date",
            label: "Pickup Date",
            name: "pickup_date",
            formFloating: false,
            value: '',
            min: '',
            required: true,
            placeholder: "Please select pickup date",
            error: "Please select pickup date",
            hideMandatoryStar: false,
        },
        {
            title: "input",
            type: "time",
            label: "Pickup Time",
            name: "pickup_time",
            formFloating: false,
            value: '',
            min: '',
            required: true,
            placeholder: "Please select pickup time",
            error: "Please select pickup time",
            hideMandatoryStar: false,
        },
        // {
        //     type: "button",
        //     class: "col-md-12 d-flex justify-content-end my-2",
        //     buttons: [
        //         {
        //             title: 'Create',
        //             action: 'add',
        //             isCheckFormValidation: true,
        //             class: 'btn btn-primary px-4 btn-sm border-0 f16 me-1'
        //         },
        //         {
        //             title: 'Cancel',
        //             action: 'cancel',
        //             class: 'btn btn-outline-primary px-4 btn-sm f16'
        //         }
        //     ]
        // },
    ])

    const onFormSubmitEvent = (event: any) => {
        const data = event;
    }
    return (
        <div className='row'>
            <DynamicForm
                userFormData={formData}
                columns={2}
                isEdit={isEdit}
                onFormSubmitEvent={(e) => onFormSubmitEvent(e)}
                customClass={"display-block"}
                isNotEmptyFormAfterSubmit={true} />
        </div>
    )
}
