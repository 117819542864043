import React, { useEffect, useRef, useState } from 'react'
import { EmailTemplatesRoutes } from '../../../routes/email-templates-routes';

export const EmailTemplatesPage = () => {


    return (
        <div className='container-fluid content-bg-color'>
            <EmailTemplatesRoutes />
        </div>
    )
}
