import { DataTableCol } from "../../../components/data-table/types";

export const PreviousOrdersDataGridCols: DataTableCol[] = [
    {
        title: 'Order ID',
        control: 'order_id',
        sortable: true,
        canShowColumn: true,
    },
    {
        title: 'Customer name',
        control: 'customer_name',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Service Type',
        control: 'service_type',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Date',
        control: 'date',
        isDateFormat: true,
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Price',
        control: 'price',
        sortable: true,
        canShowColumn: true,
        isPrice: true,
    },

    {
        title: 'Tag',
        control: 'tag',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Delivery Address',
        control: 'delivery_address',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Status',
        control: 'status',
        isStatus: true,
        sortable: true,
        canShowColumn: true
    },
];