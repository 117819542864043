import React, { useEffect, useState } from 'react'
import { DMVErrorReportsDataGridCols } from './dmv-reports-data-grid-cols';
import { Loader } from '../../../../components/loader';
import { Search } from '../../../../components/search/search';
import { DataTable } from '../../../../components/data-table';
import { useNavigate } from 'react-router-dom';
import { DMVErrorsReportsResponse } from '../../../../app/model/dmv-error-resports.response';
import { GetParamsData } from '../../../../app/model/common';

export const DmvErrorReports = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [tableListData, setTableListData] = useState<DMVErrorsReportsResponse[]>([]);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [selectedData, setSelectedData] = useState<any>([]);
    const navigation = useNavigate();
    const [pageCount, setPageCount] = useState<GetParamsData>({
        limit: 10,
        page: 0,
    });

    const staticTableData = {
        total: 10,
        rows: [
            {
                service_name: "New vehicle registration",
                error: "dmv error",
                description: "dmv error checking",
                status: "Active"
            },
            {
                service_name: "Vehicle renewal",
                error: "dmv error",
                description: "dmv error checking",
                status: "In Active"
            },
        ]
    }

    useEffect(() => {
        getDMVErrorReports();
    }, [])

    const getDMVErrorReports = () => {
        // setLoading(true);
        // GET_DMV_ERROR_REPORTS(pageCount).then((res: any) => {
        //     if (res?.status === "fail") {
        // toast.error(res.message, { position: 'top-right' })
        //         setLoading(false);
        //     } else {
        //         if (res) {
        //             setTableListData(res?.data);
        //             setLoading(false);
        //         }
        //     }
        // });
    }

    const onActions = (data: any) => {
        setSelectedData(data?.data)
    }

    const onPageChange = (data: any) => {
        const pageData = pageCount;
        pageData.limit = data;
        setPageCount(pageData);
    };

    const onSelectPage = (data: any) => {
        const pageData = pageCount;
        pageData.page = data;
        setPageCount(pageData);
    };

    const onSearch = (value: any) => {
        const pageData = pageCount;
        pageData.search = value;
        setPageCount(pageData);
    }

    return (
        <div className='container-fluid content-bg-color'>
            <div className='col-12 w-100 mt-2 pt-2'>
                <Search onSearch={(e) => onSearch(e)} customSearchCss={"col-md-3"} />
            </div>
            <div className='row'>
                <div className='col-md-12'>
                    <Loader LoadingReducer={loading} />
                    <DataTable
                        tableData={staticTableData}
                        TableCols={DMVErrorReportsDataGridCols}
                        onActions={(e: any) => onActions(e)}
                        onPageChange={(e: any) => onPageChange(e)}
                        onSelectPageChange={(e) => onSelectPage(e)}
                        pageCount={pageCount}
                    />
                </div>
            </div>
        </div>
    )
}
