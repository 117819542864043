import { DataTableCol } from "../../../../components/data-table/types";

export const AbandonedCartOderDetailsDataGridCols: DataTableCol[] = [
    // {
    //     title: 'Order ID',
    //     control: 'order_id',
    //     sortable: true,
    //     canShowColumn: true,
    // },
    // {
    //     title: 'Customer Name',
    //     control: 'customer_name',
    //     sortable: true,
    //     canShowColumn: true
    // },
    // {
    //     title: 'Customer Email',
    //     control: 'customer_email',
    //     sortable: true,
    //     canShowColumn: true
    // },
    // {
    //     title: 'Customer Mobile Number',
    //     control: 'customer_mobile_number',
    //     sortable: true,
    //     canShowColumn: true
    // },

    {
        title: 'Price',
        control: 'total_price',
        sortable: true,
        canShowColumn: true,
        isPrice: true,
    },
    {
        title: 'Vehicle Details',
        control: 'vehicle_details',
        isAbandonedCartVehicleDetails: true,
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'DMV Details',
        control: 'dmv_details',
        isShowDmvDetails:true,
        sortable: true,
        canShowColumn: true
    },
    // {
    //     title: 'Address',
    //     control: 'address',
    //     sortable: true,
    //     canShowColumn: true
    // },
    // {
    //     title: 'Flow Status',
    //     control: 'flow_status',
    //     isLink: true,
    //     sortable: true,
    //     canShowColumn: true
    // },
    // {
    //     title: 'Status',
    //     control: 'status',
    //     isUserActivityStatus: true,
    //     sortable: true,
    //     canShowColumn: true
    // },
    // {
    //     title: 'Actions',
    //     control: 'both',
    //     sortable: false,
    //     isSend: true,
    //     isView: false,
    //     canShowColumn: true
    // },
];