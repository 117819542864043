// export const BASE_URL = "http://localhost:7799/";
// export const BASE_URL = "http://192.168.1.52:7799/";
export const ORDERS_BASE_URL = "http://54.85.190.77:7799/";
export const BASE_URL = "https://api.simpletags.com/";
// export const GA_MEASUREMENT_ID ='G-86FWS7W1ZH'


const appConfig = {
  BASE_URL: BASE_URL,
  ORDERS_BASE_URL: ORDERS_BASE_URL
  // GA_MEASUREMENT_ID: GA_MEASUREMENT_ID
};
export default appConfig;
