import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { UserActivityReports } from '../pages/dashboard/reports/ga-reports/user-activity-reports/user-activity-reports'
import { CampaignTracking } from '../pages/dashboard/reports/ga-reports/campaign-tracking/campaign-tracking'

export const GAReportsTabRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<UserActivityReports />}></Route>
            <Route path="/user-activity-reports" element={<UserActivityReports />}></Route>
            <Route path="/campaign-tracking" element={<CampaignTracking />}></Route>
        </Routes>
    )
}
