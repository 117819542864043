import { DataTableCol } from "../../../components/data-table/types";

export const ConfigurationDataGridCols: DataTableCol[] = [
    {
        title: 'Type',
        control: 'type',
        sortable: true,
        // isProfileImage:true,
        canShowColumn: true,
    },
    {
        title: 'Code',
        control: 'code',
        sortable: true,
        canShowColumn: true
    },
    // {
    //     title: 'Details',
    //     control: 'details',
    //     isShowConfigDetails:true,
    //     sortable: true,
    //     canShowColumn: true
    // },
    {
        title: 'Status',
        control: 'status',
        isUserActivityStatus: true,
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Actions',
        control: 'both',
        sortable: false,
        isEdit: true,
        isDelete: true,
        isView: false,
        canShowColumn: true
    },
];