import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { DataTable } from '../../../components/data-table';
import { Loader } from '../../../components/loader';
import { AuditLogsDataGridCols } from './audit-logs-data-grid-cols';
import { Search } from '../../../components/search/search';
import { DynamicForm } from '../../../components/form-builder/form';
import { GetParamsData } from '../../../app/model/common';
import { GET_AUDIT_LOGS } from '../../../app/services/audit-logs.service';

export const AuditLogs = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [tableListData, setTableListData] = useState<any>([]);
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
    const [showCreatePopup, setShowCreatePopup] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [selectedData, setSelectedData] = useState<any>([]);
    const navigation = useNavigate();
    const [pageCount, setPageCount] = useState<GetParamsData>({
        limit: 10,
        page: 0,
    });
    const [formData, setFormData] = useState<any>([
        {
            title: "input",
            type: "text",
            label: "Audit Log Name",
            name: "log_name",
            formFloating: false,
            value: '',
            regex: "",
            required: true,
            placeholder: "Please enter audit log name",
            error: "Please enter audit log name",
            hideMandatoryStar: false,
        },
        {
            title: "text-area",
            type: "text",
            label: "Description",
            name: "description",
            formFloating: false,
            value: '',
            regex: '',
            required: false,
            placeholder: "Please enter description",
            error: "Please enter description",
            hideMandatoryStar: true,
        },
        {
            type: "button",
            class: "col-md-12 d-flex justify-content-end my-2",
            buttons: [
                {
                    title: 'Create',
                    action: 'add',
                    isCheckFormValidation: true,
                    class: 'btn btn-primary px-4 btn-sm border-0 f16 me-1'
                },
                {
                    title: 'Cancel',
                    action: 'cancel',
                    class: 'btn btn-outline-primary px-4 btn-sm f16'
                }
            ]
        },
    ])

    const staticTableData = {
        total: 10,
        rows: [
            {
                id: "1",
                type: "audit type one",
                customer_name: "prasanth",
                service_type: "New Registration",
                description: "information logs"
            },
            {
                id: "2",
                type: "audit type two",
                customer_name: "ravi",
                service_type: "Renewal",
                description: "application version logs"
            },
        ]
    }

    useEffect(() => {
        getAuditLogs(pageCount);
    }, [])

    const getAuditLogs = (pageData: any) => {
        setLoading(true);
        GET_AUDIT_LOGS(pageData).then((res: any) => {
            if (res?.status === "fail") {
                toast.error(res.message, { position: 'top-right' })
                setLoading(false);
            } else {
                if (res) {
                    res?.data?.data?.forEach((el:any) =>{
                        el.customer_name = el?._user?.name
                    })
                    setTableListData(res?.data);
                    setLoading(false);
                }
            }
        });
    }

    const onShowCreatePopup = () => {
        setIsEdit(false);
        setShowCreatePopup(true);
        const categoryFormData = formData;
        categoryFormData?.forEach((element: any) => {
            if (element?.type === "button") {
                for (let i = 0; i < element?.buttons?.length; i++) {
                    const ele = element?.buttons;
                    ele[0].title = "Create";
                    ele[0].action = "add";
                }
            }
            element.value = "";
        })
        setFormData([...formData])
    }

    const onActions = (data: any) => {
        setSelectedData(data?.data)
        if (data?.type === "Edit") {
            setIsEdit(true);
            setShowCreatePopup(true);
            const formDataObj = formData;
            formDataObj?.forEach((element: any) => {
                if (element?.type === "button") {
                    for (let i = 0; i < element?.buttons?.length; i++) {
                        const ele = element?.buttons;
                        ele[0].title = "Update";
                        ele[0].action = "edit";
                    }
                }
                element.value = data?.data[element.name]
            });
            setFormData([...formDataObj]);
        } else if (data?.type === "Delete") {
            setShowDeletePopup(true);
        }
    }

    const onPageChange = (data: any) => {
        const pageData = pageCount;
        pageData.limit = data;
        setPageCount(pageData);
        getAuditLogs(pageData);
    };

    const onSelectPage = (data: any) => {
        const pageData = pageCount;
        pageData.page = data;
        setPageCount(pageData);
        getAuditLogs(pageData);
    };

    const onSearch = (value: any) => {
        const pageData = pageCount;
        pageData.search = value;
        setPageCount(pageData);
        getAuditLogs(pageData);
    }

    const onDeleteUserConfirmation = () => {
        // DELETE_AUDIT_LOG(selectedData?.uuid).then((res: any) => {
        //     if (res.status === "error") {
        //         toast.error(res.error, { position: "top-right" });
        //         setLoading(false);
        //     } else {
        //         if (res) {
        //             setShowDeletePopup(false);
        //             toast.success(res.message, { position: 'top-right', })
        //             setLoading(false);
        //             getRoles();
        //         }
        //     }
        // });
        // setShowDeletePopup(false);
        toast.success("Deleted Successfully", { position: 'top-right' })
    }

    const onChangeDiscountAmount = (data: any) => {
        if (data?.name === "discount_amount") {
            const data = formData;
            data[3].value = data[1].value - data[2].value
            setFormData([...data])
        }
    }

    const onFormSubmitEvent = (event: any) => {
        const data = event;
        if (data?.type === "add") {
            const formData = data?.formData;
            formData.finalPrice = data?.formData?.price - data?.formData?.discount_amount
            delete formData['undefined']
            // setLoading(true);
            // CREATE_AUDIT_LOG(formData).then((res: any) => {
            //     if (res.status === "fail") {
            //         toast.error(res.error, { position: "top-right" });
            //         setLoading(false);
            //     } else {
            //         if (res) {
            //             setShowCreatePopup(false);
            //             toast.success(res.message, { position: 'top-right', })
            //             setLoading(false);
            //             getUsers();
            //         }
            //     }
            // });
        } else if (data?.type === "edit") {
            const formData = data?.formData
            delete formData['undefined']
            // UPDATE_AUDIT_LOG(selectedData?.uuid, formData).then((res: any) => {
            //     if (res.status === "fail") {
            //         toast.error(res.error, { position: "top-right" });
            //         setLoading(false);
            //     } else {
            //         if (res) {
            //             setShowCreatePopup(false);
            //             toast.success(res.message, { position: 'top-right', })
            //             setLoading(false);
            //             getCountriesData();
            //         }
            //     }
            // });
        } else if (data?.type === "cancel") {
            setShowCreatePopup(false)
        }
    }

    return (
        <div className='container-fluid content-bg-color'>
            <div className='row border-bottom-primary'>
                <div className='col-md-9 text-start d-flex align-items-center px-0'>
                    <h4>Audit Logs</h4>
                </div>
                {/* <div className='col-md-3 text-end my-2 px-0'>
                    <button className='btn btn-primary border-0' onClick={onShowCreatePopup}>Create Audit Log</button>
                </div> */}
            </div>
            <div className='col-12 w-100 mt-4 pt-1'>
                <Search onSearch={(e) => onSearch(e)} customSearchCss={"col-md-3 px-0"} />
            </div>
            <div className='row'>
                <div className='col-md-12 px-0'>
                    <Loader LoadingReducer={loading} />
                    <DataTable
                        tableData={tableListData}
                        TableCols={AuditLogsDataGridCols}
                        onActions={(e: any) => onActions(e)}
                        onPageChange={(e: any) => onPageChange(e)}
                        onSelectPageChange={(e) => onSelectPage(e)}
                        pageCount={pageCount}
                    />
                </div>
                {showCreatePopup && (
                    <div className={`modal modal-custom fade ${showCreatePopup ? "show" : "false"}  ${showCreatePopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-lg modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header border-bottom-primary mx-4 px-0">
                                    <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">{isEdit ? "Edit" : "Create"} Audit Log</strong></h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowCreatePopup(false)}></button>
                                </div>
                                <div className="modal-body px-4 text-start">
                                    <div className='row'>
                                        <DynamicForm
                                            userFormData={formData}
                                            columns={2}
                                            isEdit={isEdit}
                                            onFormSubmitEvent={(e) => onFormSubmitEvent(e)}
                                            onParentDropdownEvent={(e) => onChangeDiscountAmount(e)}
                                            customClass={"display-block"}
                                            isNotEmptyFormAfterSubmit={true} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {showDeletePopup && (
                    <>
                        <div className={`modal modal-custom fade ${showDeletePopup ? "show" : "false"}  ${showDeletePopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
                            <div className="modal-dialog modal-md modal-dialog-centered">
                                <div className="modal-content text-start">
                                    <div className="modal-header border-bottom-primary mx-4 px-0">
                                        <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">Delete</strong></h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowDeletePopup(false)}></button>
                                    </div>
                                    <div className="modal-body pb-5">
                                        <div className="row px-2">
                                            <div className="col-md-12">
                                                <div className="mb-3">
                                                    <p className="f16 p-2">Are you sure you want to delete <span className='fw-bold '>{selectedData?.type || ""}</span> ?</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='px-2 text-end'>
                                            <button type="button" className="btn btn-primary px-4 btn-sm border-0 f16 me-3" onClick={onDeleteUserConfirmation}>Yes</button>
                                            <button type="button" className="btn btn-outline-primary px-4 btn-sm f16 text-primary-medium border-primary-medium" onClick={() => setShowDeletePopup(false)}>No</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}
