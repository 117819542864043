import React, { useEffect, useState } from 'react'
import { Search } from '../../../../../components/search/search'
import { useNavigate, useParams } from 'react-router-dom';
import { Loader } from '../../../../../components/loader';
import { DataTable } from '../../../../../components/data-table';
import { UsersAddressDataGridCols } from './user-address-data-grid-cols';
import { UserAddressResponse } from '../../../../../app/model/addresses-response';
import { GetParamsData } from '../../../../../app/model/common';
import { GET_USER_ADDRESSES } from '../../../../../app/services/user-address.service';
import toast from 'react-hot-toast';

export const UserAddress = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [tableListData, setTableListData] = useState<UserAddressResponse[]>([]);
  const navigation = useNavigate();
  const params = useParams();
  const [pageCount, setPageCount] = useState<GetParamsData>({
    limit: 10,
    page: 0,
  });

  const staticTableData = {
    total: 10,
    rows: [
      {
        id: "11",
        company_name: "Company",
        street_address: "Street one",
        unit: "12",
        city: "Hyderabad",
        state: "Telangana",
        zip_code: "500082"
      },
      {
        id: "12",
        company_name: "Test Company ",
        street_address: "Street two",
        unit: "13",
        city: "Hyderabad",
        state: "Telangana",
        zip_code: "500072"
      },
    ]
  }

  useEffect(() => {
    getUserAddresses(pageCount);
  }, [])

  const getUserAddresses = (pageData: any) => {
    const data = pageData
    data.user_uuid = params?.user_uuid
    setLoading(true);
    GET_USER_ADDRESSES(data).then((res: any) => {
      if (res?.status === "fail") {
        toast.error(res.message, { position: 'top-right' })
        setLoading(false);
      } else {
        if (res) {
          setTableListData(res?.data);
          setLoading(false);
        }
      }
    });
  }

  const onPageChange = (data: any) => {
    const pageData = pageCount;
    pageData.limit = data;
    setPageCount(pageData);
    getUserAddresses(pageData);
  };

  const onSelectPage = (data: any) => {
    const pageData = pageCount;
    pageData.page = data;
    setPageCount(pageData);
    getUserAddresses(pageData);
  };

  const onSearch = (value: any) => {
    const pageData = pageCount;
    pageData.search = value;
    setPageCount(pageData);
    getUserAddresses(pageData);
  }

  const onActions = (data: any) => { }

  return (
    <div className='row px-3'>
      <div className='col-12 w-100 mt-4 pt-1'>
        <Search onSearch={(e) => onSearch(e)} customSearchCss={"col-md-3"} />
      </div>
      <div className='col-md-12'>
        <Loader LoadingReducer={loading} />
        <DataTable
          tableData={tableListData}
          TableCols={UsersAddressDataGridCols}
          onActions={(e: any) => onActions(e)}
          onPageChange={(e: any) => onPageChange(e)}
          onSelectPageChange={(e) => onSelectPage(e)}
          pageCount={pageCount}
        />
      </div>
    </div>
  )
}
