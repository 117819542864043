import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { DynamicForm } from '../../../../components/form-builder/form';
import { Loader } from '../../../../components/loader';
import { Search } from '../../../../components/search/search';
import { DataTable } from '../../../../components/data-table';
import { PaymentReportsDataGridCols } from './payment-report-data-grid-cols';
import { PaymentReportsResponse } from '../../../../app/model/payment-reports.response';
import { GetParamsData } from '../../../../app/model/common';
import { GET_PAYMENT_REPORTS } from '../../../../app/services/reports.service';

export const PaymentReports = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [tableListData, setTableListData] = useState<PaymentReportsResponse[]>([]);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [selectedData, setSelectedData] = useState<any>([]);
    const navigation = useNavigate();
    const user_uuid = sessionStorage?.getItem('user_uuid');

    const [pageCount, setPageCount] = useState<GetParamsData>({
        limit: 10,
        page: 0,
        // user_uuid: user_uuid
    });

    const [searchFormData, setSearchFormData] = useState<any>([
        {
            title: "input",
            type: "date",
            label: "Start Date",
            name: "start_dt",
            formFloating: false,
            value: '',
            regex: "",
            required: false,
            placeholder: "Please select start date",
            error: "Please select start date",
            hideMandatoryStar: false,
        },
        {
            title: "input",
            type: "date",
            label: "End Date",
            name: "end_dt",
            formFloating: false,
            value: '',
            regex: "",
            required: false,
            placeholder: "Please select end date",
            error: "Please select end date",
            hideMandatoryStar: false,
        },
        {
            title: "input",
            type: "text",
            label: "Search",
            name: "search",
            formFloating: false,
            value: '',
            regex: '',
            required: false,
            placeholder: "Please enter search",
            error: "Please enter search",
            hideMandatoryStar: true,
        },
        {
            title: "dropdown",
            type: "dropdown",
            label: "Status",
            name: "payment_status",
            formFloating: false,
            value: '',
            option: [
                { label: 'Success', value: "SUCCESS" },
                { label: 'Validation Failed', value: "VALIDATION_FAILED" },
                { label: 'Pending', value: "PENDING" },
                { label: 'In Progress', value: "INPROGRESS" },
                { label: 'Failed', value: "FAILED" },
            ],
            required: false,
            placeholder: "Please select status",
            error: "Please select status",
            hideMandatoryStar: false,
        },
        {
            type: "button",
            class: "col-md-3 d-flex justify-content-start my-2",
            buttons: [
                {
                    title: 'Search',
                    action: 'add',
                    isCheckFormValidation: false,
                    class: 'btn btn-primary px-4 btn-sm border-0 f16 me-1'
                },
                {
                    title: 'Cancel',
                    action: 'cancel',
                    class: 'btn btn-outline-primary px-4 btn-sm f16'
                }
            ]
        },
    ])

    const staticTableData = {
        total: 10,
        rows: [
            {
                name: "New vehicle registration",
                transaction_id: "1223829823",
                price: "100",
                date: "2024-4-01",
                status: "COMPLETED"
            },
            {
                name: "Vehicle renewal",
                transaction_id: "4223829328",
                price: "500",
                date: "2024-5-02",
                status: "PENDING"
            },
        ]
    }

    useEffect(() => {
        getPaymentReports(pageCount);
    }, [])

    const getPaymentReports = (pageData: any) => {
        setLoading(true);
        GET_PAYMENT_REPORTS(pageData).then((res: any) => {
            if (res?.status === "fail") {
                toast.error(res.message, { position: 'top-right' })
                setLoading(false);
            } else {
                if (res) {
                    res?.data?.data?.forEach((el: any) => {
                        el.customer_name = el?.user_info?.name
                        el.customer_email = el?.user_info?.email
                        el.total_price = el?.total_amount
                        const orderIds = el?.order?.length > 0 ? el?.order?.map((orders: any) => orders?.order_id).join(', ') : []
                        el.orders_data = el?.order[0]?.order_id
                    })
                    setTableListData(res?.data);
                    setLoading(false);
                }
            }
        });
    }

    const onActions = (data: any) => {
        setSelectedData(data?.data)
        if (data?.type === "Edit") {
            setIsEdit(true);
        } else if (data?.type === "link") {
            navigation("/dashboard/orders")
        } else if (data?.type === "Delete") {
        }
    }

    const onPageChange = (data: any) => {
        const pageData = pageCount;
        pageData.limit = data;
        setPageCount(pageData);
        getPaymentReports(pageData);
    };

    const onSelectPage = (data: any) => {
        const pageData = pageCount;
        pageData.page = data;
        setPageCount(pageData);
        getPaymentReports(pageData);
    };

    const onSearch = (value: any) => {
        const pageData = pageCount;
        pageData.search = value;
        setPageCount(pageData);
        getPaymentReports(pageData);
    }

    const onSearchFormSubmit = (event: any) => {
        const data = event;
        if (data?.type === "add") {
            const pageData = pageCount;
            const formDataObj = { ...pageData, ...data?.formData };
            delete formDataObj['undefined'];
            for (var propName in formDataObj) {
                if (!formDataObj[propName]) {
                    delete formDataObj[propName];
                }
            }
            setPageCount({ ...formDataObj });
            getPaymentReports(formDataObj);
        } else if (data?.type === "cancel") {
            const formData = searchFormData;
            formData?.forEach((element: any) => {
                element.value = ""
            })
            const pageData = {
                limit: 10,
                page: 0,
            }
            setSearchFormData([...formData]);
            setPageCount(pageData);
            getPaymentReports(pageData);
        }
    }

    return (
        <div className='container-fluid content-bg-color'>
            {/* <div className='col-12 w-100 mt-2 pt-2'>
                <Search onSearch={(e) => onSearch(e)} customSearchCss={"col-md-3"} />
            </div> */}
            <div className='col-12 w-100 my-2 mb-3 pb-2'>
                <DynamicForm
                    userFormData={searchFormData}
                    columns={4}
                    isEdit={false}
                    onFormSubmitEvent={(e) => onSearchFormSubmit(e)}
                    // onParentDropdownEvent={(e) => onChangeDropdown(e)}
                    customClass={"display-block"}
                    isNotEmptyFormAfterSubmit={true}
                />
            </div>
            <div className='row'>
                <div className='col-md-12'>
                    <Loader LoadingReducer={loading} />
                    <DataTable
                        tableData={tableListData}
                        TableCols={PaymentReportsDataGridCols}
                        onActions={(e: any) => onActions(e)}
                        onPageChange={(e: any) => onPageChange(e)}
                        onSelectPageChange={(e) => onSelectPage(e)}
                        pageCount={pageCount}
                    />
                </div>
            </div>
        </div>
    )
}
