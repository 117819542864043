import React, { useState } from 'react'
import { FormField } from '../../../app/model/form-fields';
import { DynamicForm } from '../../form-builder/form';

export const Processing = () => {
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [formData, setFormData] = useState<FormField[]>([
        {
            title: "input",
            type: "number",
            label: "Shipping Document Number",
            name: "name",
            formFloating: false,
            value: '',
            regex: '',
            required: true,
            placeholder: "Please enter shipping document number",
            error: "Please enter shipping document number",
            hideMandatoryStar: true,
            isParentDropDown: true
        },
        {
            title: "input",
            type: "datetime-local",
            label: "Shipping Date",
            name: "shipping_date",
            formFloating: false,
            value: '',
            min: '',
            required: true,
            placeholder: "Please select shipping date",
            error: "Please select shipping date",
            hideMandatoryStar: false,
        },
        {
            title: "input",
            type: "datetime-local",
            label: "Delivery Date",
            name: "delivery_date",
            formFloating: false,
            value: '',
            min: '',
            required: true,
            placeholder: "Please select delivery date",
            error: "Please select delivery date",
            hideMandatoryStar: false,
        },
        // {
        //     title: "input",
        //     type: "time",
        //     label: "Delivery Time",
        //     name: "delivery_time",
        //     formFloating: false,
        //     value: '',
        //     min: '',
        //     required: true,
        //     placeholder: "Please select delivery time",
        //     error: "Please select delivery time",
        //     hideMandatoryStar: false,
        // },
        // {
        //     title: "input",
        //     type: "datetime-local",
        //     label: "Delivery Date & Time",
        //     name: "delivery_date_and_time",
        //     formFloating: false,
        //     value: '',
        //     min: '',
        //     required: true,
        //     placeholder: "Please select delivery date and time",
        //     error: "Please select delivery date and time",
        //     hideMandatoryStar: false,
        // },
        // {
        //     title: "textarea",
        //     type: "text",
        //     label: "Shipping Address",
        //     name: "shipping_address",
        //     formFloating: false,
        //     value: '',
        //     required: true,
        //     placeholder: "Please enter shipping address",
        //     error: "Please enter shipping address",
        //     hideMandatoryStar: false,
        // },

        // {
        //     type: "button",
        //     class: "col-md-12 d-flex justify-content-end my-2",
        //     buttons: [
        //         {
        //             title: 'Create',
        //             action: 'add',
        //             isCheckFormValidation: true,
        //             class: 'btn btn-primary px-4 btn-sm border-0 f16 me-1'
        //         },
        //         {
        //             title: 'Cancel',
        //             action: 'cancel',
        //             class: 'btn btn-outline-primary px-4 btn-sm f16'
        //         }
        //     ]
        // },
    ])

    const onFormSubmitEvent = (event: any) => {
        const data = event;
    }

    return (
        <div className='row'>
            <DynamicForm
                userFormData={formData}
                columns={2}
                isEdit={isEdit}
                onFormSubmitEvent={(e) => onFormSubmitEvent(e)}
                customClass={"display-block"}
                isNotEmptyFormAfterSubmit={true} />
        </div>
    )
}
