import React, { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { Login } from '../pages/login/login'
import { Dashboard } from '../pages/dashboard/dashboard'
import { ForgotPassword } from '../pages/login/forgot-password'
import { NotFoundPage } from '../pages/dashboard/not-found-page/notfound-page'
import ReactGA from 'react-ga4';
// import { GA_MEASUREMENT_ID } from '../config/constant'

export const RootNavigation = () => {
    // ReactGA.initialize(GA_MEASUREMENT_ID);
    const TrackPageViews = () => {
        const location = useLocation();
        useEffect(() => {
            const pagePath = location.pathname + location.search;
            ReactGA.send({ hitType: 'pageview', page_path: pagePath });
        }, [location]);
        return null;
    };
    return (
        <div>
            <TrackPageViews />
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/dashboard/*" element={<Dashboard />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                {/* Catch-all route for unknown URLs */}
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
        </div>
    )
}
