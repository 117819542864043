import { EditorUtils } from '@progress/kendo-react-editor';
import { EditorView } from 'prosemirror-view'; // Assuming you're using prosemirror-view

interface InsertImageFilesParams {
  view: EditorView; // Type for the ProseMirror Editor view
  files: File[]; // An array of File objects
  nodeType: any; // This could be more specific if you know the exact type for nodeType
  position?: { pos: number }; // Optional position object with a number field 'pos'
  attrs?: Record<string, any>; // Attributes for the image (could be empty)
}

export const insertImageFiles = ({ view, files, nodeType, position, attrs = {} }: InsertImageFilesParams) => {
  if (EditorUtils.canInsert(view.state, nodeType)) {
    files.forEach((file: File) => {
      let reader = new FileReader();
      reader.onload = function (e: ProgressEvent<FileReader>) {
        const image = nodeType.createAndFill({ ...attrs, src: e.target?.result });
        if (position) {
          view.dispatch(view.state.tr.insert(position.pos, image));
        } else {
          EditorUtils.insertNode(view, image, true);
        }
      };
      reader.readAsDataURL(file);
    });
  }
};
