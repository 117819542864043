import { DataTableCol } from "../../../components/data-table/types";

export const OrderHistoryDataGridCols: DataTableCol[] = [
    {
        title: 'Date',
        control: 'date',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'email / phone number',
        control: 'email_or_phone_number',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'email',
        control: 'email',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Link',
        control: 'link',
        sortable: true,
        canShowColumn: true
    },
];