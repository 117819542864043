import React, { useState } from 'react'
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { GAReportsTabRoutes } from '../../../../routes/ga-reports-tab-routes';
import { TabsData } from '../../../../app/model/common';

export const GaReports = () => {
    const location = useLocation();
    const currentPath = location.pathname.split("/");
    const params = useParams();
    const [tabs, setTabs] = useState<TabsData[]>([
        {
            id: "1",
            name: "User Activity Reports",
            link: `/dashboard/reports/ga-reports/user-activity-reports`,
        },
        {
            id: "2",
            name: "Campaign Tracking",
            link: `/dashboard/reports/ga-reports/campaign-tracking`,
        },
    ]);
    
    return (
        <div className='col-md-12 mt-3 p-2'>
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                {tabs?.map((data: TabsData, index: number) => {
                    return <NavLink key={index} to={data?.link} className={`nav-link text-dark px-4 fs-13
                ${currentPath.length === 4 && data?.name === "User Activity Reports"
                            ? "active"
                            : ""
                        }`}>{data?.name}</NavLink>
                })}
            </ul>
            <div className="tab-content shadow" id="myTabContent">
                <GAReportsTabRoutes />
            </div>
        </div>
    )
}