import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { DynamicForm } from '../../../components/form-builder/form';
import { RefundsDataGridCols } from './refunds-data-grid-cols';
import { DataTable } from '../../../components/data-table';
import { Loader } from '../../../components/loader';
import { Search } from '../../../components/search/search';
import { useNavigate } from 'react-router-dom';
import { RefundsResponse } from '../../../app/model/refunds.response';
import { FormField } from '../../../app/model/form-fields';
import { GetParamsData } from '../../../app/model/common';

export const Refunds = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [tableListData, setTableListData] = useState<RefundsResponse[]>([]);
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
    const [showCreatePopup, setShowCreatePopup] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [selectedData, setSelectedData] = useState<any>([]);
    const [showVehicleDetailsPopup, setShowVehicleDetailsPopup] = useState<boolean>(false);
    const navigation = useNavigate();
    const [pageCount, setPageCount] = useState<GetParamsData>({
        limit: 10,
        page: 0,
    });
    const [formData, setFormData] = useState<FormField[]>([
        {
            title: "dropdown",
            type: "dropdown",
            label: "Service Name",
            name: "name",
            formFloating: false,
            value: '',
            option: [
                { label: 'New Registration', value: 'New Registration' },
                { label: 'Renewal', value: 'Renewal' }
            ],
            required: true,
            placeholder: "Please select service name",
            error: "Please select service name",
            hideMandatoryStar: false,
        },
        {
            title: "dropdown",
            type: "dropdown",
            label: "Customers",
            name: "customer_name",
            formFloating: false,
            value: '',
            option: [
                { label: 'Prasanth', value: 'Prasanth' },
                { label: 'Ravi', value: 'Ravi' }
            ],
            required: true,
            placeholder: "Please select customer name",
            error: "Please select customer name",
            hideMandatoryStar: false,
        },
        {
            title: "dropdown",
            type: "dropdown",
            label: "Orders",
            name: "order_id",
            formFloating: false,
            value: '',
            option: [
                { label: 'Order 1', value: '2302323323' },
                { label: 'Order 2', value: '2302323359' }
            ],
            required: true,
            placeholder: "Please select order",
            error: "Please select order",
            hideMandatoryStar: false,
        },
        // {
        //     title: "input",
        //     type: "date",
        //     label: "Date",
        //     name: "order_date",
        //     formFloating: false,
        //     value: '',
        //     regex: '',
        //     required: true,
        //     placeholder: "Please select order date",
        //     error: "Please select order date",
        //     hideMandatoryStar: true,
        // },
        {
            title: "input",
            type: "number",
            label: "Amount",
            name: "amount",
            formFloating: false,
            value: '',
            regex: '',
            required: true,
            placeholder: "Please enter amount",
            error: "Please enter amount",
            hideMandatoryStar: true,
        },
        {
            title: "dropdown",
            type: "dropdown",
            label: "Status",
            name: "status",
            formFloating: false,
            value: '',
            option: [
                { label: 'Completed', value: 'COMPLETED' },
                { label: 'Pending', value: 'PENDING' },
                { label: 'Processing', value: 'PROCESSING' },
            ],
            required: true,
            placeholder: "Please select status",
            error: "Please select status",
            hideMandatoryStar: false,
        },
        {
            title: "textarea",
            type: "text",
            label: "Description",
            name: "description",
            formFloating: false,
            value: '',
            regex: '',
            required: false,
            placeholder: "Please enter description",
            error: "Please enter description",
            hideMandatoryStar: true,
        },
        {
            type: "button",
            class: "col-md-12 d-flex justify-content-end my-2",
            buttons: [
                {
                    title: 'Create',
                    action: 'add',
                    isCheckFormValidation: true,
                    class: 'btn btn-primary px-4 btn-sm border-0 f16 me-1'
                },
                {
                    title: 'Cancel',
                    action: 'cancel',
                    class: 'btn btn-outline-primary px-4 btn-sm f16'
                }
            ]
        },
    ])

    const staticTableData = {
        total: 10,
        rows: [
            {
                id: "1",
                order_id: "2302323323",
                customer_name: "Prasanth",
                service_type: "New registration",
                paid_amount: "1000",
                amount: "500",
                refund_reason: "Registration taking long time",
                order_date: "2024-04-21",
                description: "Registration process is not working properly",
                status: "PENDING",
            },
            {
                id: "2",
                order_id: "2302323359",
                customer_name: "Ravi",
                service_type: "Renewal",
                paid_amount: "1500",
                amount: "1000",
                refund_reason: "Renewal process is not done",
                order_date: "2024-04-22",
                description: "Issues having at renewal process",
                status: "COMPLETED",
            },
        ]
    }

    useEffect(() => {
        getRefunds();
    }, [])

    const getRefunds = () => {
        // setLoading(true);
        // GET_REFUNDS(pageCount).then((res: any) => {
        //     if (res?.status === "fail") {
        // toast.error(res.message, { position: 'top-right' })
        //         setLoading(false);
        //     } else {
        //         if (res) {
        //             setTableListData(res?.data);
        //             setLoading(false);
        //         }
        //     }
        // });
    }

    const onShowCreatePopup = () => {
        setIsEdit(false);
        setShowCreatePopup(true);
        const categoryFormData = formData;
        categoryFormData?.forEach((element: any) => {
            if (element?.type === "button") {
                for (let i = 0; i < element?.buttons?.length; i++) {
                    const ele = element?.buttons;
                    ele[0].title = "Create";
                    ele[0].action = "add";
                }
            }
            element.value = "";
        })
        setFormData([...formData])
    }

    const onActions = (data: any) => {
        setSelectedData(data?.data)
        if (data?.type === "Edit") {
            setIsEdit(true);
            setShowCreatePopup(true);
            const formDataObj = formData;
            formDataObj?.forEach((element: any) => {
                if (element?.type === "button") {
                    for (let i = 0; i < element?.buttons?.length; i++) {
                        const ele = element?.buttons;
                        ele[0].title = "Update";
                        ele[0].action = "edit";
                    }
                }
                element.value = data?.data[element.name]
            });
            setFormData([...formDataObj]);
        } else if (data?.type === "Delete") {
            setShowDeletePopup(true);
        } else if (data?.type === "View") {
            setShowVehicleDetailsPopup(true);
        }
    }

    const onPageChange = (data: any) => {
        const pageData = pageCount;
        pageData.limit = data;
        setPageCount(pageData);
    };

    const onSelectPage = (data: any) => {
        const pageData = pageCount;
        pageData.page = data;
        setPageCount(pageData);
    };

    const onSearch = (value: any) => {
        const pageData = pageCount;
        pageData.search = value;
        setPageCount(pageData);
    }

    const onDeleteUserConfirmation = () => {
        toast.success("Deleted Successfully", { position: 'top-right' })
    }

    const onFormSubmitEvent = (event: any) => {
        const data = event;
        if (data?.type === "add") {
            const formData = data?.formData;
            delete formData['undefined'];
            // setLoading(true);
            // CREATE_REFUND(formData).then((res: any) => {
            //     if (res.status === "fail") {
            //         toast.error(res.error, { position: "top-right" });
            //         setLoading(false);
            //     } else {
            //         if (res) {
            //             setShowCreatePopup(false);
            //             toast.success(res.message, { position: 'top-right', })
            //             setLoading(false);
            //             getUsers();
            //         }
            //     }
            // });
        } else if (data?.type === "cancel") {
            setShowCreatePopup(false)
        }
    }

    return (
        <div className='container-fluid content-bg-color'>
            <div className='row border-bottom-primary'>
                <div className='col-md-9 text-start d-flex align-items-center px-0'>
                    <h4>Refunds</h4>
                </div>
                <div className='col-md-3 text-end my-2 px-0'>
                    <button className='btn btn-primary border-0' onClick={onShowCreatePopup}>Create Refund</button>
                </div>
            </div>
            <div className='col-12 w-100 mt-4 pt-1'>
                <Search onSearch={(e) => onSearch(e)} customSearchCss={"col-md-3 px-0"} />
            </div>
            <div className='row'>
                <div className='col-md-12 px-0'>
                    <Loader LoadingReducer={loading} />
                    <DataTable
                        tableData={staticTableData}
                        TableCols={RefundsDataGridCols}
                        onActions={(e: any) => onActions(e)}
                        onPageChange={(e: any) => onPageChange(e)}
                        onSelectPageChange={(e) => onSelectPage(e)}
                        pageCount={pageCount}
                    />
                </div>
                {showCreatePopup && (
                    <div className={`modal modal-custom fade ${showCreatePopup ? "show" : "false"}  ${showCreatePopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-lg modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header border-bottom-primary mx-4 px-0">
                                    <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">{isEdit ? "Edit" : "Create"} Refund</strong></h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowCreatePopup(false)}></button>
                                </div>
                                <div className="modal-body px-4 text-start">
                                    <div className='row'>
                                        <DynamicForm
                                            userFormData={formData}
                                            columns={2}
                                            isEdit={isEdit}
                                            onFormSubmitEvent={(e) => onFormSubmitEvent(e)}
                                            customClass={"display-block"}
                                            isNotEmptyFormAfterSubmit={true} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {showDeletePopup && (
                    <>
                        <div className={`modal modal-custom fade ${showDeletePopup ? "show" : "false"}  ${showDeletePopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
                            <div className="modal-dialog modal-lg modal-dialog-centered">
                                <div className="modal-content text-start">
                                    <div className="modal-header border-bottom-primary mx-4 px-0">
                                        <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">Delete</strong></h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowDeletePopup(false)}></button>
                                    </div>
                                    <div className="modal-body pb-5">
                                        <div className="row px-2">
                                            <div className="col-md-12">
                                                <div className="mb-3">
                                                    <p className="f16 p-2">Are you sure you want to delete <span className='fw-bold '>{selectedData?.name || ""}</span> ?</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='px-2 text-end'>
                                            <button type="button" className="btn btn-primary px-4 btn-sm border-0 f16 me-3" onClick={onDeleteUserConfirmation}>Yes</button>
                                            <button type="button" className="btn btn-outline-primary px-4 btn-sm f16 text-primary-medium border-primary-medium" onClick={() => setShowDeletePopup(false)}>No</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
            {showVehicleDetailsPopup && (
                <div className={`modal modal-custom fade ${showVehicleDetailsPopup ? "show" : "false"}  ${showVehicleDetailsPopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header border-bottom-primary mx-4 px-0">
                                <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">Vehicle Details</strong></h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowVehicleDetailsPopup(false)}></button>
                            </div>
                            <div className="modal-body px-4 text-start">
                                <div className='card border-primary'>
                                    <div className='card-body'>
                                        <div className='row d-flex justify-content-between'>
                                            <div className='col-md-6 text-center fw-bold'>VIN Number</div>
                                            <div className='col-md-6 text-start'><span className='me-3'>:</span>1HGCM82633A123456</div>
                                        </div>
                                        <div className='row d-flex justify-content-between'>
                                            <div className='col-md-6 text-center fw-bold'>License Plate</div>
                                            <div className='col-md-6 text-start '><span className='me-3'>:</span>7ABC123</div>
                                        </div>
                                        <div className='row d-flex justify-content-between'>
                                            <div className='col-md-6 text-center fw-bold'>Vehicle Make</div>
                                            <div className='col-md-6 text-start'><span className='me-3'>:</span>BMW</div>
                                        </div>
                                        <div className='row d-flex justify-content-between'>
                                            <div className='col-md-6 text-center fw-bold'>Vehicle Type</div>
                                            <div className='col-md-6 text-start '><span className='me-3'>:</span>Car</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
