import React, { useEffect, useState } from 'react'
import { DynamicForm } from '../../../../components/form-builder/form';
import { DataTable } from '../../../../components/data-table';
import { AbandonedCartOderDetailsDataGridCols } from './abandoned-cart-order-details-data-grid-cols';
import { Loader } from '../../../../components/loader';
import { GetParamsData } from '../../../../app/model/common';
import { useNavigate, useParams } from 'react-router-dom';
import { AbandonedCartResponse } from '../../../../app/model/abandoned-cart.response';
import { FormField } from '../../../../app/model/form-fields';
import { GET_ABANDONED_CART_DETAILS } from '../../../../app/services/abandoned-cart.service';
import { Search } from '../../../../components/search/search';
import { BreadCrumb } from '../../../../components/bread-crumb';
import toast from 'react-hot-toast';
import { GET_ORDERS } from '../../../../app/services/orders.service';

export const OrderDetailsPage = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [tableListData, setTableListData] = useState<any>([]);
    const [vehicleDetailsData, setVehicleDetailsData] = useState<any>([]);
    const [dmvDetailsData, setDMVDetailsData] = useState<any>([]);
    const [showVehicleDetailsPopup, setShowVehicleDetailsPopup] = useState<boolean>(false);
    const [showDMVDetailsPopup, setShowDMVDetailsPopup] = useState<boolean>(false);
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
    const [showCreatePopup, setShowCreatePopup] = useState<boolean>(false);
    const [priceConfigData, setPriceConfigData] = useState<any[]>([]);
    const [serviceData, setServiceData] = useState<any>();
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [selectedData, setSelectedData] = useState<any>([]);
    const navigation = useNavigate();
    const params = useParams();
    const [pageCount, setPageCount] = useState<GetParamsData>({
        limit: 10,
        page: 0,
        uuid: params?.cart_order_uuid
    });

    const [formData, setFormData] = useState<any>([
        {
            title: "input",
            type: "text",
            label: "Name",
            name: "service_name",
            formFloating: false,
            value: '',
            regex: "",
            required: true,
            placeholder: "Please enter name",
            error: "Please enter name",
            hideMandatoryStar: false,
        },
        {
            title: "input",
            type: "number",
            label: "Price",
            name: "price",
            formFloating: false,
            value: '',
            regex: '',
            required: true,
            placeholder: "Please enter price",
            error: "Please enter price",
            hideMandatoryStar: true,
        },
        {
            type: "button",
            class: "col-md-12 d-flex justify-content-end my-2",
            buttons: [
                {
                    title: 'Create',
                    action: 'add',
                    isCheckFormValidation: true,
                    class: 'btn btn-primary px-4 btn-sm border-0 f16 me-1'
                },
                {
                    title: 'Cancel',
                    action: 'cancel',
                    class: 'btn btn-outline-primary px-4 btn-sm f16'
                }
            ]
        },
    ])

    const [breadcrumbInfo, setBreadcrumbInfo] = useState<any>([
        {
            label: "Abandoned Cart Orders",
            value: "Abandoned Cart Orders",
            routerLink: "/dashboard/abandoned-cart",
            isActive: false,
        },
        {
            label: "Abandoned Cart Orders",
            value: "Abandoned Cart Orders : ",
            routerLink: `/dashboard/abandoned-cart/${params?.cart_order_uuid}`,
            isActive: true,
        },
    ]);


    useEffect(() => {
        getAbandonedCartOrderDetailsById(params?.cart_order_uuid);
    }, [])

    const getAbandonedCartOrderDetailsById = (orderUuid: any) => {
        setLoading(true);
        GET_ORDERS({ uuid: orderUuid }).then((res: any) => {
            if (res?.status === "fail") {
                setLoading(false);
            } else {
                if (res) {
                    res?.data?.data?.forEach((element: any) => {
                        element.service_type = element?.service_type?.name;
                        element.customer_name = element?.user_info?.name;
                        element.customer_email = element?.user_info?.email;
                        element.customer_mobile_number = element?.user_info?.mobile_number;
                        element.address = `${element?.user_shipping_address?.street_address},${element?.user_shipping_address?.unit}`;
                        // ${element?.shipping_info?.city}, ${element?.shipping_info?.state} ${element?.shipping_info?.zip_code}`;
                    });
                    setTableListData(res?.data);
                    // setVehicleDetailsData(res?.data?.user_vehicle)
                    // setDMVDetailsData(res?.data?.user_vehicle)
                    const data = breadcrumbInfo;
                    data[1].value = res?.data?.data[0]?.order_id
                    setBreadcrumbInfo([...data])
                    setLoading(false);
                }
            }
        });
    }

    const getPrivileges = (pageData: any) => {
        // setLoading(true);
        // GET_ACCESS_OBJECTS(pageData).then((res: any) => {
        //     if (res?.status === "fail") {
        //         toast.error(res.message, { position: 'top-right' })
        //         setLoading(false);
        //     } else {
        //         if (res) {
        //             setTableListData(res?.data);
        //             setLoading(false);
        //         }
        //     }
        // });
    }

    const onShowCreatePopup = () => {
        setIsEdit(false);
        setShowCreatePopup(true);
        const categoryFormData = formData;
        categoryFormData?.forEach((element: any) => {
            if (element?.type === "button") {
                for (let i = 0; i < element?.buttons?.length; i++) {
                    const ele = element?.buttons;
                    ele[0].title = "Create";
                    ele[0].action = "add";
                }
            }
            element.value = "";
        })
        setFormData([...formData])
    }

    const onActions = (data: any) => {
        setSelectedData(data?.data)
        if (data?.type === "Edit") {
            setIsEdit(true);
            setShowCreatePopup(true);
            const formDataObj = formData;
            formDataObj?.forEach((element: any) => {
                if (element?.type === "button") {
                    for (let i = 0; i < element?.buttons?.length; i++) {
                        const ele = element?.buttons;
                        ele[0].title = "Update";
                        ele[0].action = "edit";
                    }
                }
                element.value = data?.data[element.name]
            });
            setFormData([...formDataObj]);
        } else if (data?.type === "Delete") {
            setShowDeletePopup(true);
            setVehicleDetailsData(data?.data?.user_vehicle)
        } else if (data?.type === "showDMVDetails") {
            setShowDMVDetailsPopup(true);
        } else if (data?.type === "Vehicle Details") {
            setVehicleDetailsData(data?.data?.user_vehicle)
            setShowVehicleDetailsPopup(true);
        }
    }

    const onPageChange = (data: any) => {
        const pageData = pageCount;
        pageData.limit = data;
        setPageCount(pageData);
        getPrivileges(pageData)
    };

    const onSelectPage = (data: any) => {
        const pageData = pageCount;
        pageData.page = data;
        setPageCount(pageData);
        getPrivileges(pageData);
    };

    const onSearch = (value: any) => {
        const pageData = pageCount;
        pageData.search = value;
        setPageCount(pageData);
        getPrivileges(pageData)
    }

    const onDeleteUserConfirmation = () => {
        const updatedServiceData = { ...serviceData };
        delete updatedServiceData.price_config[selectedData?.service_name];
        delete updatedServiceData['uuid']
        delete updatedServiceData['created_dt']

        // setLoading(true);
        // UPDATE_SERVICE_TYPE(serviceData?.uuid, updatedServiceData).then((res: any) => {
        //     if (res.status === "fail") {
        //         toast.error(res.error, { position: "top-right" });
        //         setLoading(false);
        //     } else {
        //         if (res) {
        //             setShowDeletePopup(false);
        //             toast.success("Deleted Successfully!", { position: 'top-right', })
        //             setLoading(false);
        //             getServiceDetailsById(serviceData?.uuid)
        //         }
        //     }
        // });
    }

    const onFormSubmitEvent = (event: any) => {
        const data = event;
        if (data?.type === "add") {
            const formData = data?.formData;
            // Update serviceData with the modified price_config
            // setServiceData(updatedServiceData);

            setShowCreatePopup(false); // Close popup after submission
        } else if (data?.type === "edit") {
            const formData = data?.formData;
            // Here you would typically send your update API call with updatedServiceData as payload
            // Example:
            // UPDATE_ACCESS_OBJECT(selectedData?.uuid, updatedServiceData).then((res: any) => {
            //     // Handle response
            // });

            setShowCreatePopup(false); // Close popup after submission
        } else if (data?.type === "cancel") {
            setShowCreatePopup(false); // Close popup on cancel
        }
    }
    return (
        <div className='container-fluid content-bg-color'>
            <div className='row border-bottom-primary'>
                <div className='col-md-9 px-4 py-3 fs-5'>
                    <BreadCrumb breadCrumbList={breadcrumbInfo}></BreadCrumb>
                </div>
                <div className='col-md-3 text-end my-2 px-0'>
                    {/* <button className='btn btn-primary border-0' onClick={onShowCreatePopup}>Create Price Config</button> */}
                </div>
            </div>
            {/* <div className='col-12 w-100 mt-4 pt-1'>
                <Search onSearch={(e) => onSearch(e)} customSearchCss={"col-md-3 px-0"} />
            </div> */}
            <div className='row my-3'>
                <div className='col-md-12 px-0'>
                    <Loader LoadingReducer={loading} />
                    <DataTable
                        tableData={tableListData}
                        TableCols={AbandonedCartOderDetailsDataGridCols}
                        onActions={(e: any) => onActions(e)}
                        isHidePagination={true}
                        onPageChange={(e: any) => onPageChange(e)}
                        onSelectPageChange={(e) => onSelectPage(e)}
                        pageCount={pageCount}
                    />
                </div>
            </div>
            {showVehicleDetailsPopup && (
                <div className={`modal modal-custom fade ${showVehicleDetailsPopup ? "show" : "false"}  ${showVehicleDetailsPopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header border-bottom-primary mx-4 px-0">
                                <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">Vehicle Details</strong></h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowVehicleDetailsPopup(false)}></button>
                            </div>
                            <div className="modal-body px-4 text-start">
                                <div className='card border-primary-color px-3'>
                                    <div className='card-body'>
                                        <div className='row d-flex justify-content-between'>
                                            <div className='col-md-6 text-center fw-bold'>VIN Number</div>
                                            <div className='col-md-6 text-start'><span className='me-3'>:</span>{vehicleDetailsData?.vehicle_vin_number || "-"}</div>
                                        </div>
                                        <div className='row d-flex justify-content-between'>
                                            <div className='col-md-6 text-center fw-bold'>License Plate</div>
                                            <div className='col-md-6 text-start '><span className='me-3'>:</span>{vehicleDetailsData?.vehicle_license_plate || "-"}</div>
                                        </div>
                                        <div className='row d-flex justify-content-between'>
                                            <div className='col-md-6 text-center fw-bold'>Vehicle Make</div>
                                            <div className='col-md-6 text-start'><span className='me-3'>:</span>{vehicleDetailsData?.vehicle_make || "-"}</div>
                                        </div>
                                        <div className='row d-flex justify-content-between'>
                                            <div className='col-md-6 text-center fw-bold'>Vehicle Type</div>
                                            <div className='col-md-6 text-start '><span className='me-3'>:</span>{vehicleDetailsData?.vehicle_type || "-"}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showDMVDetailsPopup && (
                <div className={`modal modal-custom fade ${showDMVDetailsPopup ? "show" : "false"}  ${showDMVDetailsPopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header border-bottom-primary mx-4 px-0">
                                <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">DMV Details</strong></h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowDMVDetailsPopup(false)}></button>
                            </div>
                            <div className="modal-body px-4 text-start">
                                <div className='row px-3'>
                                    <div className='card border-primary-color px-4'>
                                        <div className='card-body'>
                                            <div className='row d-flex justify-content-between'>
                                                <div className='col-md-6 text-center fw-bold'>VIN Number</div>
                                                <div className='col-md-6 text-start'><span className='me-3'>:</span>1HGCM82633A123456</div>
                                            </div>
                                            <div className='row d-flex justify-content-between'>
                                                <div className='col-md-6 text-center fw-bold'>License Plate</div>
                                                <div className='col-md-6 text-start '><span className='me-3'>:</span>7ABC123</div>
                                            </div>
                                            <div className='row d-flex justify-content-between'>
                                                <div className='col-md-6 text-center fw-bold'>Vehicle Make</div>
                                                <div className='col-md-6 text-start'><span className='me-3'>:</span>BMW</div>
                                            </div>
                                            <div className='row d-flex justify-content-between'>
                                                <div className='col-md-6 text-center fw-bold'>Vehicle Type</div>
                                                <div className='col-md-6 text-start '><span className='me-3'>:</span>Car</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
