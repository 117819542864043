import { DataTableCol } from "../../../components/data-table/types";

export const PriceConfigDataGridCols: DataTableCol[] = [
    {
        title: 'Name',
        control: 'service_name',
        sortable: true,
        isServiceFee: true,
        canShowColumn: true,
    },
    {
        title: 'Price',
        control: 'price',
        sortable: true,
        canShowColumn: true,
        isPrice: true,
    },
    {
        title: 'Actions',
        control: 'both',
        sortable: false,
        isEdit: true,
        isDelete: true,
        isView: false,
        canShowColumn: true
    },
];