import React, { useState } from 'react'
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { ReportTabRoutes } from '../../../routes/report-tab-routes';
import { TabsData } from '../../../app/model/common';

export const ReportsTabInfo = () => {
    const location = useLocation();
    const currentPath = location.pathname.split("/");
    const userRole = sessionStorage?.getItem('user_role');
    const params = useParams();
    const [tabs, setTabs] = useState<TabsData[]>([
        {
            id: "1",
            name: "Payment Reports",
            link: `/dashboard/reports/payment-reports`,
        },
        {
            id: "2",
            name: "GA Reports",
            link: `/dashboard/reports/ga-reports`,
        },
        {
            id: "3",
            name: "Refund Reports",
            link: `/dashboard/reports/refund-reports`,
        },
        {
            id: "4",
            name: "Service Wise Reports",
            link: `/dashboard/reports/service-wise-reports`,
        },
        {
            id: "5",
            name: "DMV Error Reports",
            link: `/dashboard/reports/dmv-error-reports`,
        },
    ]);
    return (
        <div className='col-md-12 mt-3 p-2 content-bg-color'>
            <div className='row ps-3 py-2'>
                <div className='col-md-9 text-start d-flex align-items-center px-0'>
                    <h4 className='ps-1'>Reports</h4>
                </div>
            </div>
            <ul className="nav nav-tabs mx-2" id="myTab" role="tablist">
                {tabs.map((data: TabsData, index: number) => {
                    return <NavLink key={index} to={data?.link} className={`nav-link text-dark px-4 fs-13
                    ${currentPath.length === 3 && data?.name === "Payment Reports"
                            ? "active"
                            : ""
                        }`}>{data?.name}</NavLink>
                })}
            </ul>
            <div className="tab-content shadow mx-2" id="myTabContent">
                <ReportTabRoutes />
            </div>
        </div>
    )
}
