import API from "../utils/axios";
let common = "/v1/api";

export const GET_ROLES = (data: any): Promise<any> => {
  return API.get(`${common}/access/roles`, { params: data });
};

export const CREATE_ROLE = (data: any): Promise<any> => {
  return API.post(`${common}/access/roles`, data);
};

export const UPDATE_ROLE = (uuid: string, data: any): Promise<any> => {
  return API.put(`${common}/access/roles/${uuid}`, data);
};

export const DELETE_ROLE = (uuid: string): Promise<any> => {
  return API.delete(`${common}/access/roles/${uuid}`);
};

export const GET_ACCESS_OBJECT_BY_ROLE = (data: any): Promise<any> => {
  return API.get(`${common}/access/roles`, { params: data });
};