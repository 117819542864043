import { DataTableCol } from "../../../components/data-table/types";

export const OrdersDataGridCols: DataTableCol[] = [
    {
        title: 'Order ID',
        control: 'order_id',
        sortable: true,
        canShowColumn: true,
    },
    {
        title: 'Customer name',
        control: 'customer_name',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Customer email',
        control: 'customer_email',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Service Type',
        control: 'service_type',
        sortable: true,
        canShowColumn: true
    },
    // {
    //     title: 'Vehicle details',
    //     control: 'vehicle_details',
    //     isOrderVehicleDetails: true,
    //     sortable: true,
    //     canShowColumn: true
    // },
    // {
    //     title: 'DMV details',
    //     control: 'dmv_details',
    //     isDMVDetails:true,
    //     sortable: true,
    //     canShowColumn: true
    // },
    {
        title: 'Date',
        control: 'date',
        isDateFormat: true,
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Price',
        control: 'total_price',
        sortable: true,
        canShowColumn: true,
        isPrice: true,
    },

    // {
    //     title: 'Tag',
    //     control: 'tag',
    //     sortable: true,
    //     canShowColumn: true
    // },
    {
        title: 'Delivery Address',
        control: 'delivery_address',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'DMV Details',
        control: 'dmv_details',
        isShowDmvDetails:true,
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Status',
        control: 'order_status',
        isStatus: true,
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Shipping Status',
        control: 'shipping_status',
        isOrderStatus:true,
        isShowShippingDetails:true,
        sortable: true,
        canShowColumn: true,
    },
    // {
    //     title: 'Status Update',
    //     control: 'status_update',
    //     sortable: true,
    //     canShowColumn: true
    // },
    {
        title: 'Actions',
        control: 'both',
        sortable: false,
        isEdit: true,
        isOrderVehicleDetails: true,
        isDMVDetails: false,
        isDelete: false,
        isView: false,
        canShowColumn: true
    },
];