import API from "../utils/axios";
let common = "/v1/api";

export const GET_ACCESS_OBJECTS = (data: any): Promise<any> => {
    return API.get(`${common}/access/objects`, { params: data });
};

export const CREATE_ACCESS_OBJECT = (data: any): Promise<any> => {
    return API.post(`${common}/access/objects`, data);
};

export const UPDATE_ACCESS_OBJECT = (uuid: string, data: any): Promise<any> => {
    return API.put(`${common}/access/objects/${uuid}`, data);
};

export const DELETE_ACCESS_OBJECT = (uuid: string): Promise<any> => {
    return API.delete(`${common}/access/objects/${uuid}`);
};