import API from "../utils/axios";
let common = "/v1/api";

export const GET_EMAIL_TEMPLATES = (data: any): Promise<any> => {
  return API.get(`${common}/template`, { params: data });
};

export const CREATE_EMAIL_TEMPLATE = (data: any): Promise<any> => {
  return API.post(`${common}/template`, data);
};
export const UPDATE_EMAIL_TEMPLATE = (
  uuid: string,
  data: any
): Promise<any> => {
  return API.put(`${common}/template/${uuid}`, data);
};

export const DELETE_EMAIL_TEMPLATE = (uuid: string): Promise<any> => {
  return API.delete(`${common}/template/${uuid}`);
};

export const GET_EMAIL_TEMPLATES_TYPES = (data: any): Promise<any> => {
  return API.get(`${common}/template/types`, { params: data });
};
