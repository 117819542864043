import React, { useEffect, useState } from 'react'
import { NavLink, Routes, useLocation, useParams } from 'react-router-dom'
import { UserInfoTabsRoutes } from '../../../../routes/user-info-tabs-routes';
import { BreadCrumb } from '../../../../components/bread-crumb';
import { TabsData } from '../../../../app/model/common';
import { GET_USER } from '../../../../app/services/user.service';
import toast from 'react-hot-toast';

export const UserTabInfo = () => {
    const location = useLocation();
    const currentPath = location.pathname.split("/");
    const [loading, setLoading] = useState<boolean>(false);
    const userRole = sessionStorage?.getItem('user_role');
    const params = useParams();
    const [tabs, setTabs] = useState<TabsData[]>([
        {
            id: "1",
            name: "Address",
            link: `/dashboard/users/${params?.user_uuid}/address`,
        },
        {
            id: "2",
            name: "Vehicles",
            link: `/dashboard/users/${params?.user_uuid}/vehicles`,
        },
    ]);

    const [breadcrumbInfo, setBreadcrumbInfo] = useState<any>([
        {
            label: "Users",
            value: "Users",
            routerLink: "/dashboard/users",
            isActive: false,
        },
        {
            label: "User Name",
            value: "Customer : ",
            routerLink: `/dashboard/users/${params?.user_uuid}/address`,
            isActive: true,
        },
    ]);

    useEffect(() => {
        getUserDetailsById(params?.user_uuid)
    }, [])

    const getUserDetailsById = (userUuid: any) => {
        setLoading(true);
        GET_USER({ uuid: userUuid }).then((res: any) => {
            if (res?.status === "fail") {
                toast.error(res.message, { position: 'top-right' })
                setLoading(false);
            } else {
                if (res) {
                    const response = res?.data?.data[0]
                    const data = breadcrumbInfo;
                    data[1].value = response?.name
                    setBreadcrumbInfo([...data])
                    setLoading(false);
                }
            }
        });
    }

    return (
        <div className='col-md-12 mt-3 p-2 content-bg-color px-4'>
            <div className='row'>
                <div className='col-md-12 px-4 py-3 fs-5'>
                    <BreadCrumb breadCrumbList={breadcrumbInfo}></BreadCrumb>
                </div>
            </div>
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                {tabs.map((data: any, index: number) => {
                    return <NavLink key={index} to={data?.link} className={`nav-link text-dark px-4 fs-13
                ${currentPath.length === 4 && data?.name === "Address"
                            ? "active"
                            : ""
                        }`}>{data?.name}</NavLink>
                })}
            </ul>
            <div className="tab-content shadow" id="myTabContent">
                <UserInfoTabsRoutes />
            </div>
        </div>
    )
}
