import API from "../utils/axios";
let common = "/v1/api";

export const GET_USER_ADDRESSES = (data: any): Promise<any> => {
    return API.get(`${common}/user/shipping/address`, { params: data });
};

export const CREATE_USER_ADDRESS = (data: any): Promise<any> => {
    return API.post(`${common}/user/shipping/address`, data);
};

export const UPDATE_USER_ADDRESS = (uuid: string, data: any): Promise<any> => {
    return API.put(`${common}/user/shipping/address/${uuid}`, data);
};

export const DELETE_USER_ADDRESS = (uuid: string): Promise<any> => {
    return API.delete(`${common}/user/shipping/address/${uuid}`);
};