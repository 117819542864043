import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Vehicles } from '../pages/dashboard/users/users-tab-info/vehicles/vehicles'
import { UserAddress } from '../pages/dashboard/users/users-tab-info/address/user-address'
import { VehiclesTabInfo } from '../pages/dashboard/users/users-tab-info/vehicles/vehciles-tab-info'
import { NotFoundPage } from '../pages/dashboard/not-found-page/notfound-page'
import { Orders } from '../pages/dashboard/users/users-tab-info/vehicles/orders/orders'

export const UserInfoTabsRoutes = () => {
    return (
        <div>
            <Routes>
                <Route path="/" element={<UserAddress />}></Route>
                <Route path="/address" element={<UserAddress />}></Route>
                <Route path="/vehicles" element={<Vehicles />}></Route>
                {/* <Route path="/vehicles/:user_vehicle_uuid/*" element={<VehiclesTabInfo />}></Route> */}
                <Route path="/vehicles/:user_vehicle_uuid/orders" element={<Orders />}></Route>
                {/* Catch-all route for unknown URLs */}
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
        </div>
    )
}
