import { DataTableCol } from "../../../components/data-table/types";

export const TicketsDataGridCols: DataTableCol[] = [
    {
        title: 'Ticket Id',
        control: 'ticket_id',
        sortable: true,
        canShowColumn: true,
    },
    {
        title: 'Date & Time',
        control: 'date_time',
        sortable: true,
        canShowColumn: true,
    },
    {
        title: 'Customer Name',
        control: 'name',
        sortable: true,
        canShowColumn: true,
    },
    {
        title: 'Vehicle Number',
        control: 'vehicle_number',
        sortable: true,
        canShowColumn: true,
    },
    {
        title: 'Customer Mobile Number',
        control: 'mobile_number',
        sortable: true,
        canShowColumn: true,
    },
    {
        title: 'Email',
        control: 'email',
        sortable: true,
        canShowColumn: true,
    },
    {
        title: 'Description',
        control: 'description',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Status',
        control: 'status',
        isStatus: true,
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Actions',
        control: 'both',
        sortable: false,
        isEdit: false,
        isDelete: false,
        isReply: true,
        isView: false,
        canShowColumn: true
    },
];