import React from 'react'
import { NavLink } from 'react-router-dom'
import Logo from './../../../assets/images/logo.svg'

export const NotFoundPage = () => {
    return (
        <div className="not-found-page-container">
            <div className='mb-4'>
                <img src={Logo} className='w-50'/>
            </div>
            <h1 className='title'>404</h1>
            <p className='message'>Page Not Found</p>
            <NavLink to="/" className='not-found-link text-decoration-none'>Go to Home</NavLink>
        </div>
    )
}
