import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { Search } from '../../../components/search/search';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../../components/loader';
import { DataTable } from '../../../components/data-table';
import { UsersDataGridCols } from './users-data-grid-cols';
import { DynamicForm } from '../../../components/form-builder/form';
import { UsersResponseData } from '../../../app/model/users-response';
import { GetParamsData } from '../../../app/model/common';
import { EventData, FormField } from '../../../app/model/form-fields';
import { CustomSelectDropdown } from '../../../components/custom-select-dopdown';
import { CustomSelectDropDown } from '../../../components/custom-select-drop-down';
import { CREATE_USER, DELETE_USER, GET_USER, UPDATE_USER } from '../../../app/services/user.service';
import { GET_ROLES } from '../../../app/services/roles.service';


export const Users = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [tableListData, setTableListData] = useState<UsersResponseData[]>([]);
    const [rolesData, setRolesData] = useState<any>()
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
    const [showCreatePopup, setShowCreatePopup] = useState<boolean>(false);
    const [isFirstEdit, setIsFirstEdit] = useState<boolean>(true);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [selectedData, setSelectedData] = useState<any>([]);
    const navigation = useNavigate();
    const [pageCount, setPageCount] = useState<GetParamsData>({
        limit: 10,
        page: 0,
    });

    const [formData, setFormData] = useState<FormField[]>([
        {
            title: "dropdown",
            type: "dropdown",
            label: "Roles",
            name: "user_role",
            formFloating: false,
            value: '',
            option: rolesData,
            required: true,
            placeholder: "Please select status",
            error: "Please select status",
            hideMandatoryStar: false,
        },
        {
            title: "input",
            type: "text",
            label: "Name",
            name: "name",
            formFloating: false,
            value: '',
            regex: "",
            required: true,
            placeholder: "Please enter name",
            error: "Please enter name",
            hideMandatoryStar: false,
        },
        {
            title: "input",
            type: "text",
            label: "Email",
            name: "email",
            formFloating: false,
            value: '',
            regex: /^[a-zA-Z0-9._%+-]+@[a-z]{3,10}.[a-z]{2,5}$/,
            required: true,
            placeholder: "Please enter email",
            error: "Please enter email",
            hideMandatoryStar: true,
        },
        {
            title: "input",
            type: "text",
            label: "Phone Number",
            formFloating: false,
            name: "mobile_number",
            value: '',
            regex: /^[6-9]\d{9}$/,
            maxLength: "10",
            required: true,
            placeholder: "Please enter phone number",
            error: "Please enter phone number",
            hideMandatoryStar: false,
            customParentClass: "ps-0",
            customClass: "rounded-start-0"
        },
        {
            title: "input",
            type: "password",
            label: "Password",
            name: "password",
            value: "",
            required: true,
            regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/,
            maxLength: "",
            hideMandatoryStar: false,
            placeholder: "Please enter password",
            error:
                "Please enter password",
        },
        {
            title: "textarea",
            type: "text",
            label: "Address",
            name: "address",
            formFloating: false,
            value: '',
            regex: "",
            required: false,
            placeholder: "Please enter address",
            error: "Please enter address",
            hideMandatoryStar: true,
        },
        {
            title: "file",
            type: "file",
            label: "Profile Image",
            name: "profile_image",
            value: "",
            required: false,
            regex: "",
            maxLength: "",
            isMultipleImages: false,
            placeholder: "Upload image",
            error: "Please upload image",
            hideMandatoryStar: false
        },
        {
            type: "button",
            class: "col-md-12 d-flex justify-content-end my-2",
            buttons: [
                {
                    title: 'Create',
                    action: 'add',
                    isCheckFormValidation: true,
                    class: 'btn btn-primary px-4 btn-sm f16 border-0 me-1'
                },
                {
                    title: 'Cancel',
                    action: 'cancel',
                    class: 'btn btn-outline-primary px-4 btn-sm f16'
                }
            ]
        },
    ])

    const staticTableData = {
        total: 10,
        rows: [
            {
                id: "1",
                name: "Prasanth",
                email: "prasanth@mail.com",
                mobile_number: "9892389982",
                address: "Hyderabad"
            },
            {
                id: "2",
                name: "Ravi",
                email: "ravi@mail.com",
                mobile_number: "9892389123",
                address: "Chennai"
            },
            {
                id: "3",
                name: "Rajesh",
                email: "rajesh@mail.com",
                mobile_number: "9892389827",
                address: "Delhi"
            },
        ]
    }

    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
    ];

    const customSelect = [
        {
            type: "single-select",
            label: "Select",
            name: "select_data",
            options: options,
            placeholder: "Select...",
            isSearch: true,
            isMultiSelect: true,
            isMultiSelectWithCheckBox: true,
        }
    ]

    useEffect(() => {
        getUsers(pageCount);
        getRoles();
    }, [])

    const getUsers = (pageData: any) => {
        setLoading(true);
        GET_USER(pageData).then((res: any) => {
            if (res?.status === "fail") {
                toast.error(res.message, { position: 'top-right' })
                setLoading(false);
            } else {
                if (res) {
                    res?.data?.data?.forEach((el: any) => {
                        el.address = el?.metadata?.address
                    })
                    setTableListData(res?.data);
                    setLoading(false);
                }
            }
        });
    }

    const getRoles = () => {
        setLoading(true);
        GET_ROLES({ limit: 1000 }).then((res: any) => {
            if (res?.status === "fail") {
                toast.error(res.message, { position: 'top-right' })
                setLoading(false);
            } else {
                if (res) {
                    const response = res?.data?.data
                    response?.forEach((el: any) => {
                        el.label = el?.code
                        el.value = el?.code
                    })
                    const data = formData;
                    data[0].option = response
                    setFormData([...data])
                    setRolesData(response)
                    setLoading(false);
                }
            }
        });
    }

    const onShowCreatePopup = () => {
        setIsEdit(false);
        setShowCreatePopup(true);
        const categoryFormData = formData;
        categoryFormData?.forEach((element: any) => {
            if (element?.type === "button") {
                for (let i = 0; i < element?.buttons?.length; i++) {
                    const ele = element?.buttons;
                    ele[0].title = "Create";
                    ele[0].action = "add";
                }
            }
            element.value = "";
        })
        setFormData([...formData])
    }

    const onActions = (data: any) => {
        setSelectedData(data?.data)
        console.log("sellected daa : ", data?.data);
        if (data?.type === "Edit") {
            setIsEdit(true);
            setShowCreatePopup(true);
            const formDataObj = formData;
            if (isFirstEdit) {
                const newFormData = formData
                newFormData?.splice(4, 1);
                setFormData([...newFormData]);
                setIsFirstEdit(false); // Set to false after applying once
            }
            formDataObj?.forEach((element: any) => {
                if (element?.type === "button") {
                    for (let i = 0; i < element?.buttons?.length; i++) {
                        const ele = element?.buttons;
                        ele[0].title = "Update";
                        ele[0].action = "edit";
                    }
                }
                element.value = data?.data[element.name]
            });
            setFormData([...formDataObj]);
        } else if (data?.type === "Delete") {
            setShowDeletePopup(true);
        } else if (data?.type === "link") {
            navigation(`/dashboard/users/${data?.data?.uuid}`)
        }
    }

    const onPageChange = (data: any) => {
        const pageData = pageCount;
        pageData.limit = data;
        setPageCount(pageData);
        getUsers(pageData)
    };

    const onSelectPage = (data: any) => {
        const pageData = pageCount;
        pageData.page = data;
        setPageCount(pageData);
        getUsers(pageData)
    };

    const onSearch = (value: any) => {
        const pageData = pageCount;
        pageData.search = value;
        setPageCount(pageData);
        getUsers(pageData);
    }

    const onDeleteUserConfirmation = () => {
        setLoading(true);
        DELETE_USER(selectedData?.uuid).then((res: any) => {
            if (res.status === "error") {
                toast.error(res.error, { position: "top-right" });
                setLoading(false);
            } else {
                if (res) {
                    setShowDeletePopup(false);
                    toast.success(res.message, { position: 'top-right', })
                    setLoading(false);
                    getUsers(pageCount);
                }
            }
        });
    }

    const onFormSubmitEvent = (event: any) => {
        const data = event;
        if (data?.type === "add") {
            const formData = data?.formData;
            formData.whatsapp_number = null
            formData.gender = null
            formData.status = 1
            formData.metadata = { address: data?.formData?.address }
            delete formData['undefined'];
            console.log("profile_image creation: ", formData)
            setLoading(true);
            CREATE_USER(formData).then((res: any) => {
                if (res.status === "fail") {
                    toast.error(res.error, { position: "top-right" });
                    setLoading(false);
                } else {
                    if (res) {
                        setShowCreatePopup(false);
                        toast.success(res.message, { position: 'top-right', })
                        setLoading(false);
                        getUsers(pageCount);
                    }
                }
            });
        } else if (data?.type === "edit") {
            const formData = data?.formData
            formData.whatsapp_number = null
            formData.gender = null
            formData.status = 1
            formData.metadata = { address: data?.formData?.address }
            delete formData['undefined']
            console.log("profile_image updation: ", formData)
            // setLoading(true);
            UPDATE_USER(selectedData?.uuid, formData).then((res: any) => {
                if (res.status === "fail") {
                    toast.error(res.error, { position: "top-right" });
                    setLoading(false);
                } else {
                    if (res) {
                        setShowCreatePopup(false);
                        toast.success(res.message, { position: 'top-right', })
                        setLoading(false);
                        getUsers(pageCount);
                    }
                }
            });
        } else if (data?.type === "cancel") {
            setShowCreatePopup(false)
        }
    }

    const getSelectedData = (data: any) => {
    }

    return (
        <div className='container-fluid content-bg-color'>
            <div className='row border-bottom-primary'>
                <div className='col-md-9 text-start d-flex align-items-center px-0'>
                    <h4>Users</h4>
                </div>
                <div className='col-md-3 text-end my-2 px-0'>
                    <button className='btn btn-primary border-0' onClick={onShowCreatePopup}>Create User</button>
                </div>
            </div>
            <div className='col-12 w-100 mt-4 pt-1'>
                <Search onSearch={(e) => onSearch(e)} customSearchCss={"col-md-3 px-0"} />
            </div>
            {/* <div className='row'>
                <div className="col-md-3 my-3">y
                    <CustomSelectDropdown itemsList={options} selectedData={getSelectedData} isMultiSelect={true} />
                </div>
                <div className="col-md-3 my-3">
                    <CustomSelectDropDown configData={customSelect} />  
                </div>
            </div> */}
            <div className='row'>
                <div className='col-md-12 px-0'>
                    <Loader LoadingReducer={loading} />
                    <DataTable
                        tableData={tableListData}
                        TableCols={UsersDataGridCols}
                        onActions={(e: any) => onActions(e)}
                        onPageChange={(e: any) => onPageChange(e)}
                        onSelectPageChange={(e) => onSelectPage(e)}
                        pageCount={pageCount}
                    />
                </div>
                {showCreatePopup && (
                    <div className={`modal modal-custom fade ${showCreatePopup ? "show" : "false"}  ${showCreatePopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
                        <Loader LoadingReducer={loading} />
                        <div className="modal-dialog modal-lg modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header border-bottom-primary mx-4 px-0">
                                    <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">{isEdit ? "Edit" : "Create"} User</strong></h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowCreatePopup(false)}></button>
                                </div>
                                <div className="modal-body px-4 text-start">
                                    <div className='row'>
                                        <DynamicForm
                                            userFormData={formData}
                                            columns={2}
                                            isEdit={isEdit}
                                            onFormSubmitEvent={(e) => onFormSubmitEvent(e)}
                                            customClass={"display-block"}
                                            isNotEmptyFormAfterSubmit={true} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {showDeletePopup && (
                    <>
                        <div className={`modal modal-custom fade ${showDeletePopup ? "show" : "false"}  ${showDeletePopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
                            <Loader LoadingReducer={loading} />
                            <div className="modal-dialog modal-md modal-dialog-centered">
                                <div className="modal-content text-start">
                                    <div className="modal-header border-bottom-primary mx-4 px-0">
                                        <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">Delete</strong></h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowDeletePopup(false)}></button>
                                    </div>
                                    <div className="modal-body pb-5">
                                        <div className="row px-2">
                                            <div className="col-md-12">
                                                <div className="mb-3">
                                                    <p className="f16 p-2">Are you sure you want to delete <span className='fw-bold '>{selectedData?.name || ""}</span> ?</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='px-2 text-end'>
                                            <button type="button" className="btn btn-primary px-4 btn-sm f16 me-3 border-0" onClick={onDeleteUserConfirmation}>Yes</button>
                                            <button type="button" className="btn btn-outline-primary px-4 btn-sm f16 text-primary-medium border-primary-medium" onClick={() => setShowDeletePopup(false)}>No</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}
