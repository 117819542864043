import API from "../utils/axios";
let common = "/v1/api";

export const GET_AUDIT_LOGS = (data: any): Promise<any> => {
    return API.get(`${common}/auditlogs`, { params: data });
};

export const CREATE_AUDIT_LOG = (data: any): Promise<any> => {
    return API.post(`${common}/auditlogs`, data);
};

export const UPDATE_AUDIT_LOG = (uuid: string, data: any): Promise<any> => {
    return API.put(`${common}/auditlogs/${uuid}`, data);
};

export const DELETE_AUDIT_LOG = (uuid: string): Promise<any> => {
    return API.delete(`${common}/auditlogs/${uuid}`);
};