import { Button, Step, StepLabel, Stepper, Typography } from '@mui/material'
import React, { useState } from 'react'
import { ShippingPartner } from '../shipping-details-components/shipping-partner';
import { Processing } from '../shipping-details-components/processing';
import { Shipping } from '../shipping-details-components/shipping';
import { DeliveryStatus } from '../shipping-details-components/delivery-status';

interface Props {
    shippingData?: any;
}
export const ShippingDetailsStepper = (props: Props) => {
    const steps = ['Shipping Partner', 'Processing', 'Shipping', 'Delivery Status'];
    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep: any) => prevActiveStep + 1);
    };

    const handleBack = () => {
        // if (steps.length - 1) {
        //         // if()
        // } else {
            setActiveStep((prevActiveStep: any) => prevActiveStep - 1);
        // }
    };

    const getStepContent = (stepIndex: any) => {
        switch (stepIndex) {
            case 0:
                return <ShippingPartner userShippingData={props?.shippingData} />;
            case 1:
                return <Processing />;
            case 2:
                return <Shipping />;
            case 3:
                return <DeliveryStatus />;
            default:
                return
        }
    };
    return (
        <div>
            {/* <Stepper activeStep={activeStep}> */}
            {/* {steps.map((label:any, index:number) => (
                    <Step key={index}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))} */}
            {/* </Stepper> */}
            <Stepper alternativeLabel activeStep={activeStep}>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div>
                {activeStep === steps.length ? (
                    <Typography className='text-center my-4 '>All steps completed - you're finished</Typography>
                ) : (
                    <div className='text-center stepper-button mx-auto '>
                        {/* <Typography className="text-center my-4">
                            <div>{"Welcome"}</div>
                            <div> {`Step ${activeStep + 1}`}</div>
                        </Typography> */}
                        <div className='my-4'>
                            {getStepContent(activeStep)}
                        </div>
                        <Button disabled={activeStep === 0} onClick={handleBack}>
                            Back
                        </Button>
                        <Button variant="contained" onClick={handleNext}>
                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                        </Button>
                    </div>
                )}
            </div>
        </div >
    )
}
