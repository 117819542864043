import API from "../utils/axios";
let common = "/v1/api";

export const GET_DISCOUNTS = (data: any): Promise<any> => {
    return API.get(`${common}/offers`, { params: data });
};

export const CREATE_DISCOUNT = (data: any): Promise<any> => {
    return API.post(`${common}/offers`, data);
};

export const UPDATE_DISCOUNT = (uuid: string, data: any): Promise<any> => {
    return API.put(`${common}/offers/${uuid}`, data);
};

export const DELETE_DISCOUNT = (uuid: string): Promise<any> => {
    return API.delete(`${common}/offers/${uuid}`);
};