import { DataTableCol } from "../../../../../components/data-table/types";

export const CampaignTrackingReportsDataGridCols: DataTableCol[] = [
    {
        title: 'name',
        control: 'name',
        sortable: true,
        canShowColumn: true,
    },
    {
        title: 'Activity',
        control: 'activity',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Date',
        control: 'date',
        isDateFormat:true,
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Status',
        control: 'status',
        isUserActivityStatus:true,
        sortable: true,
        canShowColumn: true
    },
];