import API from "../utils/axios";
let common = "/v1/api";

export const GET_PAYMENT_REPORTS = (data: any): Promise<any> => {
    return API.get(`${common}/user/payments`, { params: data });
};

export const GET_USER_ACTIVITY_REPORTS = (data: any): Promise<any> => {
    return API.get(`${common}/user-activity-reports`, { params: data });
};

export const GET_CAMPAIGN_TRACKING_REPORTS = (data: any): Promise<any> => {
    return API.get(`${common}/campaign-tracking-reports`, { params: data });
};

export const GET_REFUND_REPORTS = (data: any): Promise<any> => {
    return API.get(`${common}/refund-reports`, { params: data });
};

export const GET_DMV_ERROR_REPORTS = (data: any): Promise<any> => {
    return API.get(`${common}/dmv-error-reports`, { params: data });
};