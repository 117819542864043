import React, { useEffect, useRef, useState } from 'react'
import { DataTable } from '../../../components/data-table';
import { Loader } from '../../../components/loader';
import { Search } from '../../../components/search/search';
import { EmailTemplateDataGridCols } from './email-template-data-grid-cols';
import { useNavigate } from 'react-router-dom';
import { DynamicForm } from '../../../components/form-builder/form';
import { EmailTemplatesResponse } from '../../../app/model/email-templates.response';
import { GetParamsData } from '../../../app/model/common';
import { FormField } from '../../../app/model/form-fields';
import toast from 'react-hot-toast';
// import CKEditor from '@ckeditor/ckeditor5-react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { CREATE_EMAIL_TEMPLATE, DELETE_EMAIL_TEMPLATE, GET_EMAIL_TEMPLATES, GET_EMAIL_TEMPLATES_TYPES, UPDATE_EMAIL_TEMPLATE } from '../../../app/services/email-templates.service';

export const EmailTemplates = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [image, setImage] = useState<any>("");
    const [imageFile, setImageFile] = useState<any>();
    const [showFooter, setShowFooter] = useState<boolean>(false);
    const [tableListData, setTableListData] = useState<EmailTemplatesResponse[]>([]);
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
    const [showCreatePopup, setShowCreatePopup] = useState<boolean>(false);
    const [showEmailPreviewPopup, setShowEmailPreviewPopup] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [selectedData, setSelectedData] = useState<any>([]);
    const navigation = useNavigate();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const imageFileInputRef = useRef<HTMLInputElement>(null);
    const [emailTemplate, setEmailTemplate] = useState<any>({
        subject: "",
        body: "",
        footer: ""
    })
    const [formErrors, setFormErrors] = useState<any>({});
    const [pageCount, setPageCount] = useState<GetParamsData>({
        limit: 10,
        page: 0,
    });
    const [formData, setFormData] = useState<FormField[]>([
        {
            title: "input",
            type: "text",
            label: "Name",
            name: "name",
            formFloating: false,
            value: '',
            regex: '',
            required: true,
            placeholder: "Please enter name",
            error: "Please enter name",
            hideMandatoryStar: true,
            column: "6"
        },
        {
            title: "dropdown",
            type: "dropdown",
            label: "Email Type",
            name: "code",
            formFloating: false,
            value: '',
            regex: '',
            required: true,
            placeholder: "Please enter email type",
            error: "Please enter email type",
            hideMandatoryStar: true,
            column: "6"
        },
        {
            title: "input",
            type: "text",
            label: "Subject",
            name: "subject",
            formFloating: false,
            value: '',
            regex: '',
            required: true,
            placeholder: "Please enter subject",
            error: "Please enter subject",
            hideMandatoryStar: true,
            column: "12"
        },
        // {
        //     title: "file",
        //     type: "file",
        //     label: "Image",
        //     name: "image",
        //     formFloating: false,
        //     value: '',
        //     regex: '',
        //     required: false,
        //     placeholder: "Please upload image",
        //     error: "Please upload image",
        //     hideMandatoryStar: true,
        //     column: "12"
        // },
        {
            title: "ckEditor",
            type: "ckEditor",
            label: "Message",
            name: "body",
            formFloating: false,
            value: '',
            regex: '',
            required: true,
            placeholder: "Please upload image",
            error: "Please upload image",
            hideMandatoryStar: true,
            column: "12"
        },
        {
            title: "dropdown",
            type: "dropdown",
            label: "Status",
            name: "status",
            formFloating: false,
            value: '',
            option: [
                { label: 'ACTIVE', value: 1 },
                { label: 'IN ACTIVE', value: 0 }
            ],
            required: false,
            placeholder: "Please select status",
            error: "Please select status",
            hideMandatoryStar: false,
        },
        // {
        //     type: "button",
        //     class: "col-md-12 d-flex justify-content-end my-2",
        //     buttons: [
        //         {
        //             title: 'Add Footer',
        //             action: 'addFooter',
        //             isCheckFormValidation: false,
        //             class: 'btn btn-primary px-4 border-0 border-0 btn-sm f16 me-1'
        //         }
        //     ]
        // },

        // {
        //     title: "input",
        //     type: "text",
        //     label: "Organization Name",
        //     name: "organization_name",
        //     formFloating: false,
        //     value: '',
        //     regex: '',
        //     required: false,
        //     placeholder: "Please enter organization name",
        //     error: "Please enter organization name",
        //     hideMandatoryStar: true,
        // },


        // {
        //     title: "input",
        //     type: "text",
        //     label: "Organization Name",
        //     name: "organization_name",
        //     formFloating: false,
        //     value: '',
        //     regex: '',
        //     required: false,
        //     placeholder: "Please enter organization name",
        //     error: "Please enter organization name",
        //     hideMandatoryStar: true,
        // },
        // {
        //     title: "input",
        //     type: "text",
        //     label: "Organization Address",
        //     name: "organization_address",
        //     formFloating: false,
        //     value: '',
        //     regex: '',
        //     required: false,
        //     placeholder: "Please enter organization address",
        //     error: "Please enter organization address",
        //     hideMandatoryStar: true,
        // },
        // {
        //     title: "file",
        //     type: "file",
        //     label: "Organization Image",
        //     name: "organization_image",
        //     formFloating: false,
        //     value: '',
        //     regex: '',
        //     required: false,
        //     placeholder: "Please upload image",
        //     error: "Please upload image",
        //     hideMandatoryStar: true,
        // },


        {
            type: "button",
            class: "col-md-12 d-flex justify-content-end my-2",
            buttons: [
                {
                    title: 'Create',
                    action: 'add',
                    isCheckFormValidation: true,
                    class: 'btn btn-primary px-4 border-0 border-0 btn-sm f16 me-1'
                },
                {
                    title: 'Cancel',
                    action: 'cancel',
                    class: 'btn btn-outline-primary px-4 btn-sm f16'
                }
            ]
        },
    ])

    // const staticTableData = {
    //     total: 10,
    //     data: [
    //         {
    //             id: "1",
    //             email_type: "Welcome mail",
    //             from: "simpltags@info.com",
    //             subject: "Hi Team, Good morning",
    //             body: "Discover enriched data capabilities, powerful visualization tools, and personalized experiences tailored to your users' needs. Whether you're optimizing logistics, analyzing customer behavior, or planning infrastructure projects.",
    //             status: "Active"
    //         },
    //         {
    //             id: "2",
    //             email_type: "Congratulations",
    //             from: "simpltags@info.com",
    //             subject: "Hi Team, Congratulations",
    //             body: "Congratulations your vehicle registration is successful",
    //             status: "Active"
    //         },
    //         {
    //             id: "3",
    //             email_type: "Thank You",
    //             from: "simpltags@info.com",
    //             subject: "Hi Team,Thank You",
    //             body: "Thank you for using simple tags",
    //             status: "Active"
    //         },
    //     ]
    // }

    useEffect(() => {
        getEmailTemplates();
        getEmailTemplateTypes();
    }, [])

    const getEmailTemplates = () => {
        setLoading(true);
        GET_EMAIL_TEMPLATES(pageCount).then((res: any) => {
            if (res?.status === "fail") {
                toast.error(res.message, { position: 'top-right' })
                setLoading(false);
            } else {
                if (res) {
                    setTableListData(res?.data);
                    setLoading(false);
                }
            }
        });
    }

    const getEmailTemplateTypes = () => {
        setLoading(true);
        GET_EMAIL_TEMPLATES_TYPES(pageCount).then((res: any) => {
            if (res?.status === "fail") {
                toast.error(res.message, { position: 'top-right' })
                setLoading(false);
            } else {
                if (res) {
                    // setTableListData(res?.data);
                    const data: any = []
                    res?.data.forEach((element: any) => {
                        data.push({
                            label: element,
                            value: element
                        })
                    });
                    const categoryFormData = formData;
                    categoryFormData[1].option = data;
                    // categoryFormData?.forEach((element: any) => {
                    //     if (element?.type === "button") {
                    //         for (let i = 0; i < element?.buttons?.length; i++) {
                    //             const ele = element?.buttons;
                    //             ele[0].title = "Create";
                    //             ele[0].action = "add";
                    //         }
                    //     }
                    //     element.value = "";
                    // })
                    setFormData([...formData])
                    setLoading(false);
                }
            }
        });
    }




    const onShowCreatePopup = () => {
        // setIsEdit(false);
        // setShowCreatePopup(true);
        // const categoryFormData = formData;
        // categoryFormData?.forEach((element: any) => {
        //     if (element?.type === "button") {
        //         for (let i = 0; i < element?.buttons?.length; i++) {
        //             const ele = element?.buttons;
        //             ele[0].title = "Create";
        //             ele[0].action = "add";
        //         }
        //     }
        //     element.value = "";
        // })
        // setFormData([...formData])
        navigation(`./form`);
    }

    const onActions = (data: any) => {
        setSelectedData(data?.data)
        if (data?.type === "Edit") {
            navigation(`./form/${data?.data?.uuid}`);
            // setIsEdit(true);
            // setShowCreatePopup(true);
            // const formDataObj = formData;
            // formDataObj?.forEach((element: any) => {
            //     if (element?.type === "button") {
            //         for (let i = 0; i < element?.buttons?.length; i++) {
            //             const ele = element?.buttons;
            //             ele[0].title = "Update";
            //             ele[0].action = "edit";
            //         }
            //     }
            //     element.value = data?.data[element.name]
            // });
            // setFormData([...formDataObj]);
        } else if (data?.type === "Delete") {
            setShowDeletePopup(true);
        } else if (data?.type === "Email Preview") {
            setShowEmailPreviewPopup(true);
        }
    }


    const onPageChange = (data: any) => {
        const pageData = pageCount;
        pageData.limit = data;
        setPageCount(pageData);
    };

    const onSelectPage = (data: any) => {
        const pageData = pageCount;
        pageData.page = data;
        setPageCount(pageData);
    };

    const onSearch = (value: any) => {
        const pageData = pageCount;
        pageData.search = value;
        setPageCount(pageData);
    }

    const onDeleteUserConfirmation = () => {
        DELETE_EMAIL_TEMPLATE(selectedData?.uuid).then((res: any) => {
            if (res.status === "error") {
                toast.error(res.error, { position: "top-right" });
                setLoading(false);
            } else {
                if (res) {
                    setShowDeletePopup(false);
                    toast.success(res.message, { position: 'top-right', })
                    setLoading(false);
                    getEmailTemplates();
                }
            }
        });
    }

    const onFormSubmitEvent = (event: any) => {
        const data = event;
        if (data?.type === "add") {
            const formData = data?.formData;
            const tempElement = document.createElement("div");
            tempElement.innerHTML = formData?.body;
            const plainText = tempElement.textContent || tempElement.innerText || "";
            formData.plain_body = plainText;
            formData.format = "text";
            formData.type = "promotional";
            console.log(plainText);
            delete formData['undefined']
            // formData.finalPrice = data?.formData?.price - data?.formData?.discount_amount
            console.log('formData', formData);

            setLoading(true);
            CREATE_EMAIL_TEMPLATE(formData).then((res: any) => {
                if (res.status === "fail") {
                    toast.error(res.error, { position: "top-right" });
                    setLoading(false);
                } else {
                    if (res) {
                        setShowCreatePopup(false);
                        toast.success(res.message, { position: 'top-right', })
                        setLoading(false);
                        getEmailTemplates();
                    }
                }
            });
        } else if (data?.type === "edit") {
            const formData = data?.formData;
            const tempElement = document.createElement("div");
            tempElement.innerHTML = formData?.body;
            const plainText = tempElement.textContent || tempElement.innerText || "";
            formData.plain_body = plainText;
            formData.format = "text";
            formData.type = "promotional";
            console.log(plainText);
            delete formData['undefined']
            UPDATE_EMAIL_TEMPLATE(selectedData?.uuid, formData).then((res: any) => {
                if (res.status === "fail") {
                    toast.error(res.error, { position: "top-right" });
                    setLoading(false);
                } else {
                    if (res) {
                        setShowCreatePopup(false);
                        toast.success(res.message, { position: 'top-right', })
                        setLoading(false);
                        getEmailTemplates();
                    }
                }
            });
        } else if (data?.type === "cancel") {
            setShowCreatePopup(false)
        }
    }

    const handleInput = (e: any) => {
        const { name, value } = e.target;
        setEmailTemplate((prevData: any) => ({
            ...prevData,
            [name]: value,
        }));

        setFormErrors((prevErrors: any) => ({
            ...prevErrors,
            [name]: validateSpecificField(name, value),
        }));
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        const imageData: any = event.target.files?.[0];
        const imagesList = [];
        imagesList.push(imageData.name);
        let payload = {
            images: imagesList
        }
        // GET_PRESIGNED_URL(payload).then((res: any) => {
        //     if (res) {
        //         const result = S3Helper.uploadFilesToS3BySigned(res.data?.presigned_urls[imageData.name],
        //             imageData,
        //             imageData.type,
        //         );
        //         // const formData = formFieldsArray;
        //         // const { name, value } = e.target;
        //         // // const field = formFieldsArray[index];
        //         // const { regex, label } = field;
        //         // const newErrors: any = { ...errors };
        //         // if (regex && value && !RegExp(regex).test(value)) {
        //         //     newErrors[name] = `Please enter a valid ${label?.toLowerCase()}`;
        //         // } else {
        //         //     delete newErrors[name];
        //         // }
        //         // formData[index].value = res.data?.presigned_urls[imageData.name].split('?')[0];
        //         // setFormData((prevData: any) => ({
        //         //     ...prevData,
        //         //     [name]: res.data?.presigned_urls[imageData.name].split('?')[0],
        //         // }));
        //         const file = event.target.files?.[0];
        //         if (file) {
        //             const reader: any = new FileReader();
        //             reader.onloadend = () => {
        //                 setImage(reader.result);
        //             };
        //             reader.readAsDataURL(file);
        //         }
        //         setImage(res.data?.presigned_urls[imageData.name].split('?')[0])

        //         // setFormFieldsArray([...formData]);
        //         // setErrors(newErrors);
        //     }
        // })
        if (file) {
            const reader = new FileReader();
            reader.onload = (e: ProgressEvent<FileReader>) => {
                if (e.target) {
                    setImage(e.target.result as string);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const validateSpecificField = (name: any, value: any) => { }

    const onShowFooter = () => {
        setShowFooter(!showFooter)
    }

    const handleImageFileChange = (event: any) => {
        const file = event.target.files?.[0];
        const imageData: any = event.target.files?.[0];
        const imagesList = [];
        imagesList.push(imageData.name);
        let payload = {
            images: imagesList
        }
        if (file) {
            const reader = new FileReader();
            reader.onload = (e: ProgressEvent<FileReader>) => {
                if (e.target) {
                    setImageFile(e.target.result as string);
                }
            };
            reader.readAsDataURL(file);
        }
    }

    const onCreate = () => {
        // emailTemplate
        console.log('emailTemplate', emailTemplate);

    }

    return (
        <div className='container-fluid content-bg-color'>
            <div className='row border-bottom-primary'>
                <div className='col-md-9 text-start d-flex align-items-center px-0'>
                    <h4>Email Templates</h4>
                </div>
                <div className='col-md-3 text-end my-2 px-0'>
                    <button className='btn btn-primary border-0' onClick={onShowCreatePopup} >Create Email Template</button>
                </div>
            </div>
            <div className='col-12 w-100 mt-4 pt-1'>
                <Search onSearch={(e) => onSearch(e)} customSearchCss={"col-md-3 px-0"} />
            </div>
            <div className='row'>
                <div className='col-md-12 px-0'>
                    <Loader LoadingReducer={loading} />
                    <DataTable
                        tableData={tableListData}
                        TableCols={EmailTemplateDataGridCols}
                        onActions={(e: any) => onActions(e)}
                        onPageChange={(e: any) => onPageChange(e)}
                        onSelectPageChange={(e) => onSelectPage(e)}
                        pageCount={pageCount}
                    />
                    {/* <DynamicForm
                        userFormData={formData}
                        columns={2}
                        isEdit={isEdit}
                        onFormSubmitEvent={(e) => onFormSubmitEvent(e)}
                        customClass={"display-block"}
                        isNotEmptyFormAfterSubmit={true} /> */}
                </div>
                {showCreatePopup && (
                    <div className={`modal modal-custom fade ${showCreatePopup ? "show" : "false"}  ${showCreatePopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-lg modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header border-bottom-primary mx-4 px-0">
                                    <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">{isEdit ? "Edit" : "Create"} Email Template</strong></h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowCreatePopup(false)}></button>
                                </div>
                                <div className="modal-body px-4 text-start">
                                    {/* <div className='row'>
                                        <div className='col-md-12 my-2'>
                                            <label className='form-label'>Email Type <span className='text-danger ms-2'>*</span></label>
                                            <input type="text" className='form-control' name="email_type" value={""} placeholder='Please enter subject' onChange={(e) => handleInput(e)} />
                                        </div>
                                        <div className='col-md-12 my-2'>
                                            <label className='form-label'>Subject <span className='text-danger ms-2'>*</span></label>
                                            <input type="text" className='form-control' name="subject" value={""} placeholder='Please enter subject' onChange={(e) => handleInput(e)} />
                                        </div>
                                        <div className='col-md-4 rounded-3 mb-2'>
                                            <label className='text-start d-flex justify-content-start'>Image</label>
                                            {imageFile && (
                                                <div style={{ height: '130px', width: "180px", marginBottom: imageFile ? "20px" : "" }}>
                                                    <img src={imageFile} alt="image" className={`rounded ${imageFile ? "my-2" : ""}`} style={{ height: '100%', width: "100%", borderRadius: "20%" }} />
                                                </div>
                                            )}
                                            <input
                                                type='file'
                                                id={`InputFileOne`}
                                                ref={imageFileInputRef}
                                                name='image'
                                                accept='image/*'
                                                style={{ display: 'none' }}
                                                onChange={(e) => handleImageFileChange(e)}
                                            />
                                            <label htmlFor={`InputFileOne`} className={`btn btn-primary btn-sm f16 px-4 border-0 ${image ? "mt-0" : "mt-1"}`} >
                                                Upload
                                            </label>
                                        </div>
                                        <div className='col-md-12 my-2'>
                                            <label className='form-label'>Message <span className='text-danger ms-2'>*</span></label>
                                            <CKEditor
                                                className="h-50"
                                                editor={ClassicEditor}
                                                data={emailTemplate.body}
                                                onChange={(event: any, editor: any) => {
                                                    const data = editor.getData();
                                                    setEmailTemplate((prevData: any) => ({
                                                        ...prevData,
                                                        body: data
                                                    }));
                                                }}
                                            />
                                        </div>
                                        <div className='col-12 text-end'>
                                            <button className='btn btn-primary px-4 btn-sm f16 border-0' onClick={onShowFooter}>{showFooter ? "Remove" : "Add"} Footer</button>
                                        </div>
                                        {showFooter && (
                                            <div className='row'>
                                                <div className='col-md-6 my-2'>
                                                    <label className='form-label'>Organization Name <span className='text-danger ms-2'>*</span></label>
                                                    <input type="text" className='form-control' name="subject" value={""} placeholder='Please enter organization name' onChange={(e) => handleInput(e)} />
                                                </div>
                                                <div className='col-md-6 my-2'>
                                                    <label className='form-label'>Organization Address <span className='text-danger ms-2'>*</span></label>
                                                    <input type="text" className='form-control' name="subject" value={""} placeholder='Please enter organization address' onChange={(e) => handleInput(e)} />
                                                </div>
                                                <div className='col-md-6 rounded-3'>
                                                    <label className='text-start d-flex justify-content-start'>Organization Image </label>
                                                    {image && (
                                                        <div style={{ height: '130px', width: "180px", marginBottom: image ? "20px" : "" }}>
                                                            <img src={image} alt="image" className={`rounded ${image ? "my-2" : ""}`} style={{ height: '100%', width: "100%", borderRadius: "20%" }} />
                                                        </div>
                                                    )}
                                                    <input
                                                        type='file'
                                                        id={`InputFile`}
                                                        ref={fileInputRef}
                                                        name='profile-image'
                                                        accept='image/*'
                                                        style={{ display: 'none' }}
                                                        onChange={(e) => handleFileChange(e)}
                                                    />
                                                    <label htmlFor={`InputFile`} className={`btn btn-primary btn-sm f16 px-4 border-0 ${image ? "mt-0" : "mt-1"}`} >
                                                        Upload
                                                    </label>
                                                </div>
                                            </div>

                                        )}
                                    </div> */}
                                    <DynamicForm
                                        userFormData={formData}
                                        columns={2}
                                        isEdit={isEdit}
                                        onFormSubmitEvent={(e) => onFormSubmitEvent(e)}
                                        customClass={"display-block"}
                                        isNotEmptyFormAfterSubmit={true} />
                                    {/* <div className='px-2 text-end my-3'>
                                        <button type="button" className="btn btn-primary px-4 btn-sm f16 border-0 me-3" onClick={() => onCreate()}>Create</button>
                                        <button type="button" className="btn btn-outline-primary px-4 btn-sm f16 text-primary-medium border-primary-medium" onClick={() => setShowCreatePopup(false)}>Cancel</button>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {showDeletePopup && (
                    <>
                        <div className={`modal modal-custom fade ${showDeletePopup ? "show" : "false"}  ${showDeletePopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
                            <div className="modal-dialog modal-md modal-dialog-centered">
                                <div className="modal-content text-start">
                                    <div className="modal-header border-bottom-primary mx-4 px-0">
                                        <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">Delete</strong></h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowDeletePopup(false)}></button>
                                    </div>
                                    <div className="modal-body pb-5">
                                        <div className="row px-2">
                                            <div className="col-md-12">
                                                <div className="mb-3">
                                                    <p className="f16 p-2">Are you sure you want to delete <span className='fw-bold '>{selectedData?.email_type || ""}</span> ?</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='px-2 text-end'>
                                            <button type="button" className="btn btn-primary px-4 btn-sm f16 border-0 me-3" onClick={onDeleteUserConfirmation}>Yes</button>
                                            <button type="button" className="btn btn-outline-primary px-4 btn-sm f16 text-primary-medium border-primary-medium" onClick={() => setShowDeletePopup(false)}>No</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {showEmailPreviewPopup && (
                    <>
                        <div className={`modal modal-custom fade ${showEmailPreviewPopup ? "show" : "false"}  ${showEmailPreviewPopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
                            <div className="modal-dialog modal-lg modal-dialog-centered">
                                <div className="modal-content text-start">
                                    <div className="modal-header border-bottom-primary mx-4 px-0">
                                        <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">Email Preview</strong></h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowEmailPreviewPopup(false)}></button>
                                    </div>
                                    <div className="modal-body pb-5">
                                        <div className="row px-2">
                                            <div className="col-md-12">
                                                <div className='card p-3 px-4'>
                                                    {/* <div className=''><span className='fw-bold '>From : </span><span className=''>{selectedData?.from}</span></div> */}
                                                    <div className=''><span className='fw-bold'>Subject : </span>{selectedData?.subject}</div>
                                                    <div className='py-2 custom-box-shadow rounded-3 p-3 my-3' dangerouslySetInnerHTML={{ __html: selectedData?.body }}>
                                                        {/* {selectedData?.body} */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}
