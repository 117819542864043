import React from 'react';
import logo from './logo.svg';
import './App.css';
import './assets/css/custom.css';
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { RootNavigation } from './routes';
import { Toaster } from 'react-hot-toast';
import '@progress/kendo-theme-default/dist/all.css';

function App() {
  return (
    <div className="App">
      {/* <BrowserRouter> */}
      <HashRouter>
        <Toaster />
        <RootNavigation />
      </HashRouter >
      {/* </BrowserRouter> */}
    </div >
  );
}

export default App;
