import { DataTableCol } from "../../../components/data-table/types";

export const AuditLogsDataGridCols: DataTableCol[] = [
    {
        title: 'Type',
        control: 'log_type',
        sortable: true,
        canShowColumn: true,
    },
    {
        title: 'Date',
        control: 'created_dt',
        isDateFormat: true,
        sortable: true,
        canShowColumn: true,
    },
    {
        title: 'Customer Name',
        control: 'customer_name',
        sortable: true,
        canShowColumn: true,
    },
    // {
    //     title: 'Service Type',
    //     control: 'service_type',
    //     sortable: true,
    //     canShowColumn: true,
    // },
    {
        title: 'Description',
        control: 'message',
        sortable: true,
        canShowColumn: true
    },
    // {
    //     title: 'Actions',
    //     control: 'both',
    //     sortable: false,
    //     isEdit: true,
    //     isDelete: true,
    //     isView: false,
    //     canShowColumn: true
    // },
];