import { DataTableCol } from "../../../../../../components/data-table/types";

export const OrdersDataGridCols: DataTableCol[] = [
    {
        title: 'Order ID',
        control: 'order_id',
        sortable: true,
        canShowColumn: true,
    },
    {
        title: 'Date',
        control: 'created_dt',
        isDateFormat:true,
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Price',
        control: 'final_price',
        sortable: true,
        canShowColumn: true,
        isPrice: true,
    },
    // {
    //     title: 'Tag',
    //     control: 'tag',
    //     sortable: true,
    //     canShowColumn: true
    // },
    {
        title: 'Status',
        control: 'status',
        isStatus:true,
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Shipping Status',
        control: 'shipping_status',
        isOrderStatus: true,
        sortable: true,
        canShowColumn: true
    },
   
];