import API from "../utils/axios";
let common = "/v1/api";

export const GET_USER = (data: any): Promise<any> => {
  return API.get(`${common}/users`, { params: data });
};

export const CREATE_USER = (data: any): Promise<any> => {
  return API.post(`${common}/users`, data);
};

export const UPDATE_USER = (uuid: string, data: any): Promise<any> => {
  return API.put(`${common}/users/${uuid}`, data);
};

export const DELETE_USER = (uuid: string): Promise<any> => {
  return API.delete(`${common}/users/${uuid}`);
};