import { DataTableCol } from "../../../components/data-table/types";

export const RolePrivilegesDataGridCols: DataTableCol[] = [
    {
        title: 'Name',
        control: 'name',
        sortable: true,
        canShowColumn: true,
    },
    {
        title: 'Code',
        control: 'code',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Read',
        control: 'p_read',
        isInput: true,
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Write',
        control: 'p_write',
        isInput: true,
        sortable: true,
        canShowColumn: true
    },
];