import { DataTableCol } from "../../../components/data-table/types";

export const UsersDataGridCols: DataTableCol[] = [
    {
        title: 'Name',
        control: 'name',
        sortable: true,
        isLink: true,
        canShowColumn: true,
    },
    {
        title: 'Email',
        control: 'email',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Phone Number',
        control: 'mobile_number',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Address',
        control: 'address',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Actions',
        control: 'both',
        sortable: false,
        isEdit: true,
        isDelete: true,
        isView: false,
        canShowColumn: true
    },
];