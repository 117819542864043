import { DataTableCol } from "../../../../components/data-table/types";

export const RefundReportsDataGridCols: DataTableCol[] = [
    {
        title: 'Transaction ID',
        control: 'transaction_id',
        sortable: true,
        canShowColumn: true,
    },
    {
        title: 'Date',
        control: 'date',
        isDateFormat:true,
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Name',
        control: 'name',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Service Name',
        control: 'service_name',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Price',
        control: 'price',
        sortable: true,
        canShowColumn: true,
        isPrice: true,
    },
    {
        title: 'Status',
        control: 'status',
        isPaymentStatus:true,
        sortable: true,
        canShowColumn: true
    },
];