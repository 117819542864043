import React, { useEffect, useState } from 'react'
import { Loader } from '../../../components/loader';
import { DynamicForm } from '../../../components/form-builder/form';
import { ConfigurationDataGridCols } from './configuration-data-grid-cols';
import { DataTable } from '../../../components/data-table';
import { Search } from '../../../components/search/search';
import toast from 'react-hot-toast';
import { CouponsResponse } from '../../../app/model/coupons.response';
import { useNavigate } from 'react-router-dom';
import { GetParamsData } from '../../../app/model/common';
import { FormField } from '../../../app/model/form-fields';
import { CREATE_CONFIGURATION, DELETE_CONFIGURATION, GET_CONFIGURATIONS, UPDATE_CONFIGURATION } from '../../../app/services/configurations.service';

export const Configurations = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [tableListData, setTableListData] = useState<CouponsResponse[]>([]);
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
    const [showCreatePopup, setShowCreatePopup] = useState<boolean>(false);
    const [showDetailsPopup, setShowDetailsPopup] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [selectedData, setSelectedData] = useState<any>([]);
    const navigation = useNavigate();
    const currentDate = new Date().toISOString().split('T')[0];
    const [selectedType, setSelectedType] = useState<string>('');
    const [pageCount, setPageCount] = useState<GetParamsData>({
        limit: 10,
        page: 0,
    });

    const [formData, setFormData] = useState<FormField[]>([
        {
            title: "dropdown",
            type: "dropdown",
            label: "Type",
            name: "type",
            formFloating: false,
            value: '',
            option: [
                { label: 'DMV', value: "DMV" },
                { label: 'Pricing', value: "PRICING" }
            ],
            required: false,
            placeholder: "Please select type",
            error: "Please select type",
            hideMandatoryStar: false,
            isParentDropDown: true
        },
        {
            title: "input",
            type: "text",
            label: "Code",
            name: "code",
            formFloating: false,
            value: '',
            required: true,
            placeholder: "Please enter code",
            error: "Please enter code",
            hideMandatoryStar: false,
        },
        {
            title: "dropdown",
            type: "dropdown",
            label: "Status",
            name: "status",
            formFloating: false,
            value: '',
            option: [
                { label: 'ACTIVE', value: 1 },
                { label: 'IN ACTIVE', value: 0 }
            ],
            required: false,
            placeholder: "Please select status",
            error: "Please select status",
            hideMandatoryStar: false,
            customParentClass: "mt-2"
        },
        {
            title: "textarea",
            type: "text",
            label: "Description",
            name: "notes",
            formFloating: false,
            value: '',
            required: false,
            placeholder: "Please enter description",
            error: "Please enter description",
            hideMandatoryStar: false,
        },
        {
            title: "file",
            type: "file",
            label: "DMV Logo",
            name: "dmv_logo",
            value: "",
            required: false,
            regex: "",
            maxLength: "",
            isMultipleImages: false,
            placeholder: "Upload image",
            error: "Please upload image",
            hideMandatoryStar: true
        },
        {
            type: "button",
            class: "col-md-12 d-flex justify-content-end my-2",
            buttons: [
                {
                    title: 'Create',
                    action: 'add',
                    isCheckFormValidation: true,
                    class: 'btn btn-primary px-4 btn-sm border-0 f16 me-1'
                },
                {
                    title: 'Cancel',
                    action: 'cancel',
                    class: 'btn btn-outline-primary px-4 btn-sm f16'
                }
            ]
        },
    ])

    const profileImageField: FormField = {
        title: 'file',
        type: 'file',
        label: 'DMV Logo',
        name: 'dmv_logo',
        value: '',
        required: false,
        regex: '',
        maxLength: '',
        isMultipleImages: false,
        placeholder: 'Upload image',
        error: 'Please upload image',
        hideMandatoryStar: true,
    };

    const titleField: FormField = {
        title: 'input',
        type: 'text',
        label: 'Title',
        name: 'title',
        value: '',
        required: false,
        regex: '',
        maxLength: '',
        isMultipleImages: false,
        placeholder: 'Please enter title',
        error: 'Please enter title',
        hideMandatoryStar: true,
    };

    const subTitleField: FormField = {
        title: 'input',
        type: 'text',
        label: 'Sub Title',
        name: 'sub_title',
        value: '',
        required: false,
        regex: '',
        maxLength: '',
        isMultipleImages: false,
        placeholder: 'Please enter sub title',
        error: 'Please enter sub title',
        hideMandatoryStar: true,
    };

    const pricingInputField: FormField = {
        title: 'input',
        type: 'number',
        label: 'Pricing',
        name: 'overnight_shipping_price',
        formFloating: false,
        value: '',
        required: true,
        placeholder: 'Please enter overnight shipping price',
        error: 'Please enter overnight shipping price',
        hideMandatoryStar: false,
    };

    // const staticTableData: any = {
    //     total: 10,
    //     rows: [
    //         {
    //             id: "1",
    //             name: "10% Off Summer Sale",
    //             code: "SUMMER10",
    //             image: TenPercent,
    //             description: "Get 10% off on all summer sale items.",
    //             expiry_date: "2024-08-31",
    //             terms: "Applicable only on selected items.",
    //             status: "In Active",
    //         },
    //         {
    //             id: "2",
    //             name: "Free Shipping",
    //             code: "FREESHIP",
    //             image: FreeShipping,
    //             description: "Enjoy free shipping on orders over $50.",
    //             expiry_date: "2024-09-15",
    //             terms: "Valid on standard shipping only.",
    //             status: "Active",
    //         },
    //         {
    //             id: "3",
    //             name: "Buy One Get One Free",
    //             code: "BOGO",
    //             image: BuyOneGetOne,
    //             description: "Buy one item and get another one for free.",
    //             expiryDate: "2024-07-31",
    //             terms: "Offer valid on selected items.",
    //             status: "In Active",
    //         },
    //     ]
    // }

    useEffect(() => {
        const configurationFormData = formData;
        let updatedFormData = configurationFormData?.filter(
            (field: any) => field.name !== 'dmv_logo' && field.name !== 'pricing'
        );
        if (selectedType === 'DMV') {
            // Insert the profile image field before the button field
            const index = updatedFormData.findIndex((field) => field.type === 'button');
            updatedFormData.splice(index, 0, titleField, subTitleField, profileImageField,);
        } else if (selectedType === 'PRICING') {
            // Insert the pricing input field before the button field
            const index = updatedFormData.findIndex((field) => field.type === 'button');
            updatedFormData.splice(index, 0, pricingInputField);
        }

        setFormData(updatedFormData);
    }, [selectedType]);

    useEffect(() => {
        getConfigurations(pageCount);
    }, [])

    const getConfigurations = (pageData: any) => {
        setLoading(true);
        GET_CONFIGURATIONS(pageData).then((res: any) => {
            if (res?.status === "fail") {
                toast.error(res.message, { position: 'top-right' })
                setLoading(false);
            } else {
                if (res) {
                    setTableListData(res?.data);
                    setLoading(false);
                }
            }
        });
    }


    const onShowCreatePopup = () => {
        setIsEdit(false);
        setShowCreatePopup(true);
        const categoryFormData = formData;
        categoryFormData?.forEach((element: any) => {
            if (element?.type === "button") {
                for (let i = 0; i < element?.buttons?.length; i++) {
                    const ele = element?.buttons;
                    ele[0].title = "Create";
                    ele[0].action = "add";
                }
            }
            element.value = "";
        })
        setFormData([...formData])
    }

    const onActions = (data: any) => {
        setSelectedData(data?.data)
        console.log("data? .data : ", data?.data);
        if (data?.type === "Edit") {
            setIsEdit(true);
            setShowCreatePopup(true);
            // console.log("formdata : ", formData)
            // const configurationFormData: any = formData;
            // let updatedFormData = configurationFormData?.filter(
            //     (field: any) => field.name !== 'dmv_logo' && field.name !== 'pricing'
            // );
            // if (configurationFormData?.type === 'DMV' || selectedType === 'DMV') {
            //     // Insert the profile image field before the button field
            //     const index = updatedFormData.findIndex((field: any) => field.type === 'button');
            //     updatedFormData.splice(index, 0, titleField, subTitleField, profileImageField,);
            // } else if (configurationFormData?.type === "PRICING" || selectedType === 'PRICING') {
            //     // Insert the pricing input field before the button field
            //     const index = updatedFormData.findIndex((field: any) => field.type === 'button');
            //     updatedFormData.splice(index, 0, pricingInputField);
            // }
            // console.log("updated dformdata on edit : ", updatedFormData)
            // setFormData([...updatedFormData])
            const formDataObj = formData;
            formDataObj?.forEach((element: any) => {
                if (element?.type === "button") {
                    for (let i = 0; i < element?.buttons?.length; i++) {
                        const ele = element?.buttons;
                        ele[0].title = "Update";
                        ele[0].action = "edit";
                    }
                }
                element.value = data?.data[element.name]
            });
            setFormData([...formDataObj]);
        } else if (data?.type === "Delete") {
            setShowDeletePopup(true);
        } else if (data?.type === "showConfigDetails") {
            setShowDetailsPopup(true);
        }
    }

    const onPageChange = (data: any) => {
        const pageData = pageCount;
        pageData.limit = data;
        setPageCount(pageData);
        getConfigurations(pageData);
    };

    const onSelectPage = (data: any) => {
        const pageData = pageCount;
        pageData.page = data;
        setPageCount(pageData);
        getConfigurations(pageData);
    };

    const onSearch = (value: any) => {
        const pageData = pageCount;
        pageData.search = value;
        setPageCount(pageData);
        getConfigurations(pageData);
    }

    const onDeleteUserConfirmation = () => {
        setLoading(true);
        DELETE_CONFIGURATION(selectedData?.uuid).then((res: any) => {
            if (res.status === "error") {
                toast.error(res.error, { position: "top-right" });
                setLoading(false);
            } else {
                if (res) {
                    setShowDeletePopup(false);
                    toast.success(res.message, { position: 'top-right', })
                    setLoading(false);
                    getConfigurations(pageCount);
                }
            }
        });
    }

    const onChangeDropdown = (event: any) => {
        if (event.name === 'type') {
            setSelectedType(event.value);
        }
    }

    const onFormSubmitEvent = (event: any) => {
        const data = event;
        if (data?.type === "add") {
            const formData = data?.formData;
            formData.access = "FRONTEND"
            formData.status = Number(data?.formData?.status)
            if (formData.type === "DMV") {
                formData.value = { dmv_logo: formData?.dmv_logo, title: formData.title, sub_title: formData?.sub_title }
            } else if (formData.type === "PRICING") {
                formData.value = { overnight_shipping_price: formData?.overnight_shipping_price }
            } else {
                formData.value = {}
            }
            delete formData['undefined']
            console.log("formData : ", formData)
            setLoading(true);
            CREATE_CONFIGURATION(formData).then((res: any) => {
                if (res.status === "fail") {
                    toast.error(res.error, { position: "top-right" });
                    setLoading(false);
                } else {
                    if (res) {
                        setShowCreatePopup(false);
                        toast.success(res.message, { position: 'top-right', })
                        setLoading(false);
                        getConfigurations(pageCount);
                    }
                }
            });
        } else if (data?.type === "edit") {
            const formData = data?.formData
            formData.status = Number(data?.formData?.status)
            if (formData.type === "DMV") {
                formData.value = { dmv_logo: formData?.dmv_logo, title: formData.title, sub_title: formData?.sub_title }
            } else if (formData.type === "PRICING") {
                formData.value = { overnight_shipping_price: formData?.overnight_shipping_price }
            } else {
                formData.value = {}
            }
            delete formData['undefined']
            console.log("update config : ", formData)
            setLoading(true);
            UPDATE_CONFIGURATION(selectedData?.uuid, formData).then((res: any) => {
                if (res.status === "fail") {
                    toast.error(res.error, { position: "top-right" });
                    setLoading(false);
                } else {
                    if (res) {
                        setShowCreatePopup(false);
                        toast.success(res.message, { position: 'top-right', })
                        setLoading(false);
                        getConfigurations(pageCount);
                    }
                }
            });
        } else if (data?.type === "cancel") {
            setShowCreatePopup(false)
        }
    }

    return (
        <div className='container-fluid content-bg-color'>
            <div className='row border-bottom-primary'>
                <div className='col-md-9 text-start d-flex align-items-center px-0'>
                    <h4>Configurations</h4>
                </div>
                <div className='col-md-3 text-end my-2 px-0'>
                    <button className='btn btn-primary border-0' onClick={onShowCreatePopup}>Create Configuration</button>
                </div>
            </div>
            <div className='row'>
                <div className='col-12 w-100 mt-4 pt-1'>
                    <Search onSearch={(e) => onSearch(e)} customSearchCss={"col-md-3 px-0"} />
                </div>
            </div>
            <div className='row'>
                <div className='col-md-12 px-0'>
                    <Loader LoadingReducer={loading} />
                    <DataTable
                        tableData={tableListData}
                        TableCols={ConfigurationDataGridCols}
                        onActions={(e: any) => onActions(e)}
                        onPageChange={(e: any) => onPageChange(e)}
                        onSelectPageChange={(e) => onSelectPage(e)}
                        pageCount={pageCount}
                    />
                </div>
                {showCreatePopup && (
                    <div className={`modal modal-custom fade ${showCreatePopup ? "show" : "false"}  ${showCreatePopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
                        <Loader LoadingReducer={loading} />
                        <div className="modal-dialog modal-lg modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header border-bottom-primary mx-4 px-0">
                                    <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">{isEdit ? "Edit" : "Create"} Configuration</strong></h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowCreatePopup(false)}></button>
                                </div>
                                <div className="modal-body px-4 text-start">
                                    <div className='row'>
                                        <DynamicForm
                                            userFormData={formData}
                                            columns={2}
                                            isEdit={isEdit}
                                            onFormSubmitEvent={(e) => onFormSubmitEvent(e)}
                                            customClass={"display-block"}
                                            isNotEmptyFormAfterSubmit={true}
                                            onParentDropdownEvent={(e) => onChangeDropdown(e)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {showDeletePopup && (
                    <>
                        <div className={`modal modal-custom fade ${showDeletePopup ? "show" : "false"}  ${showDeletePopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
                            <Loader LoadingReducer={loading} />
                            <div className="modal-dialog modal-md modal-dialog-centered">
                                <div className="modal-content text-start">
                                    <div className="modal-header border-bottom-primary mx-4 px-0">
                                        <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">Delete</strong></h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowDeletePopup(false)}></button>
                                    </div>
                                    <div className="modal-body pb-5">
                                        <div className="row px-2">
                                            <div className="col-md-12">
                                                <div className="mb-3">
                                                    <p className="f16 p-2">Are you sure you want to delete <span className='fw-bold '>{selectedData?.type || ""}</span> ?</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='px-2 text-end'>
                                            <button type="button" className="btn btn-primary px-4 btn-sm f16 border-0 me-3" onClick={onDeleteUserConfirmation}>Yes</button>
                                            <button type="button" className="btn btn-outline-primary px-4 btn-sm f16 text-primary-medium border-primary-medium" onClick={() => setShowDeletePopup(false)}>No</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {showDetailsPopup && (
                    <>
                        <div className={`modal modal-custom fade ${showDetailsPopup ? "show" : "false"}  ${showDetailsPopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
                            <div className="modal-dialog modal-lg modal-dialog-centered">
                                <div className="modal-content text-start">
                                    <div className="modal-header border-bottom-primary mx-4 px-0">
                                        <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">Configuration Details</strong></h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowDetailsPopup(false)}></button>
                                    </div>
                                    <div className="modal-body pb-5">

                                        {/* <div className="row px-2">
                                            <div className="col-md-5">
                                                <div className="mb-3 h-100">
                                                    <img src={selectedData?.image} className='w-100 rounded' />
                                                </div>
                                            </div>
                                            <div className="col-md-7">
                                                <div className="mb-3">
                                                    <div className='row'>
                                                        <div className='col-12 col-md-4 fw-bold'>Coupon Name</div>
                                                        <div className="col-12 col-md-8"><span className='me-md-3'>:</span><span>{selectedData?.name || "-"}</span></div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-12 col-md-4 fw-bold'>Code</div>
                                                        <div className="col-12 col-md-8"><span className='me-md-3'>:</span><span>{selectedData?.code || "-"}</span></div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-12 col-md-4 fw-bold'>Expiry Date</div>
                                                        <div className="col-12 col-md-8"><span className='me-md-3'>:</span><span>{selectedData?.end_dt || "-"}</span></div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-12 col-md-4 fw-bold'>Description</div>
                                                        <div className="col-12 col-md-8"><span className='me-md-3'>:</span><span>{selectedData?.notes || "-"}</span></div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-12 col-md-4 fw-bold'>Terms</div>
                                                        <div className="col-12 col-md-8"><span className='me-md-3'>:</span><span>{selectedData?.terms || "-"}</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='px-2 text-end'>
                                            <button type="button" className="btn btn-outline-primary px-4 btn-sm f16 text-primary-medium border-primary-medium" onClick={() => setShowDetailsPopup(false)}>Cancel</button>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}
