import { DataTableCol } from "../../../../components/data-table/types";

export const DMVErrorReportsDataGridCols: DataTableCol[] = [
    {
        title: 'Service Name',
        control: 'service_name',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Error',
        control: 'error',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Description',
        control: 'description',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Status',
        control: 'status',
        isUserActivityStatus: true,
        sortable: true,
        canShowColumn: true
    },
];