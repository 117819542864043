import { DataTableCol } from "../../../components/data-table/types";

export const UserFlowDataGridCols: DataTableCol[] = [
    {
        title: 'Flow State',
        control: 'flow_state',
        sortable: true,
        canShowColumn: true,
    },
    {
        title: 'name',
        control: 'name',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Date',
        control: 'date',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Status',
        control: 'status',
        isUserActivityStatus: true,
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Payment Status',
        control: 'payment_status',
        isPaymentStatus: true,
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Order Status',
        control: 'order_status',
        isOrderStatus: true,
        sortable: true,
        canShowColumn: true
    },
];