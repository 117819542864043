import React, { useEffect, useState } from 'react'
import { GetParamsData } from '../../../app/model/common';
import { useNavigate, useParams } from 'react-router-dom';
import { Search } from '../../../components/search/search';
import { Loader } from '../../../components/loader';
import { DataTable } from '../../../components/data-table';
import { PrivilegesDataGridCols } from '../privileges/privileges-data-grid-cols';
import { DynamicForm } from '../../../components/form-builder/form';
import { PriceConfigDataGridCols } from './price-config-data-grid-cols';
import { CREATE_SERVICE_TYPE, GET_SERVICE_TYPE, UPDATE_SERVICE_TYPE } from '../../../app/services/service-type.service';
import toast from 'react-hot-toast';
import { BreadCrumb } from '../../../components/bread-crumb';

export const PriceConfig = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [tableListData, setTableListData] = useState<any>([]);
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
    const [showCreatePopup, setShowCreatePopup] = useState<boolean>(false);
    const [priceConfigData, setPriceConfigData] = useState<any[]>([]);
    const [serviceData, setServiceData] = useState<any>();
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [selectedData, setSelectedData] = useState<any>([]);
    const navigation = useNavigate();
    const params = useParams();
    const [pageCount, setPageCount] = useState<GetParamsData>({
        limit: 10,
        page: 0,
    });

    const [formData, setFormData] = useState<any>([
        {
            title: "input",
            type: "text",
            label: "Name",
            name: "service_name",
            formFloating: false,
            value: '',
            regex: "",
            required: true,
            placeholder: "Please enter name",
            error: "Please enter name",
            hideMandatoryStar: false,
        },
        {
            title: "input",
            type: "number",
            label: "Price",
            name: "price",
            formFloating: false,
            value: '',
            regex: '',
            required: true,
            placeholder: "Please enter price",
            error: "Please enter price",
            hideMandatoryStar: true,
        },
        {
            type: "button",
            class: "col-md-12 d-flex justify-content-end my-2",
            buttons: [
                {
                    title: 'Create',
                    action: 'add',
                    isCheckFormValidation: true,
                    class: 'btn btn-primary px-4 btn-sm border-0 f16 me-1'
                },
                {
                    title: 'Cancel',
                    action: 'cancel',
                    class: 'btn btn-outline-primary px-4 btn-sm f16'
                }
            ]
        },
    ])

    const [breadcrumbInfo, setBreadcrumbInfo] = useState<any>([
        {
            label: "Services",
            value: "Services",
            routerLink: "/dashboard/services",
            isActive: false,
        },
        {
            label: "Services",
            value: "Services : ",
            routerLink: `/dashboard/services/${params?.service_uuid}`,
            isActive: true,
        },
    ]);


    const staticTableData = {
        total: 10,
        data: [
            {
                name: "DMV Registration Fees",
                price: "1300",
                status: 1
            },
            {
                name: "CA DMV BPA Fee",
                price: "1200",
                status: 1
            },
            {
                name: "Simple Tags Service Fee",
                price: "1500",
                status: 1
            },
            {
                name: "Shipping & Handling",
                price: "1000",
                status: 1
            },
            {
                name: "Convenience Fee",
                price: "1000",
                status: 1
            },
        ]
    }

    useEffect(() => {
        getServiceDetailsById(params?.service_uuid);
    }, [])

    const getServiceDetailsById = (userUuid: any) => {
        setLoading(true);
        GET_SERVICE_TYPE({ uuid: userUuid }).then((res: any) => {
            if (res?.status === "fail") {
                toast.error(res.message, { position: 'top-right' })
                setLoading(false);
            } else {
                if (res) {
                    const response = res?.data?.data[0]
                    const service = [response?.price_config]
                    const dataService = response?.price_config
                        ? Object.entries(response.price_config).map(([key, value], index) => ({ service_name: key, price: value, index }))
                        : []
                    const preparedData: any = {
                        total: dataService?.length,
                        data: dataService
                    }
                    setPriceConfigData(preparedData)
                    setServiceData(response)
                    // setPriceConfigData(
                    //     response?.price_config
                    //         ? Object.entries(response.price_config).map(([key, value]) => ({ name: key, price: value }))
                    //         : []
                    // );
                    const data = breadcrumbInfo;
                    data[1].value = response?.name
                    setBreadcrumbInfo([...data])
                    setLoading(false);
                }
            }
        });
    }

    const getPrivileges = (pageData: any) => {
        // setLoading(true);
        // GET_ACCESS_OBJECTS(pageData).then((res: any) => {
        //     if (res?.status === "fail") {
        //         toast.error(res.message, { position: 'top-right' })
        //         setLoading(false);
        //     } else {
        //         if (res) {
        //             setTableListData(res?.data);
        //             setLoading(false);
        //         }
        //     }
        // });
    }

    const onShowCreatePopup = () => {
        setIsEdit(false);
        setShowCreatePopup(true);
        const categoryFormData = formData;
        categoryFormData?.forEach((element: any) => {
            if (element?.type === "button") {
                for (let i = 0; i < element?.buttons?.length; i++) {
                    const ele = element?.buttons;
                    ele[0].title = "Create";
                    ele[0].action = "add";
                }
            }
            element.value = "";
        })
        setFormData([...formData])
    }

    const onActions = (data: any) => {
        setSelectedData(data?.data)
        if (data?.type === "Edit") {
            setIsEdit(true);
            setShowCreatePopup(true);
            const formDataObj = formData;
            formDataObj?.forEach((element: any) => {
                if (element?.type === "button") {
                    for (let i = 0; i < element?.buttons?.length; i++) {
                        const ele = element?.buttons;
                        ele[0].title = "Update";
                        ele[0].action = "edit";
                    }
                }
                element.value = data?.data[element.name]
            });
            setFormData([...formDataObj]);
        } else if (data?.type === "Delete") {
            setShowDeletePopup(true);
        }
    }

    const onPageChange = (data: any) => {
        const pageData = pageCount;
        pageData.limit = data;
        setPageCount(pageData);
        getPrivileges(pageData)
    };

    const onSelectPage = (data: any) => {
        const pageData = pageCount;
        pageData.page = data;
        setPageCount(pageData);
        getPrivileges(pageData);
    };

    const onSearch = (value: any) => {
        const pageData = pageCount;
        pageData.search = value;
        setPageCount(pageData);
        getPrivileges(pageData)
    }

    const onDeleteUserConfirmation = () => {
        const updatedServiceData = { ...serviceData };
        delete updatedServiceData.price_config[selectedData?.service_name];
        delete updatedServiceData['uuid']
        delete updatedServiceData['created_dt']

        setLoading(true);
        UPDATE_SERVICE_TYPE(serviceData?.uuid, updatedServiceData).then((res: any) => {
            if (res.status === "fail") {
                toast.error(res.error, { position: "top-right" });
                setLoading(false);
            } else {
                if (res) {
                    setShowDeletePopup(false);
                    toast.success("Deleted Successfully!", { position: 'top-right', })
                    setLoading(false);
                    getServiceDetailsById(serviceData?.uuid)
                }
            }
        });
    }

    // const onFormSubmitEvent = (event: any) => {
    //     const data = event;
    //     if (data?.type === "add") {
    //         const formData = data?.formData;
    //         delete formData['undefined'];
    //         console.log("form data for price config creation: ", formData);

    //         const servicePriceData = { ...serviceData }; // Make a copy of the serviceData object

    //         // Ensure price_config exists and is an object
    //         if (!servicePriceData.price_config) {
    //             servicePriceData.price_config = {};
    //         }

    //         // Merge the new formData into the existing price_config
    //         Object.keys(formData).forEach(key => {
    //             if (key in servicePriceData.price_config) {
    //                 servicePriceData.price_config['key'] = formData?.name;
    //                 servicePriceData.price_config['value'] = formData?.price;
    //             } else {
    //                 servicePriceData[key] = formData[key];
    //             }
    //         });
    //         console.log("service proce dat A : ", servicePriceData)
    //         // setLoading(true);
    //         // CREATE_ACCESS_OBJECT(formData).then((res: any) => {
    //         //     if (res.status === "fail") {
    //         //         toast.error(res.error, { position: "top-right" });
    //         //         setLoading(false);
    //         //     } else {
    //         //         if (res) {
    //         //             setShowCreatePopup(false);
    //         //             toast.success(res.message, { position: 'top-right', })
    //         //             setLoading(false);
    //         //             getPrivileges(pageCount);
    //         //         }
    //         //     }
    //         // });
    //     } else if (data?.type === "edit") {
    //         const formData = data?.formData
    //         delete formData['undefined']
    //         // setLoading(true);
    //         // UPDATE_ACCESS_OBJECT(selectedData?.uuid, formData).then((res: any) => {
    //         //     if (res.status === "fail") {
    //         //         toast.error(res.error, { position: "top-right" });
    //         //         setLoading(false);
    //         //     } else {
    //         //         if (res) {
    //         //             setShowCreatePopup(false);
    //         //             toast.success(res.message, { position: 'top-right', })
    //         //             setLoading(false);
    //         //             getPrivileges(pageCount);
    //         //         }
    //         //     }
    //         // });
    //     } else if (data?.type === "cancel") {
    //         setShowCreatePopup(false)
    //     }
    // }

    const onFormSubmitEvent = (event: any) => {
        const data = event;
        if (data?.type === "add") {
            const formData = data?.formData;

            // Make a copy of serviceData
            const updatedServiceData = { ...serviceData };

            // Ensure price_config exists and is an object
            if (!updatedServiceData.price_config) {
                updatedServiceData.price_config = {};
            }

            // Add or update the formData into price_config
            // updatedServiceData.price_config[formData.price] = formData.price;
            updatedServiceData.price_config[formData.service_name] = formData.price;
            updatedServiceData.status = 1
            delete updatedServiceData['uuid']
            delete updatedServiceData['created_dt']

            // Update serviceData with the modified price_config
            // setServiceData(updatedServiceData);
            setLoading(true);
            UPDATE_SERVICE_TYPE(serviceData?.uuid, updatedServiceData).then((res: any) => {
                if (res.status === "fail") {
                    toast.error(res.error, { position: "top-right" });
                    setLoading(false);
                } else {
                    if (res) {
                        setShowCreatePopup(false);
                        toast.success(res.message, { position: 'top-right', })
                        setLoading(false);
                        getServiceDetailsById(serviceData?.uuid)
                    }
                }
            });

            setShowCreatePopup(false); // Close popup after submission
        } else if (data?.type === "edit") {
            const formData = data?.formData;

            // // Make a copy of serviceData
            const updatedServiceData = { ...serviceData };

            // Update the existing entry in price_config
            // updatedServiceData.price_config[selectedData?.service_name] = formData.service_name;
            // updatedServiceData.price_config[selectedData?.service_name] = formData.price;
            if (selectedData?.index !== undefined) {
                const keys = Object.keys(updatedServiceData.price_config);
                const key = keys[selectedData.index];

                if (key) {
                    // Delete the old key and add the new key-value pair
                    const newKey = formData.service_name || key;  // Use a new key if provided
                    const newValue = formData.price;
                    console.log('Old newKey:===', newKey, newValue);

                    delete updatedServiceData.price_config[key];
                    updatedServiceData.price_config[newKey] = newValue;
                }
            }

            delete updatedServiceData['uuid']
            delete updatedServiceData['created_dt']

            // Update serviceData with the modified price_config
            // setServiceData(updatedServiceData);
            setLoading(true)
            UPDATE_SERVICE_TYPE(serviceData?.uuid, updatedServiceData).then((res: any) => {
                if (res.status === "fail") {
                    toast.error(res.error, { position: "top-right" });
                    setLoading(false);
                } else {
                    if (res) {
                        setShowCreatePopup(false);
                        toast.success(res.message, { position: 'top-right', })
                        setLoading(false);
                        getServiceDetailsById(serviceData?.uuid)
                    }
                }
            });

            // Here you would typically send your update API call with updatedServiceData as payload
            // Example:
            // UPDATE_ACCESS_OBJECT(selectedData?.uuid, updatedServiceData).then((res: any) => {
            //     // Handle response
            // });

            setShowCreatePopup(false); // Close popup after submission
        } else if (data?.type === "cancel") {
            setShowCreatePopup(false); // Close popup on cancel
        }
    }
    return (
        <div className='container-fluid content-bg-color'>
            <div className='row border-bottom-primary'>
                <div className='col-md-9 px-4 py-3 fs-5'>
                    <BreadCrumb breadCrumbList={breadcrumbInfo}></BreadCrumb>
                </div>
                <div className='col-md-3 text-end my-2 px-0'>
                    <button className='btn btn-primary border-0' onClick={onShowCreatePopup}>Create Price Config</button>
                </div>
            </div>
            <div className='col-12 w-100 mt-4 pt-1'>
                <Search onSearch={(e) => onSearch(e)} customSearchCss={"col-md-3 px-0"} />
            </div>
            <div className='row'>
                <div className='col-md-12 px-0'>
                    <Loader LoadingReducer={loading} />
                    <DataTable
                        tableData={priceConfigData}
                        TableCols={PriceConfigDataGridCols}
                        onActions={(e: any) => onActions(e)}
                        onPageChange={(e: any) => onPageChange(e)}
                        onSelectPageChange={(e) => onSelectPage(e)}
                        pageCount={pageCount}
                    />
                </div>
                {showCreatePopup && (
                    <div className={`modal modal-custom fade ${showCreatePopup ? "show" : "false"}  ${showCreatePopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
                        <Loader LoadingReducer={loading} />
                        <div className="modal-dialog modal-lg modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header border-bottom-primary mx-4 px-0">
                                    <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">{isEdit ? "Edit" : "Create"} Price Config</strong></h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowCreatePopup(false)}></button>
                                </div>
                                <div className="modal-body px-4 text-start">
                                    <div className='row'>
                                        <DynamicForm
                                            userFormData={formData}
                                            columns={2}
                                            isEdit={isEdit}
                                            onFormSubmitEvent={(e) => onFormSubmitEvent(e)}
                                            customClass={"display-block"}
                                            isNotEmptyFormAfterSubmit={true} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {showDeletePopup && (
                    <>
                        <div className={`modal modal-custom fade ${showDeletePopup ? "show" : "false"}  ${showDeletePopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
                            <Loader LoadingReducer={loading} />
                            <div className="modal-dialog modal-md modal-dialog-centered">
                                <div className="modal-content text-start">
                                    <div className="modal-header border-bottom-primary mx-4 px-0">
                                        <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">Delete</strong></h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowDeletePopup(false)}></button>
                                    </div>
                                    <div className="modal-body pb-5">
                                        <div className="row px-2">
                                            <div className="col-md-12">
                                                <div className="mb-3">
                                                    <p className="f16 p-2">Are you sure you want to delete <span className='fw-bold '>{selectedData?.service_name || ""}</span> ?</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='px-2 text-end'>
                                            <button type="button" className="btn btn-primary px-4 btn-sm border-0 f16 me-3" onClick={onDeleteUserConfirmation}>Yes</button>
                                            <button type="button" className="btn btn-outline-primary px-4 btn-sm f16 text-primary-medium border-primary-medium" onClick={() => setShowDeletePopup(false)}>No</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}
