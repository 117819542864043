import { DataTableCol } from "../../../../../components/data-table/types";

export const UsersAddressDataGridCols: DataTableCol[] = [
    {
        title: 'Company Name',
        control: 'company_name',
        sortable: true,
        canShowColumn: true,
    },
    {
        title: 'Street Address',
        control: 'street_address',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Apt / Unit / Suite',
        control: 'unit',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'City',
        control: 'city',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'State',
        control: 'state',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'ZIP Code',
        control: 'zip_code',
        sortable: true,
        canShowColumn: true
    }
];