import React, { useEffect, useState } from 'react'
import { Loader } from '../../../../../components/loader'
import { DataTable } from '../../../../../components/data-table'
import { Search } from '../../../../../components/search/search'
import { UserActivityReportsDataGridCols } from './user-activity-reports-data-grid-cols'
import { useNavigate } from 'react-router-dom'
import { UserActivityReportsResponse } from '../../../../../app/model/user-activity.response'
import { GetParamsData } from '../../../../../app/model/common'

export const UserActivityReports = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [tableListData, setTableListData] = useState<UserActivityReportsResponse[]>([]);
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
    const [showCreatePopup, setShowCreatePopup] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [selectedData, setSelectedData] = useState<any>([]);
    const navigation = useNavigate();
    const [pageCount, setPageCount] = useState<GetParamsData>({
        limit: 10,
        page: 0,
    });

    const staticTableData = {
        total: 10,
        rows: [
            {
                name: "Prasanth",
                activity: "Logged in",
                date: "2024-4-01",
                status: "Active"
            },
            {
                name: "Ravi",
                activity: "Checking the services",
                date: "2024-6-01",
                status: "In Active"
            },
        ]
    }

    useEffect(() => {
        getUserActivityReports();
    }, [])

    const getUserActivityReports = () => {
        // setLoading(true);
        // GET_USER_ACTIVITY_REPORTS(pageCount).then((res: any) => {
        //     if (res?.status === "fail") {
        // toast.error(res.message, { position: 'top-right' })
        //         setLoading(false);
        //     } else {
        //         if (res) {
        //             setTableListData(res?.data);
        //             setLoading(false);
        //         }
        //     }
        // });
    }

    const onActions = (data: any) => {
        setSelectedData(data?.data)
        if (data?.type === "Delete") {
            setShowDeletePopup(true);
        }
    }

    const onPageChange = (data: any) => {
        const pageData = pageCount;
        pageData.limit = data;
        setPageCount(pageData);
    };

    const onSelectPage = (data: any) => {
        const pageData = pageCount;
        pageData.page = data;
        setPageCount(pageData);
    };

    const onSearch = (value: any) => {
        const pageData = pageCount;
        pageData.search = value;
        setPageCount(pageData);
    }

    return (
        <div className='container-fluid content-bg-color'>
            <div className='col-12 w-100 mt-2 pt-2'>
                <Search onSearch={(e) => onSearch(e)} customSearchCss={"col-md-3"} />
            </div>
            <div className='row'>
                <div className='col-md-12'>
                    <Loader LoadingReducer={loading} />
                    <DataTable
                        tableData={staticTableData}
                        TableCols={UserActivityReportsDataGridCols}
                        onActions={(e: any) => onActions(e)}
                        onPageChange={(e: any) => onPageChange(e)}
                        onSelectPageChange={(e) => onSelectPage(e)}
                        pageCount={pageCount}
                    />
                </div>
            </div>
        </div>
    )
}
