import API from "../utils/axios";
let common = "/v1/auth";

export const SEND_OTP = (data: any): Promise<any> => {
  return API.post(`${common}/sendOTP`, data);
};

export const LOGIN = (data: any): Promise<any> => {
  return API.post(`${common}/login/user`, data);
};

export const FORGOT_PASSWORD = (data: any): Promise<any> => {
  return API.post(`${common}/forgot/password/user`, data);
};

export const GET_PRESIGNED_URL = (data: any): Promise<any> => {
  return API.post(`/v1/api/s3/presigned/upload/urls?code=STORAGE_UPLOADS`, data);
};