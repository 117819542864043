import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import Logo from './../../assets/images/logo.svg'
import toast from 'react-hot-toast';
import { DynamicForm } from '../../components/form-builder/form';
import { LOGIN } from '../../app/services/common.service';

export const Login = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const navigation = useNavigate();

  const loginFormData = [
    {
      title: "input",
      control: "",
      type: "text",
      label: "Email",
      name: "email",
      value: "",
      required: true,
      regex: /^[a-zA-Z0-9._%+-]+@[a-z]{3,10}.[a-z]{2,5}$/,
      maxLength: "",
      placeholder: "Please enter email",
      error: "Please enter email",
      submitButtonName: "Login",
      hideMandatoryStar: false,
    },
    {
      title: "input",
      control: "",
      type: "password",
      label: "Password",
      name: "password",
      value: "",
      required: true,
      regex: "",
      maxLength: "",
      placeholder: "Please enter password",
      error: "Please enter password",
      hideMandatoryStar: false,
    },
    {
      type: "button",
      class: "col-md-12 d-flex justify-content-center my-2",
      buttons: [
        {
          title: 'Login',
          action: 'add',
          isCheckFormValidation: true,
          class: 'btn btn-black border-0 w-25'
        },
      ]
    }
  ];


  const onFormSubmitEvent = (event: any) => {
    const data = event;
    if (data?.type === "add") {
      const formData = event?.formData;
      formData.type = "emailpass"
      formData.mobile_number = ""
      formData.mobile_country_code = ""
      delete formData['undefined']
      // setLoading(true);
      // if (formData?.username === "superadmin@mail.com" && formData?.password === "123456") {
      //   toast.success("Logged in successfully", { position: 'top-right' })
      //   sessionStorage.setItem('user_role', 'SUPERADMIN');
      //   sessionStorage.setItem('user_name', 'Super Admin');
      //   sessionStorage.setItem("jwt_token", "9y3esz22ntGgt29lgSqzNy0c1w6eA3sc2eCJ7AzCHkmp33At");
      //   navigation("/dashboard/view");
      //   setLoading(false);
      // } else {
      //   toast.error("Invalid Credentials", { position: 'top-right' })
      //   navigation("/");
      //   setLoading(false);
      // }
      setLoading(true);
      LOGIN(formData).then((res: any) => {
        if (res.status === "error") {
          toast.error(res.error, { duration: 2000, position: 'top-right', });
          setLoading(false);
        } else {
          if (res) {
            if (res?.data?.user_role === "SUPERADMIN") {
              sessionStorage.setItem('user_uuid', res?.data?.user_uuid)
              sessionStorage.setItem('user_role', res?.data?.user_role);
              sessionStorage.setItem('user_name', res?.data?.username ? res?.data?.username : "Super Admin");
              sessionStorage.setItem("jwt_token", res?.data?.jwt_token);
              toast.success(res.message, { duration: 2000, position: 'top-right', })
              navigation("/dashboard/view");
              setLoading(false);
            } else {
              toast.error("Invalid Credentials", { duration: 2000, position: 'top-right', })
              navigation("/")
              setLoading(false);
            }
          }
        }
      });
    }
  }

  return (
    <div className="modal" style={{ display: "block" }}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="bg_yellow">
            <div className="rounded-3 d-flex flex-column justify-content-center">
              <div className="">
                <form className="m-4">
                  <div className="Login-form-content mt-5">
                    <h2 className="Login-form-title login_logo text-center fw-bold mt-5 p-5">
                      <img src={Logo} alt="" className="my-4" />
                    </h2>
                    <h4 className="Login-form-title text-center fw-bold my-4">LOGIN</h4>
                    <DynamicForm
                      userFormData={loginFormData}
                      columns={1}
                      isEdit={false}
                      onFormSubmitEvent={(e) => onFormSubmitEvent(e)}
                      customClass={"display-block"}
                      loading={loading}
                      isNotEmptyFormAfterSubmit={true}
                    />
                    <div className="d-flex flex-column justify-content-center ">
                      <div className="my-2 text-primary fw-bold">
                        <NavLink
                          to="/forgot-password"
                          className="text-decoration-none text-primary fw-bold"
                        >
                          Forgot password?
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
