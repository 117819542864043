import { DataTableCol } from "../../../components/data-table/types";

export const ReminderCountsDataGridCols: DataTableCol[] = [
    {
        title: 'Date',
        control: 'date',
        isDateFormat: true,
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Message',
        control: 'message',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Service Type',
        control: 'service_type',
        sortable: true,
        canShowColumn: true
    },
];