import { DataTableCol } from "../../../../components/data-table/types";

export const PaymentReportsDataGridCols: DataTableCol[] = [
    {
        title: 'Transaction ID',
        control: 'transaction_id',
        sortable: true,
        canShowColumn: true,
    },
    {
        title: 'Customer Name',
        control: 'customer_name',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Customer Email',
        control: 'customer_email',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Orders',
        control: 'orders_data',
        isLink:true,
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Date',
        control: 'payment_date',
        isDateFormat:true,
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Price',
        control: 'total_price',
        sortable: true,
        canShowColumn: true,
        isPrice: true,
    },
    {
        title: 'Status',
        control: 'payment_status',
        isUserPaymentStatus:true,
        // isPaymentStatus:true,
        sortable: true,
        canShowColumn: true
    },
];