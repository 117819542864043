import { DataTableCol } from "../../../components/data-table/types";

export const RefundsDataGridCols: DataTableCol[] = [
    {
        title: 'Order ID',
        control: 'order_id',
        sortable: true,
        canShowColumn: true,
    },
    {
        title: 'Customer name',
        control: 'customer_name',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Service Type',
        control: 'service_type',
        sortable: true,
        canShowColumn: true,
    },
    {
        title: 'Order Date',
        control: 'order_date',
        isDateFormat: true,
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Paid Amount',
        control: 'paid_amount',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Refund Reason',
        control: 'refund_reason',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Amount',
        control: 'amount',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Description',
        control: 'description',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Status',
        control: 'status',
        isStatus: true,
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Actions',
        control: 'both',
        sortable: false,
        isEdit: false,
        isDelete: false,
        isVehicleDetails: true,
        isView: true,
        canShowColumn: true
    },
];