import { DataTableCol } from "../../../components/data-table/types";

export const EmailTemplateDataGridCols: DataTableCol[] = [
    {
        title: 'Email Type',
        control: 'code',
        sortable: true,
        canShowColumn: true,
    },
    {
        title: 'Subject',
        control: 'subject',
        sortable: true,
        canShowColumn: true,
    },
    // {
    //     title: 'Body',
    //     control: 'body',
    //     isTextOverflowHide: true,
    //     sortable: true,
    //     canShowColumn: true
    // },
    {
        title: 'Status',
        control: 'status',
        isUserActivityStatus: true,
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Actions',
        control: 'both',
        sortable: false,
        isEdit: true,
        isDelete: true,
        isSend: false,
        isEmailPreview: true,
        isView: false,
        canShowColumn: true
    },
];