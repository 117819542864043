import React, { useEffect, useState } from 'react'
import { CampaignTrackingReportsDataGridCols } from './campaign-tracking-data-grid-cols';
import { DataTable } from '../../../../../components/data-table';
import { Loader } from '../../../../../components/loader';
import { Search } from '../../../../../components/search/search';
import { useNavigate } from 'react-router-dom';
import { CampaignTrackingReportsResponse } from '../../../../../app/model/campaign-tracking.response';
import { GetParamsData } from '../../../../../app/model/common';

export const CampaignTracking = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [tableListData, setTableListData] = useState<CampaignTrackingReportsResponse[]>([]);
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
    const [showCreatePopup, setShowCreatePopup] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [selectedData, setSelectedData] = useState<any>([]);
    const navigation = useNavigate();
    const [pageCount, setPageCount] = useState<GetParamsData>({
        limit: 10,
        page: 0,
    });
    const [formData, setFormData] = useState<any>([
        {
            title: "input",
            type: "text",
            label: "Name",
            name: "name",
            formFloating: false,
            value: '',
            regex: "",
            required: true,
            placeholder: "Please enter name",
            error: "Please enter name",
            hideMandatoryStar: false,
        },
        {
            title: "input",
            type: "text",
            label: "Email",
            name: "email",
            formFloating: false,
            value: '',
            regex: /^[a-zA-Z0-9._%+-]+@[a-z]{3,10}.[a-z]{2,5}$/,
            required: false,
            placeholder: "Please enter email",
            error: "Please enter email",
            hideMandatoryStar: true,
        },
        {
            title: "input",
            type: "text",
            label: "Phone Number",
            formFloating: false,
            name: "mobile_number",
            value: '',
            regex: /^[6-9]\d{9}$/,
            maxLength: "10",
            required: true,
            placeholder: "Please enter phone number",
            error: "Please enter phone number",
            hideMandatoryStar: false,
            customParentClass: "ps-0",
            customClass: "rounded-start-0"
        },
        {
            title: "input",
            type: "password",
            label: "Password",
            name: "password",
            value: "",
            required: true,
            regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/,
            maxLength: "",
            hideMandatoryStar: false,
            placeholder: "Please enter password",
            error:
                "Please enter password",
        },
        {
            title: "textarea",
            type: "text",
            label: "Address",
            name: "address",
            formFloating: false,
            value: '',
            regex: "",
            required: false,
            placeholder: "Please enter address",
            error: "Please enter address",
            hideMandatoryStar: true,
        },
        {
            title: "file",
            type: "file",
            label: "Profile Image",
            name: "profile_img",
            value: "",
            required: false,
            regex: "",
            maxLength: "",
            isMultipleImages: false,
            placeholder: "Upload image",
            error: "Please upload image",
            hideMandatoryStar: true
        },
        {
            type: "button",
            class: "col-md-12 d-flex justify-content-end my-2",
            buttons: [
                {
                    title: 'Create',
                    action: 'add',
                    isCheckFormValidation: true,
                    class: 'btn btn-primary px-4 btn-sm border-0 f16 me-1'
                },
                {
                    title: 'Cancel',
                    action: 'cancel',
                    class: 'btn btn-outline-primary px-4 btn-sm f16'
                }
            ]
        },
    ])

    const staticTableData = {
        total: 10,
        rows: [
            {
                name: "Registration Campaign",
                activity: "Logged in",
                date: "2024-4-01",
                status: "Active"
            },
            {
                name: "Vehicle Renewal Campaign",
                activity: "Checking the services",
                date: "2024-6-01",
                status: "In Active"
            },
        ]
    }

    useEffect(() => {
        getCampaignTrackingReports();
    }, [])

    const getCampaignTrackingReports = () => {
        // setLoading(true);
        // GET_CAMPAIGN_TRACKING_REPORTS(pageCount).then((res: any) => {
        //     if (res?.status === "fail") {
        // toast.error(res.message, { position: 'top-right' })
        //         setLoading(false);
        //     } else {
        //         if (res) {
        //             setTableListData(res?.data);
        //             setLoading(false);
        //         }
        //     }
        // });
    }

    const onActions = (data: any) => {
        setSelectedData(data?.data)
        if (data?.type === "Edit") {
            setIsEdit(true);
            setShowCreatePopup(true);
            const formDataObj = formData;
            formDataObj?.forEach((element: any) => {
                if (element?.type === "button") {
                    for (let i = 0; i < element?.buttons?.length; i++) {
                        const ele = element?.buttons;
                        ele[0].title = "Update";
                        ele[0].action = "edit";
                    }
                }
                element.value = data?.data[element.name]
            });
            setFormData([...formDataObj]);
        } else if (data?.type === "Delete") {
            setShowDeletePopup(true);
        }
    }

    const onPageChange = (data: any) => {
        const pageData = pageCount;
        pageData.limit = data;
        setPageCount(pageData);
    };

    const onSelectPage = (data: any) => {
        const pageData = pageCount;
        pageData.page = data;
        setPageCount(pageData);
    };

    const onSearch = (value: any) => {
        const pageData = pageCount;
        pageData.search = value;
        setPageCount(pageData);
    }
    return (
        <div className='container-fluid content-bg-color'>
            <div className='col-12 w-100 mt-2 pt-2'>
                <Search onSearch={(e) => onSearch(e)} customSearchCss={"col-md-3"} />
            </div>
            <div className='row'>
                <div className='col-md-12'>
                    <Loader LoadingReducer={loading} />
                    <DataTable
                        tableData={staticTableData}
                        TableCols={CampaignTrackingReportsDataGridCols}
                        onActions={(e: any) => onActions(e)}
                        onPageChange={(e: any) => onPageChange(e)}
                        onSelectPageChange={(e) => onSelectPage(e)}
                        pageCount={pageCount}
                    />
                </div>
            </div>
        </div>
    )
}
