import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { DynamicForm } from '../../../components/form-builder/form';
import { DataTable } from '../../../components/data-table';
import { Loader } from '../../../components/loader';
import { Search } from '../../../components/search/search';
import { ReminderTrackerDataGridCols } from './reminder-tracker-data-grid-cols';
import { ReminderCountsDataGridCols } from './reminder-count-data-grid-cols';
import { PreviousOrdersDataGridCols } from './previous-orders-data-grid-cols';
import { ReminderTrackerResponse } from '../../../app/model/reminder-tracker.response';
import { GetParamsData } from '../../../app/model/common';
import { FormField } from '../../../app/model/form-fields';

export const ReminderTracker = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [tableListData, setTableListData] = useState<ReminderTrackerResponse[]>([]);
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
    const [showReminderPopup, setShowReminderPopup] = useState<boolean>(false);
    const [showPreviousOrdersPopup, setShowPreviousOrdersPopup] = useState<boolean>(false);
    const [showMailSendPopup, setShowMailSendPopup] = useState<boolean>(false);
    const [showCreatePopup, setShowCreatePopup] = useState<boolean>(false);
    const [showEmailPreviewPopup, setShowEmailPreviewPopup] = useState<boolean>(false);
    const [selectedEmailType, setSelectedEmailType] = useState<string>("");
    const [filteredEmailData, setFilteredEmailData] = useState<any>();
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [selectedData, setSelectedData] = useState<any>([]);
    const navigation = useNavigate();
    const [pageCount, setPageCount] = useState<GetParamsData>({
        limit: 10,
        page: 0,
    });
    const [formData, setFormData] = useState<FormField[]>([
        {
            title: "dropdown",
            type: "dropdown",
            label: "Orders",
            name: "order_id",
            formFloating: false,
            value: '',
            option: [
                { label: 'Order 1', value: 'Order 1' },
                { label: 'Order 2', value: 'Order 2' },
                { label: 'Order 3', value: 'Order 3' },
            ],
            required: true,
            placeholder: "Please select Order",
            error: "Please select order",
            hideMandatoryStar: false,
        },
        {
            title: "input",
            type: "number",
            label: "Reminding Days",
            name: "reminder_days",
            formFloating: false,
            value: '',
            regex: '',
            required: true,
            placeholder: "Please enter reminder days",
            error: "Please enter reminder days",
            hideMandatoryStar: true,
        },
        {
            type: "button",
            class: "col-md-12 d-flex justify-content-end my-2",
            buttons: [
                {
                    title: 'Create',
                    action: 'add',
                    isCheckFormValidation: true,
                    class: 'btn btn-primary px-4 btn-sm border-0 f16 me-1'
                },
                {
                    title: 'Cancel',
                    action: 'cancel',
                    class: 'btn btn-outline-primary px-4 btn-sm f16'
                }
            ]
        },
    ])
    const [mailFormData, setMailFormData] = useState<FormField[]>([
        {
            title: "dropdown",
            type: "dropdown",
            label: "Email Type",
            name: "email_type",
            formFloating: false,
            value: '',
            option: [
                { label: 'Welcome mail', value: 'Welcome mail' },
                { label: 'Congratulations', value: 'Congratulations' },
                { label: 'Thank You', value: 'Thank You' },
            ],
            required: true,
            placeholder: "Please select email type",
            error: "Please select email type",
            hideMandatoryStar: false,
            isParentDropDown: true,
        },
        // {
        //     title: "textarea",
        //     type: "text",
        //     label: "Additional Message",
        //     name: "additional_message",
        //     formFloating: false,
        //     value: '',
        //     required: true,
        //     placeholder: "Please enter additional message",
        //     error: "Please enter additional message",
        //     hideMandatoryStar: false,
        // },
        {
            type: "button",
            class: "col-md-12 d-flex justify-content-end my-2",
            buttons: [
                {
                    title: 'Send',
                    action: 'add',
                    isCheckFormValidation: true,
                    class: 'btn btn-primary px-4 btn-sm border-0 f16 me-1'
                },
                {
                    title: 'Cancel',
                    action: 'cancel',
                    class: 'btn btn-outline-primary px-4 btn-sm f16'
                }
            ]
        },
    ])

    const staticTableData = {
        total: 10,
        data: [
            {
                id: "51",
                customer_name: "Prasanth",
                phone_number: "9703323998",
                vehicle_details: "BMW",
                next_reminder_date: "2024-12-01",
                service_type: "New Registration",
                reminder_count: "3",
                status: 1,
            },
            {
                id: "52",
                customer_name: "Ravi",
                phone_number: "9703323990",
                vehicle_details: "Ferrari",
                next_reminder_date: "2024-05-06",
                service_type: "Renewal",
                reminder_count: "5",
                status: 0,
            },
        ]
    }

    const reminderCountEmailList = {
        total: 10,
        data: [
            {
                id: "21",
                date: "2024-03-21",
                message: "Registration reminder",
                service_type: "New registration"
            },
            {
                id: "22",
                date: "2024-03-22",
                message: "Registration reminder",
                service_type: "New registration"
            },
            {
                date: "2024-03-23",
                message: "Registration reminder",
                service_type: "New registration"
            },
        ]
    }

    const previousOrdersData = {
        total: 10,
        data: [
            {
                id: "1",
                order_id: "2302323323",
                customer_name: "Prasanth",
                service_type: "New registration",
                price: "1000",
                date: "01-04-2024",
                status: "PENDING",
                delivery_address: "Hyderabad",
                tag: "tag one",
                status_update: "Pending",
                dmv_details: "",
                vehicle_details: ""
            },
        ]
    }

    const staticEmailData = [
        {
            id: "1",
            email_type: "Welcome mail",
            from: "simpltags@info.com",
            subject: "Hi Team, Good morning",
            body: "Discover enriched data capabilities, powerful visualization tools, and personalized experiences tailored to your users' needs. Whether you're optimizing logistics, analyzing customer behavior, or planning infrastructure projects.",
            status: "Active"
        },
        {
            id: "2",
            email_type: "Congratulations",
            from: "simpltags@info.com",
            subject: "Hi Team, Congratulations",
            body: "Congratulations your vehicle registration is successful",
            status: "Active"
        },
        {
            id: "3",
            email_type: "Thank You",
            from: "simpltags@info.com",
            subject: "Hi Team,Thank You",
            body: "Thank you for using simple tags",
            status: "Active"
        },
    ]

    useEffect(() => {
        getReminderTrackers();
    }, [])

    const getReminderTrackers = () => {
        // setLoading(true);
        // GET_REMINDER_TRACKERS(pageCount).then((res: any) => {
        //     if (res?.status === "fail") {
        // toast.error(res.message, { position: 'top-right' })
        //         setLoading(false);
        //     } else {
        //         if (res) {
        //             setTableListData(res?.data);
        //             setLoading(false);
        //         }
        //     }
        // });
    }

    const onShowCreatePopup = () => {
        setIsEdit(false);
        setShowCreatePopup(true);
        const categoryFormData = formData;
        categoryFormData?.forEach((element: any) => {
            if (element?.type === "button") {
                for (let i = 0; i < element?.buttons?.length; i++) {
                    const ele = element?.buttons;
                    ele[0].title = "Create";
                    ele[0].action = "add";
                }
            }
            element.value = "";
        })
        setFormData([...formData]);
    }

    const onActions = (data: any) => {
        setSelectedData(data?.data)
        if (data?.type === "Edit") {
            setIsEdit(true);
            setShowCreatePopup(true);
            const formDataObj = formData;
            formDataObj?.forEach((element: any) => {
                if (element?.type === "button") {
                    for (let i = 0; i < element?.buttons?.length; i++) {
                        const ele = element?.buttons;
                        ele[0].title = "Update";
                        ele[0].action = "edit";
                    }
                }
                element.value = data?.data[element.name]
            });
            setFormData([...formDataObj]);
        } else if (data?.type === "Delete") {
            setShowDeletePopup(true);
        } else if (data?.type === "link") {
            setShowReminderPopup(true)
        } else if (data?.type === "Previous Orders") {
            setShowPreviousOrdersPopup(true)
        } else if (data?.type === "Send") {
            setShowMailSendPopup(true);
            // setShowCreatePopup(true);
            const categoryFormData = mailFormData;
            categoryFormData?.forEach((element: any) => {
                if (element?.type === "button") {
                    for (let i = 0; i < element?.buttons?.length; i++) {
                        const ele = element?.buttons;
                        ele[0].title = "Create";
                        ele[0].action = "add" || "emailPreview";
                    }
                }
                element.value = "";
            })
            setMailFormData([...categoryFormData])
        }
    }

    const onPageChange = (data: any) => {
        const pageData = pageCount;
        pageData.limit = data;
        setPageCount(pageData);
    };

    const onSelectPage = (data: any) => {
        const pageData = pageCount;
        pageData.page = data;
        setPageCount(pageData);
    };

    const onSearch = (value: any) => {
        const pageData = pageCount;
        pageData.search = value;
        setPageCount(pageData);
    }

    const onDeleteUserConfirmation = () => {
        // DELETE_REMINDER_TRACKER(selectedData?.uuid).then((res: any) => {
        //     if (res.status === "error") {
        //         toast.error(res.error, { position: "top-right" });
        //         setLoading(false);
        //     } else {
        //         if (res) {
        //             setShowDeletePopup(false);
        //             toast.success(res.message, { position: 'top-right', })
        //             setLoading(false);
        //             getRoles();
        //         }
        //     }
        // });
        // setShowDeletePopup(false);
        toast.success("Deleted Successfully", { position: 'top-right' })
    }

    const onFormSubmitEvent = (event: any) => {
        const data = event;
        if (data?.type === "add") {
            const formData = data?.formData
            delete formData['undefined']
            // setLoading(true);
            // CREATE_REMINDER_TRACKER(formData).then((res: any) => {
            //     if (res.status === "fail") {
            //         toast.error(res.error, { position: "top-right" });
            //         setLoading(false);
            //     } else {
            //         if (res) {
            //             setShowCreatePopup(false);
            //             toast.success(res.message, { position: 'top-right', })
            //             setLoading(false);
            //             getUsers();
            //         }
            //     }
            // });
        } else if (data?.type === "edit") {
            const formData = data?.formData
            delete formData['undefined']
            // UPDATE_REMINDER_TRACKER(selectedData?.uuid, formData).then((res: any) => {
            //     if (res.status === "fail") {
            //         toast.error(res.error, { position: "top-right" });
            //         setLoading(false);
            //     } else {
            //         if (res) {
            //             setShowCreatePopup(false);
            //             toast.success(res.message, { position: 'top-right', })
            //             setLoading(false);
            //             getCountriesData();
            //         }
            //     }
            // });
        } else if (data?.type === "cancel") {
            setShowCreatePopup(false)
        }
    }

    const onChangeEmailType = (event: any) => {
        if (event?.name === "email_type") {
            setSelectedEmailType(event.value);
            setSelectedEmailType(event.value)
            const updatedFormData = mailFormData;
            const buttonObj: any = {
                type: "button",
                class: "col-md-12 d-flex justify-content-start my-2",
                buttons: [
                    {
                        title: 'Email Preview',
                        action: 'emailPreview',
                        isCheckFormValidation: false,
                        class: 'btn btn-primary px-4 btn-sm border-0 f16 me-1'
                    },
                ]
            }

            const buttonExists = updatedFormData.some((field) =>
                field.type === 'button' &&
                field.buttons?.some((button: any) => button.title === 'Email Preview')
            );

            // Only add the button if it doesn't already exist
            if (!buttonExists) {
                const index = updatedFormData.findIndex((field) => field.type === 'button');
                if (index !== -1) {
                    updatedFormData.splice(1, 0, buttonObj);
                } else {
                    updatedFormData.push(buttonObj);
                }
            }
            setMailFormData([...updatedFormData])

        }
    }

    const onShowEmailPreview = () => {
        const emailData = staticEmailData
        if (selectedEmailType === "") {
            setShowEmailPreviewPopup(false);
        } else {
            setShowEmailPreviewPopup(true);
            setShowMailSendPopup(false)
            const filteredEmailData = emailData?.filter((el?: any) => el?.email_type === selectedEmailType);
            setFilteredEmailData(filteredEmailData[0])
        }
    }

    const onMailFormSubmitEvent = (event: any) => {
        const data = event
        if (data?.type === "emailPreview") {
            onShowEmailPreview();
        }
    }

    return (
        <div className='container-fluid content-bg-color'>
            <div className='row border-bottom-primary'>
                <div className='col-md-9 text-start d-flex align-items-center px-0'>
                    <h4>Reminder Tracker</h4>
                </div>
                {/* <div className='col-md-3 text-end my-2 px-0'>
                    <button className='btn btn-primary border-0' onClick={onShowCreatePopup} >Create Reminder</button>
                </div> */}
            </div>
            <div className='col-12 w-100 mt-4 pt-1'>
                <Search onSearch={(e) => onSearch(e)} customSearchCss={"col-md-3 px-0"} />
            </div>
            <div className='row'>
                <div className='col-md-12 px-0'>
                    <Loader LoadingReducer={loading} />
                    <DataTable
                        tableData={staticTableData}
                        TableCols={ReminderTrackerDataGridCols}
                        onActions={(e: any) => onActions(e)}
                        onPageChange={(e: any) => onPageChange(e)}
                        onSelectPageChange={(e) => onSelectPage(e)}
                        pageCount={pageCount}
                    />
                </div>
                {showCreatePopup && (
                    <div className={`modal modal-custom fade ${showCreatePopup ? "show" : "false"}  ${showCreatePopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-lg modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header border-bottom-primary mx-4 px-0">
                                    <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">{isEdit ? "Edit" : "Create"} Reminder</strong></h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowCreatePopup(false)}></button>
                                </div>
                                <div className="modal-body px-4 text-start">
                                    <div className='row'>
                                        <DynamicForm
                                            userFormData={formData}
                                            columns={2}
                                            isEdit={isEdit}
                                            onFormSubmitEvent={(e) => onFormSubmitEvent(e)}
                                            customClass={"display-block"}
                                            isNotEmptyFormAfterSubmit={true} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {showDeletePopup && (
                    <>
                        <div className={`modal modal-custom fade ${showDeletePopup ? "show" : "false"}  ${showDeletePopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
                            <div className="modal-dialog modal-md modal-dialog-centered">
                                <div className="modal-content text-start">
                                    <div className="modal-header border-bottom-primary mx-4 px-0">
                                        <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">Delete</strong></h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowDeletePopup(false)}></button>
                                    </div>
                                    <div className="modal-body pb-5">
                                        <div className="row px-2">
                                            <div className="col-md-12">
                                                <div className="mb-3">
                                                    <p className="f16 p-2">Are you sure you want to delete <span className='fw-bold '>{selectedData?.customer_name || ""}</span> ?</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='px-2 text-end'>
                                            <button type="button" className="btn btn-primary px-4 btn-sm border-0 f16 me-3" onClick={onDeleteUserConfirmation}>Yes</button>
                                            <button type="button" className="btn btn-outline-primary px-4 btn-sm f16 text-primary-medium border-primary-medium" onClick={() => setShowDeletePopup(false)}>No</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {showReminderPopup && (
                    <>
                        <div className={`modal modal-custom fade ${showReminderPopup ? "show" : "false"}  ${showReminderPopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
                            <div className="modal-dialog modal-xl modal-dialog-centered">
                                <div className="modal-content text-start">
                                    <div className="modal-header border-bottom-primary mx-4 px-0">
                                        <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">Reminder Details</strong></h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowReminderPopup(false)}></button>
                                    </div>
                                    <div className="modal-body pb-5">
                                        <div className="row px-2">
                                            <div className='col-md-12'>
                                                <Loader LoadingReducer={loading} />
                                                <DataTable
                                                    tableData={reminderCountEmailList}
                                                    TableCols={ReminderCountsDataGridCols}
                                                    onActions={(e: any) => onActions(e)}
                                                    onPageChange={(e: any) => onPageChange(e)}
                                                    onSelectPageChange={(e) => onSelectPage(e)}
                                                    pageCount={pageCount}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {showPreviousOrdersPopup && (
                    <>
                        <div className={`modal modal-custom fade ${showPreviousOrdersPopup ? "show" : "false"}  ${showPreviousOrdersPopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
                            <div className="modal-dialog modal-xl modal-dialog-centered">
                                <div className="modal-content text-start">
                                    <div className="modal-header border-bottom-primary mx-4 px-0">
                                        <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">Previous Orders</strong></h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowPreviousOrdersPopup(false)}></button>
                                    </div>
                                    <div className="modal-body pb-5">
                                        <div className="row px-2">
                                            <div className='col-md-12'>
                                                <Loader LoadingReducer={loading} />
                                                <DataTable
                                                    tableData={previousOrdersData}
                                                    TableCols={PreviousOrdersDataGridCols}
                                                    onActions={(e: any) => onActions(e)}
                                                    onPageChange={(e: any) => onPageChange(e)}
                                                    onSelectPageChange={(e) => onSelectPage(e)}
                                                    pageCount={pageCount}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {showMailSendPopup && (
                    <div className={`modal modal-custom fade ${showMailSendPopup ? "show" : "false"}  ${showMailSendPopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-md modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header border-bottom-primary mx-4 px-0">
                                    <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">Send follow up message</strong></h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowMailSendPopup(false)}></button>
                                </div>
                                <div className="modal-body px-4 text-start">
                                    <div className='row'>
                                        <DynamicForm
                                            userFormData={mailFormData}
                                            columns={1}
                                            isEdit={isEdit}
                                            onFormSubmitEvent={(e) => onMailFormSubmitEvent(e)}
                                            customClass={"display-block"}
                                            isNotEmptyFormAfterSubmit={true}
                                            onParentDropdownEvent={(e) => onChangeEmailType(e)}
                                        />
                                        {/* {selectedEmailType !== "" && (
                                            <div>
                                                <button className="btn btn-primary px-4 btn-sm f16 me-3 border-0" onClick={onShowEmailPreview}>Email Preview</button>
                                            </div>
                                        )} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {showEmailPreviewPopup && (
                    <>
                        <div className={`modal modal-custom fade ${showEmailPreviewPopup ? "show" : "false"}  ${showEmailPreviewPopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
                            <div className="modal-dialog modal-lg modal-dialog-centered">
                                <div className="modal-content text-start">
                                    <div className="modal-header border-bottom-primary mx-4 px-0">
                                        <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">Email Preview</strong></h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setShowEmailPreviewPopup(false); setShowMailSendPopup(true) }}></button>
                                    </div>
                                    <div className="modal-body pb-5">
                                        <div className="row px-2">
                                            <div className="col-md-12">
                                                <div className='card p-3 px-4'>
                                                    <div className=''><span className='fw-bold '>From : </span><span className=''>{filteredEmailData?.from}</span></div>
                                                    <div className=''><span className='fw-bold'>Subject : </span>{filteredEmailData?.subject}</div>
                                                    <div className='py-2 custom-box-shadow rounded-3 p-3 my-3'>{filteredEmailData?.body}</div>
                                                    <div className='py-2 custom-box-shadow rounded-3 p-3 my-1 email-footer-bg'>
                                                        <div>Thanks,</div>
                                                        <div>The Team</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}
