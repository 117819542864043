import API from "../utils/axios";
let common = "/v1/api";

export const GET_OFFERS = (data: any): Promise<any> => {
    return API.get(`${common}/offers`, { params: data });
};

export const CREATE_OFFER = (data: any): Promise<any> => {
    return API.post(`${common}/offers`, data);
};

export const UPDATE_OFFER = (uuid: string, data: any): Promise<any> => {
    return API.put(`${common}/offers/${uuid}`, data);
};

export const DELETE_OFFER = (uuid: string): Promise<any> => {
    return API.delete(`${common}/offers/${uuid}`);
};