import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { DynamicForm } from '../../../components/form-builder/form';
import { DiscountsDataGridCols } from './discounts-data-grid-cols';
import { DataTable } from '../../../components/data-table';
import { Loader } from '../../../components/loader';
import { Search } from '../../../components/search/search';
import { DiscountResponse } from '../../../app/model/discounts.response';
import { GetParamsData } from '../../../app/model/common';
import { FormField } from '../../../app/model/form-fields';
import { CREATE_DISCOUNT, DELETE_DISCOUNT, GET_DISCOUNTS, UPDATE_DISCOUNT } from '../../../app/services/discounts.service';
import { GET_SERVICE_TYPE } from '../../../app/services/service-type.service';
import moment from 'moment';

export const Discounts = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [tableListData, setTableListData] = useState<DiscountResponse[]>([]);
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
    const [showCreatePopup, setShowCreatePopup] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [selectedData, setSelectedData] = useState<any>([]);
    const navigation = useNavigate();
    const [pageCount, setPageCount] = useState<GetParamsData>({
        limit: 10,
        page: 0,
        type: "DISCOUNTS"
    });
    const [formData, setFormData] = useState<FormField[]>([
        {
            title: "dropdown",
            type: "dropdown",
            label: "Service Name",
            name: "service_type_uuid",
            formFloating: false,
            value: '',
            option: [],
            required: true,
            placeholder: "Please select service name",
            error: "Please select service name",
            hideMandatoryStar: false,
        },
        {
            title: "input",
            type: "text",
            label: "Discount Type",
            name: "name",
            formFloating: false,
            value: '',
            required: true,
            placeholder: "Please enter discount type",
            error: "Please enter discount type",
            hideMandatoryStar: false,
        },
        {
            title: "input",
            type: "date",
            label: "Start Date",
            name: "start_dt",
            formFloating: false,
            value: '',
            min: new Date().toISOString().split('T')[0],
            required: true,
            placeholder: "Please select start date",
            error: "Please select start date",
            hideMandatoryStar: false,
        },
        {
            title: "input",
            type: "date",
            label: "End Date",
            name: "end_dt",
            formFloating: false,
            value: '',
            required: true,
            placeholder: "Please select end date",
            error: "Please select end date",
            hideMandatoryStar: false,
        },
        {
            title: "input",
            type: "number",
            label: "Discount Price",
            name: "discount_price",
            formFloating: false,
            value: '',
            regex: '',
            required: true,
            placeholder: "Please enter discount price",
            error: "Please enter discount price",
            hideMandatoryStar: true,
            isParentDropDown: true
        },
        {
            title: "dropdown",
            type: "dropdown",
            label: "Status",
            name: "status",
            formFloating: false,
            value: '',
            option: [
                { label: 'ACTIVE', value: 1 },
                { label: 'IN ACTIVE', value: 0 }
            ],
            required: false,
            placeholder: "Please select status",
            error: "Please select status",
            hideMandatoryStar: false,
        },
        {
            title: "textarea",
            type: "text",
            label: "Description",
            name: "notes",
            formFloating: false,
            value: '',
            regex: "",
            placeholder: "Please enter description",
            error: "Please enter description",
            hideMandatoryStar: false,
        },
        {
            type: "button",
            class: "col-md-12 d-flex justify-content-end my-2",
            buttons: [
                {
                    title: 'Create',
                    action: 'add',
                    isCheckFormValidation: true,
                    class: 'btn btn-primary px-4 btn-sm border-0 f16 me-1'
                },
                {
                    title: 'Cancel',
                    action: 'cancel',
                    class: 'btn btn-outline-primary px-4 btn-sm f16'
                }
            ]
        },
    ])

    const [searchFormData, setSearchFormData] = useState<any>([
        {
            title: "input",
            type: "date",
            label: "Start Date",
            name: "start_dt",
            formFloating: false,
            value: '',
            regex: "",
            required: false,
            placeholder: "Please select start date",
            error: "Please select start date",
            hideMandatoryStar: false,
        },
        {
            title: "input",
            type: "date",
            label: "End Date",
            name: "end_dt",
            formFloating: false,
            value: '',
            regex: "",
            required: false,
            placeholder: "Please select end date",
            error: "Please select end date",
            hideMandatoryStar: false,
        },
        {
            title: "input",
            type: "text",
            label: "Search",
            name: "search",
            formFloating: false,
            value: '',
            regex: '',
            required: false,
            placeholder: "Please enter search",
            error: "Please enter search",
            hideMandatoryStar: true,
        },
        {
            title: "dropdown",
            type: "dropdown",
            label: "Status",
            name: "status",
            formFloating: false,
            value: '',
            option: [
                { label: 'Active', value: 1 },
                { label: 'In Active', value: 0 },
            ],
            required: false,
            placeholder: "Please select status",
            error: "Please select status",
            hideMandatoryStar: false,
        },
        {
            type: "button",
            class: "col-md-3 d-flex justify-content-start my-2",
            buttons: [
                {
                    title: 'Search',
                    action: 'add',
                    isCheckFormValidation: false,
                    class: 'btn btn-primary px-4 btn-sm border-0 f16 me-1'
                },
                {
                    title: 'Cancel',
                    action: 'cancel',
                    class: 'btn btn-outline-primary px-4 btn-sm f16'
                }
            ]
        },
    ])

    const staticTableData = {
        total: 10,
        rows: [
            {
                id: "1",
                service_name: "New registration",
                discount_type: "Registration Discount",
                discount_price: "100",
                final_price: "900",
                status: "Active"
            },
            {
                id: "2",
                service_name: "Renewal",
                discount_type: "Renewal Discount",
                discount_price: "50",
                final_price: "450",
                status: "In Active"
            },
        ]
    }

    useEffect(() => {
        getDiscounts(pageCount);
        getServices();
    }, [])

    const getDiscounts = (pageData: any) => {
        setLoading(true);
        GET_DISCOUNTS(pageData).then((res: any) => {
            if (res?.status === "fail") {
                toast.error(res.message, { position: 'top-right' })
                setLoading(false);
            } else {
                if (res) {
                    res?.data?.data?.forEach((el: any) => {
                        // const data = JSON.parse(el?.algorithm)
                        el.discount_price = el?.algorithm?.discount_price
                    })
                    setTableListData(res?.data);
                    setLoading(false);
                }
            }
        });
    }

    const getServices = () => {
        setLoading(true);
        GET_SERVICE_TYPE({ limit: 1000 }).then((res: any) => {
            if (res?.status === "fail") {
                toast.error(res.message, { position: 'top-right' })
                setLoading(false);
            } else {
                if (res) {
                    const response = res?.data?.data;
                    response?.forEach((el: any) => {
                        el.label = el?.name
                        el.value = el?.uuid
                    })
                    const data = formData;
                    data[0].option = response
                    setFormData([...data]);
                    setLoading(false);
                }
            }
        });
    }

    const onShowCreatePopup = () => {
        setIsEdit(false);
        setShowCreatePopup(true);
        const categoryFormData = formData;
        categoryFormData?.forEach((element: any) => {
            if (element?.type === "button") {
                for (let i = 0; i < element?.buttons?.length; i++) {
                    const ele = element?.buttons;
                    ele[0].title = "Create";
                    ele[0].action = "add";
                }
            }
            element.value = "";
        })
        setFormData([...formData])
    }

    const onActions = (data: any) => {
        setSelectedData(data?.data)
        if (data?.type === "Edit") {
            setIsEdit(true);
            setShowCreatePopup(true);
            const formDataObj = formData;
            formDataObj?.forEach((element: any) => {
                if (element?.type === "button") {
                    for (let i = 0; i < element?.buttons?.length; i++) {
                        const ele = element?.buttons;
                        ele[0].title = "Update";
                        ele[0].action = "edit";
                    }
                }
                element.value = data?.data[element.name]
            });
            setFormData([...formDataObj]);
        } else if (data?.type === "Delete") {
            setShowDeletePopup(true);
        }
    }

    const onPageChange = (data: any) => {
        const pageData = pageCount;
        pageData.limit = data;
        setPageCount(pageData);
        getDiscounts(pageData);
    };

    const onSelectPage = (data: any) => {
        const pageData = pageCount;
        pageData.page = data;
        setPageCount(pageData);
        getDiscounts(pageData);
    };

    const onSearch = (value: any) => {
        const pageData = pageCount;
        pageData.search = value;
        setPageCount(pageData);
        getDiscounts(pageData);
    }

    const onSearchFormSubmit = (event: any) => {
        const data = event;
        if (data?.type === "add") {
            const pageData = pageCount;
            const formDataObj = { ...pageData, ...data?.formData };
            delete formDataObj['undefined'];
            for (var propName in formDataObj) {
                if (!formDataObj[propName]) {
                    delete formDataObj[propName];
                }
            }
            setPageCount({ ...formDataObj });
            getDiscounts(formDataObj);
        } else if (data?.type === "cancel") {
            const formData = searchFormData;
            formData?.forEach((element: any) => {
                element.value = ""
            })
            const pageData = {
                limit: 10,
                page: 0,
                type: "DISCOUNTS"
            }
            setSearchFormData([...formData]);
            setPageCount(pageData);
            getDiscounts(pageData);
        }
    }

    const onDeleteUserConfirmation = () => {
        setLoading(true);
        DELETE_DISCOUNT(selectedData?.uuid).then((res: any) => {
            if (res.status === "error") {
                toast.error(res.error, { position: "top-right" });
                setLoading(false);
            } else {
                if (res) {
                    setShowDeletePopup(false);
                    toast.success(res.message, { position: 'top-right', })
                    setLoading(false);
                    getDiscounts(pageCount);
                }
            }
        });
    }

    const onFormSubmitEvent = (event: any) => {
        const data = event;
        if (data?.type === "add") {
            const formData = data?.formData;
            formData.type = "DISCOUNTS"
            formData.status = Number(data?.formData?.status)
            formData.code = data?.formData?.name?.toUpperCase();
            formData.algorithm = { discount_price: data?.formData?.discount_price }
            delete formData['undefined']
            setLoading(true);
            CREATE_DISCOUNT(formData).then((res: any) => {
                if (res.status === "fail") {
                    toast.error(res.error, { position: "top-right" });
                    setLoading(false);
                } else {
                    if (res) {
                        setShowCreatePopup(false);
                        toast.success(res.message, { position: 'top-right', })
                        setLoading(false);
                        getDiscounts(pageCount);
                    }
                }
            });
        } else if (data?.type === "edit") {
            const formData = data?.formData
            formData.type = "DISCOUNTS"
            formData.status = Number(data?.formData?.status)
            formData.code = data?.formData?.name?.toUpperCase();
            formData.start_dt = moment(data?.formData?.start_dt).format("YYYY-MM-DD")
            formData.end_dt = moment(data?.formData?.end_dt).format("YYYY-MM-DD")
            formData.algorithm = { discount_price: data?.formData?.discount_price }
            delete formData['undefined']
            setLoading(true);
            UPDATE_DISCOUNT(selectedData?.uuid, formData).then((res: any) => {
                if (res.status === "fail") {
                    toast.error(res.error, { position: "top-right" });
                    setLoading(false);
                } else {
                    if (res) {
                        setShowCreatePopup(false);
                        toast.success(res.message, { position: 'top-right', })
                        setLoading(false);
                        getDiscounts(pageCount);
                    }
                }
            });
        } else if (data?.type === "cancel") {
            setShowCreatePopup(false)
        }
    }

    return (
        <div className='container-fluid content-bg-color'>
            <div className='row border-bottom-primary'>
                <div className='col-md-9 text-start d-flex align-items-center px-0'>
                    <h4>Discounts</h4>
                </div>
                <div className='col-md-3 text-end my-2 px-0'>
                    <button className='btn btn-primary border-0' onClick={onShowCreatePopup}>Create Discount</button>
                </div>
            </div>
            {/* <div className='col-12 w-100 mt-4 pt-1'>
                <Search onSearch={(e) => onSearch(e)} customSearchCss={"col-md-3 px-0"} />
            </div> */}
            <div className='col-12 w-100 my-2 mb-3 pb-2'>
                <DynamicForm
                    userFormData={searchFormData}
                    columns={4}
                    isEdit={false}
                    onFormSubmitEvent={(e) => onSearchFormSubmit(e)}
                    customClass={"display-block"}
                    isNotEmptyFormAfterSubmit={true}
                />
            </div>
            <div className='row'>
                <div className='col-md-12 px-0'>
                    <Loader LoadingReducer={loading} />
                    <DataTable
                        tableData={tableListData}
                        TableCols={DiscountsDataGridCols}
                        onActions={(e: any) => onActions(e)}
                        onPageChange={(e: any) => onPageChange(e)}
                        onSelectPageChange={(e) => onSelectPage(e)}
                        pageCount={pageCount}
                    />
                </div>
                {showCreatePopup && (
                    <div className={`modal modal-custom fade ${showCreatePopup ? "show" : "false"}  ${showCreatePopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
                        <Loader LoadingReducer={loading} />
                        <div className="modal-dialog modal-lg modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header border-bottom-primary mx-4 px-0">
                                    <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">{isEdit ? "Edit" : "Create"} Discount</strong></h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowCreatePopup(false)}></button>
                                </div>
                                <div className="modal-body px-4 text-start">
                                    <div className='row'>
                                        <DynamicForm
                                            userFormData={formData}
                                            columns={2}
                                            isEdit={isEdit}
                                            onFormSubmitEvent={(e) => onFormSubmitEvent(e)}
                                            customClass={"display-block"}
                                            isNotEmptyFormAfterSubmit={true} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {showDeletePopup && (
                    <>
                        <div className={`modal modal-custom fade ${showDeletePopup ? "show" : "false"}  ${showDeletePopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
                            <Loader LoadingReducer={loading} />
                            <div className="modal-dialog modal-md modal-dialog-centered">
                                <div className="modal-content text-start">
                                    <div className="modal-header border-bottom-primary mx-4 px-0">
                                        <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">Delete</strong></h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowDeletePopup(false)}></button>
                                    </div>
                                    <div className="modal-body pb-5">
                                        <div className="row px-2">
                                            <div className="col-md-12">
                                                <div className="mb-3">
                                                    <p className="f16 p-2">Are you sure you want to delete <span className='fw-bold '>{selectedData?.discount_type || ""}</span> ?</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='px-2 text-end'>
                                            <button type="button" className="btn btn-primary px-4 btn-sm f16 border-0 me-3" onClick={onDeleteUserConfirmation}>Yes</button>
                                            <button type="button" className="btn btn-outline-primary px-4 btn-sm f16 text-primary-medium border-primary-medium" onClick={() => setShowDeletePopup(false)}>No</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}
