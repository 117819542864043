import React, { useState } from 'react'
import { FormField } from '../../../app/model/form-fields'
import { DynamicForm } from '../../form-builder/form';

interface Props {
    userShippingData?: any;
}
export const ShippingPartner = (props: Props) => {
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [formData, setFormData] = useState<FormField[]>([
        {
            title: "dropdown",
            type: "dropdown",
            label: "Shipping Partner",
            name: "name",
            formFloating: false,
            option: [
                {
                    label: 'DHL',
                    value: 'DHL'
                },
                {
                    label: 'FedX',
                    value: 'FedX'
                }
            ],
            value: '',
            regex: '',
            required: true,
            placeholder: "Please enter shipping partner",
            error: "Please enter shipping partner",
            hideMandatoryStar: true,
            isParentDropDown: true
        },
        // {
        //     title: "textarea",
        //     type: "text",
        //     label: "Shipping Address",
        //     name: "shipping_address",
        //     formFloating: false,
        //     value: '',
        //     required: true,
        //     placeholder: "Please enter shipping address",
        //     error: "Please enter shipping address",
        //     hideMandatoryStar: false,
        // },

        // {
        //     type: "button",
        //     class: "col-md-12 d-flex justify-content-end my-2",
        //     buttons: [
        //         {
        //             title: 'Create',
        //             action: 'add',
        //             isCheckFormValidation: true,
        //             class: 'btn btn-primary px-4 btn-sm border-0 f16 me-1'
        //         },
        //         {
        //             title: 'Cancel',
        //             action: 'cancel',
        //             class: 'btn btn-outline-primary px-4 btn-sm f16'
        //         }
        //     ]
        // },
    ])

    const onFormSubmitEvent = (event: any) => {
        const data = event;
    }

    return (
        <div className='row'>
            <div className='text-start shadow primary-bg p-3 my-2 rounded-3'>
                <label htmlFor="">Address</label>
                <div className='mb-2'>
                    <p className='m-0 ps-3'>{props?.userShippingData?.street_address}</p>
                    <p className='m-0 ps-3'>{props?.userShippingData?.city}, {props?.userShippingData?.state}, {props?.userShippingData?.zip_code}</p>
                </div>
            </div>
            <DynamicForm
                userFormData={formData}
                columns={1}
                isEdit={isEdit}
                onFormSubmitEvent={(e) => onFormSubmitEvent(e)}
                customClass={"display-block"}
                isNotEmptyFormAfterSubmit={true} />
        </div>
    )
}
