import API from "../utils/axios";
let common = "/v1/api";

export const GET_CONFIGURATIONS = (data: any): Promise<any> => {
    return API.get(`${common}/system/params`, { params: data });
};

export const CREATE_CONFIGURATION = (data: any): Promise<any> => {
    return API.post(`${common}/system/params`, data);
};

export const UPDATE_CONFIGURATION = (uuid: string, data: any): Promise<any> => {
    return API.put(`${common}/system/params/${uuid}`, data);
};

export const DELETE_CONFIGURATION = (uuid: string): Promise<any> => {
    return API.delete(`${common}/system/params/${uuid}`);
};