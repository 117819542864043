import { DataTableCol } from "../../../../../components/data-table/types";

export const VehiclesDataGridCols: DataTableCol[] = [
    {
        title: 'VIN Number',
        control: 'vehicle_vin_number',
        sortable: true,
        isLink: true,
        canShowColumn: true,
    },
    {
        title: 'License Plate',
        control: 'vehicle_license_plate',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Vehicle Type',
        control: 'vehicle_type',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Vehicle Make',
        control: 'vehicle_make',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Service Type',
        control: 'service_type',
        sortable: true,
        canShowColumn: true
    },


];