import API from "../utils/axios";
let common = "/v1/api";

export const GET_USER_ORDER = (data: any): Promise<any> => {
  return API.get(`${common}/user/orders`, { params: data });
};

export const CREATE_USER_ORDER = (data: any): Promise<any> => {
  return API.delete(`${common}/user/orders`, data);
};

export const UPDATE_USER_ORDER = (uuid: string, data: any): Promise<any> => {
  return API.put(`${common}/user/orders/${uuid}`, data);
};

export const DELETE_USER_ORDER = (uuid: string): Promise<any> => {
  return API.delete(`${common}/user-orders/${uuid}`);
};
