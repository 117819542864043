import API from "../utils/axios";
let common = "/v1/api";

export const GET_VEHICLES = (data: any): Promise<any> => {
    return API.get(`${common}/user/vehicles`, { params: data });
};

export const CREATE_VEHICLE = (data: any): Promise<any> => {
    return API.post(`${common}/user/vehicles`, data);
};

export const UPDATE_VEHICLE = (uuid: string, data: any): Promise<any> => {
    return API.put(`${common}/user/vehicles/${uuid}`, data);
};

export const DELETE_VEHICLE = (uuid: string): Promise<any> => {
    return API.delete(`${common}/user/vehicles/${uuid}`);
};