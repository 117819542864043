import React, { useEffect, useState } from 'react'
import { Search } from '../../../components/search/search'
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../../components/loader';
import { DataTable } from '../../../components/data-table';
import { AbandonedCartDataGridCols } from './abandoned-cart-data-grid-cols';
import { UserFlowDataGridCols } from './user-flow-data-grid-cols';
import { AbandonedCartResponse } from '../../../app/model/abandoned-cart.response';
import { GetParamsData } from '../../../app/model/common';
import { GET_USER_ORDER } from '../../../app/services/user-orders.service';
import { ORDERS_BASE_URL } from '../../../config/constant';
import { GET_ABANDONED_CART_DETAILS } from '../../../app/services/abandoned-cart.service';
import { DynamicForm } from '../../../components/form-builder/form';
import { FormField } from '../../../app/model/form-fields';
import { OrderHistoryDataGridCols } from './order-history-data-grid-cols';

export const AbandonedCart = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [tableListData, setTableListData] = useState<AbandonedCartResponse[]>([]);
    const [showCreatePopup, setShowCreatePopup] = useState<boolean>(false);
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
    const [showUserFlowPopup, setShowUserFlowPopup] = useState<boolean>(false);
    const [showMailSendPopup, setShowMailSendPopup] = useState<boolean>(false);
    const [showHistoryPopup, setShowHistoryPopup] = useState<boolean>(false);
    const [showEmailPreviewPopup, setShowEmailPreviewPopup] = useState<boolean>(false);
    const [selectedEmailType, setSelectedEmailType] = useState<string>("");
    const [filteredEmailData, setFilteredEmailData] = useState<any>();
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [selectedData, setSelectedData] = useState<any>([]);
    const navigation = useNavigate();
    const [pageCount, setPageCount] = useState<GetParamsData>({
        limit: 10,
        page: 0,
    });
    const [formData, setFormData] = useState<any>([
        {
            title: "dropdown",
            type: "dropdown",
            label: "Orders",
            name: "order_id",
            formFloating: false,
            value: '',
            option: [
                { label: 'Order 1', value: 'Order 1' },
                { label: 'Order 2', value: 'Order 2' },
                { label: 'Order 3', value: 'Order 3' },
            ],
            required: true,
            placeholder: "Please select Order",
            error: "Please select order",
            hideMandatoryStar: false,
        },
        {
            title: "input",
            type: "number",
            label: "Reminding Days",
            name: "reminder_days",
            formFloating: false,
            value: '',
            regex: '',
            required: true,
            placeholder: "Please enter reminder days",
            error: "Please enter reminder days",
            hideMandatoryStar: true,
        },
        {
            type: "button",
            class: "col-md-12 d-flex justify-content-end my-2",
            buttons: [
                {
                    title: 'Create',
                    action: 'add',
                    isCheckFormValidation: true,
                    class: 'btn btn-primary px-4 btn-sm border-0 f16 me-1'
                },
                {
                    title: 'Cancel',
                    action: 'cancel',
                    class: 'btn btn-outline-primary px-4 btn-sm f16'
                }
            ]
        },
    ])

    const [mailFormData, setMailFormData] = useState<FormField[]>([
        {
            title: "dropdown",
            type: "dropdown",
            label: "Email Type",
            name: "email_type",
            formFloating: false,
            value: '',
            option: [
                { label: 'Welcome mail', value: 'Welcome mail' },
                { label: 'Congratulations', value: 'Congratulations' },
                { label: 'Thank You', value: 'Thank You' },
            ],
            required: true,
            placeholder: "Please select email type",
            error: "Please select email type",
            hideMandatoryStar: false,
            isParentDropDown: true
        }, {
            title: "textarea",
            type: "text",
            label: "Additional message",
            name: "additional_message",
            formFloating: false,
            value: '',
            required: true,
            placeholder: "Please enter additional message",
            error: "Please enter additional message",
            hideMandatoryStar: false,
        },
        {
            type: "button",
            class: "col-md-12 d-flex justify-content-end my-2",
            buttons: [
                {
                    title: 'Send',
                    action: 'add',
                    isCheckFormValidation: true,
                    class: 'btn btn-primary px-4 btn-sm border-0 f16 me-1'
                },
                {
                    title: 'Cancel',
                    action: 'cancel',
                    class: 'btn btn-outline-primary px-4 btn-sm f16'
                }
            ]
        },
    ])

    const staticTableData = {
        total: 10,
        rows: [
            {
                id: "31",
                order_id: "2302323323",
                customer_name: "Prasanth",
                service_type: "New registration",
                date: "2024-12-04",
                status: "Active",
                flow_status: "Registration",
                tag: "tag one",
                address: "Hyderabad",
            },
            {
                id: "32",
                order_id: "2302323444",
                customer_name: "Subba reddy",
                service_type: "Replace tag",
                date: "2024-01-04",
                status: "In Active",
                flow_status: "cart",
                tag: "tag two",
                address: "Hyderabad",
            },
        ]
    }

    const ordersStaticTableData = {
        total: 10,
        data: [
            {
                id: "1",
                date: "2024-12-04",
                email_or_phone_number: "sunil@mail/com",
                email: "sunil@mail/com",
                link: "http://example.com",
            },
            {
                id: "2",
                date: "2024-01-04",
                email_or_phone_number: "9703323999",
                email: "raghu@mail/com",
                link: "http://example.com",
            },
        ]
    }

    const userFlowStaticData = {
        total: 10,
        data: [
            {
                id: "101",
                flow_state: "Registration",
                name: "Car",
                service: "Active",
                date: "2024-06-25",
                payment_status: "Completed",
                order_status: "Completed"
            },
        ]
    }

    const staticEmailData = [
        {
            id: "1",
            email_type: "Welcome mail",
            from: "simpltags@info.com",
            subject: "Hi Team, Good morning",
            body: "Discover enriched data capabilities, powerful visualization tools, and personalized experiences tailored to your users' needs. Whether you're optimizing logistics, analyzing customer behavior, or planning infrastructure projects.",
            status: "Active"
        },
        {
            id: "2",
            email_type: "Congratulations",
            from: "simpltags@info.com",
            subject: "Hi Team, Congratulations",
            body: "Congratulations your vehicle registration is successful",
            status: "Active"
        },
        {
            id: "3",
            email_type: "Thank You",
            from: "simpltags@info.com",
            subject: "Hi Team,Thank You",
            body: "Thank you for using simple tags",
            status: "Active"
        },
    ]

    useEffect(() => {
        getAbandonedCart(pageCount);
    }, [])

    const getAbandonedCart = (pageData: any) => {
        setLoading(true);
        GET_ABANDONED_CART_DETAILS(pageData).then((res: any) => {
            if (res?.status === "fail") {
                setLoading(false);
            } else {
                if (res) {
                    res?.data?.data?.forEach((element: any) => {
                        element.service_type = element?.service_type?.name;
                        element.customer_name = element?.user_info?.name;
                        element.customer_email = element?.user_info?.email;
                        element.customer_mobile_number = element?.user_info?.mobile_number;
                        element.address = `${element?.user_shipping_address?.street_address},${element?.user_shipping_address?.unit}`;
                        // ${element?.shipping_info?.city}, ${element?.shipping_info?.state} ${element?.shipping_info?.zip_code}`;
                    });
                    setTableListData(res?.data);
                    setLoading(false);
                }
            }
        });
    }

    const onShowCreatePopup = () => {
        setIsEdit(false);
        setShowCreatePopup(true);
        const categoryFormData = formData;
        categoryFormData?.forEach((element: any) => {
            if (element?.type === "button") {
                for (let i = 0; i < element?.buttons?.length; i++) {
                    const ele = element?.buttons;
                    ele[0].title = "Create";
                    ele[0].action = "add";
                }
            }
            element.value = "";
        })
        setFormData([...formData])
    }

    const onActions = (data: any) => {
        setSelectedData(data?.data)
        if (data?.type === "Edit") {
            setIsEdit(true);
            setShowCreatePopup(true);
            const formDataObj = formData;
            formDataObj?.forEach((element: any) => {
                if (element?.type === "button") {
                    for (let i = 0; i < element?.buttons?.length; i++) {
                        const ele = element?.buttons;
                        ele[0].title = "Update";
                        ele[0].action = "edit";
                    }
                }
                element.value = data?.data[element.name]
            });
            setFormData([...formDataObj]);
        } else if (data?.type === "Delete") {
            setShowDeletePopup(true);
        } else if (data?.type === "link") {
            navigation(`/dashboard/abandoned-cart/${data?.data?.uuid}`)
            // setShowUserFlowPopup(true);
        } else if (data?.type === "Send") {
            setShowMailSendPopup(true);
            // setShowCreatePopup(true);
            const categoryFormData = mailFormData;
            categoryFormData?.forEach((element: any) => {
                if (element?.type === "button") {
                    for (let i = 0; i < element?.buttons?.length; i++) {
                        const ele = element?.buttons;
                        ele[0].title = "Create";
                        ele[0].action = "add" || "emailPreview";
                    }
                }
                element.value = "";
            })
            setMailFormData([...categoryFormData])
        } else if (data?.type === "history") {
            setShowHistoryPopup(true);
        }
    }

    const onPageChange = (data: any) => {
        const pageData = pageCount;
        pageData.limit = data;
        setPageCount(pageData);
        getAbandonedCart(pageData);
    };

    const onSelectPage = (data: any) => {
        const pageData = pageCount;
        pageData.page = data;
        setPageCount(pageData);
        getAbandonedCart(pageData);
    };

    const onHistoryActions = (data: any) => {
        if (data?.type === "create") {
        } else if (data?.type === "Delete") {
            // setShowDeletePopup(true);
        } else if (data?.type === "link") {
            // navigation(`/dashboard/abandoned-cart/${data?.data?.uuid}`)
            // setShowUserFlowPopup(true);
        } else if (data?.type === "Send") {
            // setShowMailSendPopup(true);
        } else if (data?.type === "history") {
            // setShowHistoryPopup(true);
        }
    }

    const onHistoryPageChange = (data: any) => {
        const pageData = pageCount;
        pageData.limit = data;
        setPageCount(pageData);
    };

    const onHistorySelectPage = (data: any) => {
        const pageData = pageCount;
        pageData.page = data;
        setPageCount(pageData);
    };

    const onSearch = (value: any) => {
        const pageData = pageCount;
        pageData.search = value;
        setPageCount(pageData);
        getAbandonedCart(pageData);
    }

    const onMailFormSubmitEvent = (event: any) => {
        const data = event;
        if (data?.type === "emailPreview") {
            onShowEmailPreview()
        }
    }

    const onChangeEmailTypeDropdown = (event: any) => {
        if (event.name === "email_type") {
            console.log(event.value)
            setSelectedEmailType(event.value)
            const updatedFormData = mailFormData;
            const buttonObj: any = {
                type: "button",
                class: "col-md-12 d-flex justify-content-start my-2",
                buttons: [
                    {
                        title: 'Email Preview',
                        action: 'emailPreview',
                        isCheckFormValidation: false,
                        class: 'btn btn-primary px-4 btn-sm border-0 f16 me-1'
                    },
                ]
            }

            const buttonExists = updatedFormData.some((field) =>
                field.type === 'button' &&
                field.buttons?.some((button: any) => button.title === 'Email Preview')
            );

            // Only add the button if it doesn't already exist
            if (!buttonExists) {
                const index = updatedFormData.findIndex((field) => field.type === 'button');
                if (index !== -1) {
                    updatedFormData.splice(1, 0, buttonObj);
                } else {
                    updatedFormData.push(buttonObj);
                }
            }
            setMailFormData([...updatedFormData])
            // const index = updatedFormData.findIndex((field) => field.type === 'button');
            // updatedFormData.splice(1, 0, buttonObj);
            // const updatedData: any = data?.splice(0, buttonObj)

        }
    }

    const onShowEmailPreview = () => {
        const emailData = staticEmailData
        if (selectedEmailType === "") {
            setShowEmailPreviewPopup(false);
        } else {
            setShowEmailPreviewPopup(true);
            setShowMailSendPopup(false)
            const filteredEmailData = emailData?.filter((el?: any) => el?.email_type === selectedEmailType);
            setFilteredEmailData(filteredEmailData[0])
        }
    }

    return (
        <div className='container-fluid content-bg-color'>
            <div className='row border-bottom-primary'>
                <div className='col-md-9 text-start d-flex align-items-center px-0'>
                    <h4>Abandoned Cart</h4>
                </div>
            </div>
            <div className='col-12 w-100 mt-4 pt-1'>
                <Search onSearch={(e) => onSearch(e)} customSearchCss={"col-md-3 px-0"} />
            </div>
            <div className='row'>
                <div className='col-md-12 px-0'>
                    <Loader LoadingReducer={loading} />
                    <DataTable
                        tableData={tableListData}
                        TableCols={AbandonedCartDataGridCols}
                        abandonedCartTooltipMessage={true}
                        onActions={(e: any) => onActions(e)}
                        onPageChange={(e: any) => onPageChange(e)}
                        onSelectPageChange={(e) => onSelectPage(e)}
                        pageCount={pageCount}
                    />
                </div>
                {showUserFlowPopup && (
                    <>
                        <div className={`modal modal-custom fade ${showUserFlowPopup ? "show" : "false"}  ${showUserFlowPopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
                            <div className="modal-dialog modal-xl modal-dialog-centered">
                                <div className="modal-content text-start">
                                    <div className="modal-header border-bottom-primary mx-4 px-0">
                                        <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">User Flow State</strong></h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowUserFlowPopup(false)}></button>
                                    </div>
                                    <div className="modal-body pb-5">
                                        <div className="row px-2">
                                            <div className='col-md-12'>
                                                <Loader LoadingReducer={loading} />
                                                <DataTable
                                                    tableData={userFlowStaticData}
                                                    TableCols={UserFlowDataGridCols}
                                                    onActions={(e: any) => onActions(e)}
                                                    onPageChange={(e: any) => onPageChange(e)}
                                                    onSelectPageChange={(e) => onSelectPage(e)}
                                                    pageCount={pageCount}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {showMailSendPopup && (
                    <div className={`modal modal-custom fade ${showMailSendPopup ? "show" : "false"}  ${showMailSendPopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-md modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header border-bottom-primary mx-4 px-0">
                                    <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">Send follow up message</strong></h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowMailSendPopup(false)}></button>
                                </div>
                                <div className="modal-body px-4 text-start">
                                    <div className='row'>
                                        <DynamicForm
                                            userFormData={mailFormData}
                                            columns={1}
                                            isEdit={isEdit}
                                            onFormSubmitEvent={(e) => onMailFormSubmitEvent(e)}
                                            customClass={"display-block"}
                                            isNotEmptyFormAfterSubmit={true}
                                            onParentDropdownEvent={(e) => onChangeEmailTypeDropdown(e)}
                                        />
                                    </div>
                                    {/* {selectedEmailType !== "" && (
                                        <div>
                                            <button className="btn btn-primary px-4 btn-sm f16 me-3 border-0" onClick={onShowEmailPreview}>Email Preview</button>
                                        </div>
                                    )} */}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {showHistoryPopup && (
                    <div className={`modal modal-custom fade ${showHistoryPopup ? "show" : "false"}  ${showHistoryPopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-xl modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header border-bottom-primary mx-4 px-0">
                                    <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">Order History</strong></h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowHistoryPopup(false)}></button>
                                </div>
                                <div className="modal-body px-4 text-start">
                                    <div className='col-md-12 px-0'>
                                        <Loader LoadingReducer={loading} />
                                        <DataTable
                                            tableData={ordersStaticTableData}
                                            TableCols={OrderHistoryDataGridCols}
                                            abandonedCartTooltipMessage={true}
                                            onActions={(e: any) => onHistoryActions(e)}
                                            onPageChange={(e: any) => onHistoryPageChange(e)}
                                            onSelectPageChange={(e) => onHistorySelectPage(e)}
                                            pageCount={pageCount}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {showEmailPreviewPopup && (
                    <>
                        <div className={`modal modal-custom fade ${showEmailPreviewPopup ? "show" : "false"}  ${showEmailPreviewPopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
                            <div className="modal-dialog modal-lg modal-dialog-centered">
                                <div className="modal-content text-start">
                                    <div className="modal-header border-bottom-primary mx-4 px-0">
                                        <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">Email Preview</strong></h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setShowEmailPreviewPopup(false); setShowMailSendPopup(true) }}></button>
                                    </div>
                                    <div className="modal-body pb-5">
                                        <div className="row px-2">
                                            <div className="col-md-12">
                                                <div className='card p-3 px-4'>
                                                    <div className=''><span className='fw-bold '>From : </span><span className=''>{filteredEmailData?.from}</span></div>
                                                    <div className=''><span className='fw-bold'>Subject : </span>{filteredEmailData?.subject}</div>
                                                    <div className='py-2 custom-box-shadow rounded-3 p-3 my-3'>{filteredEmailData?.body}</div>
                                                    <div className='py-2 custom-box-shadow rounded-3 p-3 my-1 email-footer-bg'>
                                                        <div>Thanks,</div>
                                                        <div>The Team</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}
