import API from "../utils/axios";
let common = "/v1/api";

export const GET_SERVICE_TYPE = (data: any): Promise<any> => {
  return API.get(`${common}/service/types`, { params: data });
};

export const CREATE_SERVICE_TYPE = (data: any): Promise<any> => {
  return API.post(`${common}/service/types`, data);
};

export const UPDATE_SERVICE_TYPE = (service_uuid: any, data: any): Promise<any> => {
  return API.put(`${common}/service/types/${service_uuid}`, data);
};

export const DELETE_SERVICE_TYPE = (service_uuid: any): Promise<any> => {
  return API.delete(`${common}/service/types/${service_uuid}`,);
};
