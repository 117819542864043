import { DataTableCol } from "../../../components/data-table/types";

export const ReminderTrackerDataGridCols: DataTableCol[] = [
    {
        title: 'Customer Name',
        control: 'customer_name',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Customer Mobile Number',
        control: 'phone_number',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Vehicle Details',
        control: 'vehicle_details',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Service Type',
        control: 'service_type',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Next Reminder Date',
        control: 'next_reminder_date',
        isDateFormat: true,
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Count Of Reminder',
        control: 'reminder_count',
        isLink: true,
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Status',
        control: 'status',
        isUserActivityStatus: true,
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Actions',
        control: 'both',
        sortable: false,
        isEdit: false,
        isDelete: false,
        isPreviousOrdersList: true,
        isSend: true,
        isView: false,
        canShowColumn: true
    },
];