import React, { useEffect, useRef, useState } from 'react'
import { DataTable } from '../../../components/data-table';
import { Loader } from '../../../components/loader';
import { Search } from '../../../components/search/search';
import { EmailTemplateDataGridCols } from './email-template-data-grid-cols';
import { useNavigate, useParams } from 'react-router-dom';
import { DynamicForm } from '../../../components/form-builder/form';
import { EmailTemplatesResponse } from '../../../app/model/email-templates.response';
import { GetParamsData } from '../../../app/model/common';
import { FormField } from '../../../app/model/form-fields';
import toast from 'react-hot-toast';
// import CKEditor from '@ckeditor/ckeditor5-react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { CREATE_EMAIL_TEMPLATE, DELETE_EMAIL_TEMPLATE, GET_EMAIL_TEMPLATES, GET_EMAIL_TEMPLATES_TYPES, UPDATE_EMAIL_TEMPLATE } from '../../../app/services/email-templates.service';

export const EmailTemplatesForm = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const navigation = useNavigate();
    const params = useParams();
    const [pageCount, setPageCount] = useState<GetParamsData>({
        limit: 10,
        page: 0,
        uuid: params.uuid
    });
    const [formData, setFormData] = useState<FormField[]>([
        {
            title: "input",
            type: "text",
            label: "Name",
            name: "name",
            formFloating: false,
            value: '',
            regex: '',
            required: true,
            placeholder: "Please enter name",
            error: "Please enter name",
            hideMandatoryStar: true,
            column: "6"
        },
        {
            title: "dropdown",
            type: "dropdown",
            label: "Email Type",
            name: "code",
            formFloating: false,
            value: '',
            regex: '',
            required: true,
            placeholder: "Please enter email type",
            error: "Please enter email type",
            hideMandatoryStar: true,
            column: "6"
        },
        {
            title: "input",
            type: "text",
            label: "Subject",
            name: "subject",
            formFloating: false,
            value: '',
            regex: '',
            required: true,
            placeholder: "Please enter subject",
            error: "Please enter subject",
            hideMandatoryStar: true,
            column: "12"
        },
        {
            title: "ckEditor",
            type: "ckEditor",
            label: "Message",
            name: "body",
            formFloating: false,
            value: '',
            regex: '',
            required: true,
            placeholder: "Please upload image",
            error: "Please upload image",
            hideMandatoryStar: true,
            column: "12"
        },
        {
            title: "dropdown",
            type: "dropdown",
            label: "Status",
            name: "status",
            formFloating: false,
            value: '',
            option: [
                { label: 'ACTIVE', value: 1 },
                { label: 'IN ACTIVE', value: 0 }
            ],
            required: false,
            placeholder: "Please select status",
            error: "Please select status",
            hideMandatoryStar: false,
        },

        {
            type: "button",
            class: "col-md-12 d-flex justify-content-end my-2",
            buttons: [
                {
                    title: 'Create',
                    action: 'add',
                    isCheckFormValidation: true,
                    class: 'btn btn-primary px-4 border-0 border-0 btn-sm f16 me-1'
                },
                {
                    title: 'Cancel',
                    action: 'cancel',
                    class: 'btn btn-outline-primary px-4 btn-sm f16'
                }
            ]
        },
    ])

    useEffect(() => {
        if (params?.uuid) {
            getEmailTemplates();
        }
        getEmailTemplateTypes();
    }, [])

    const getEmailTemplates = () => {
        setLoading(true);
        GET_EMAIL_TEMPLATES(pageCount).then((res: any) => {
            if (res?.status === "fail") {
                toast.error(res.message, { position: 'top-right' })
                setLoading(false);
            } else {
                if (res) {
                    setLoading(false);
                    setIsEdit(true);
                    const formDataObj = formData;
                    formDataObj?.forEach((element: any) => {
                        if (element?.type === "button") {
                            for (let i = 0; i < element?.buttons?.length; i++) {
                                const ele = element?.buttons;
                                ele[0].title = "Update";
                                ele[0].action = "edit";
                            }
                        }
                        element.value = res?.data.data[0][element.name]
                    });
                    console.log('formDataObj', formDataObj);

                    setFormData([...formDataObj]);
                }
            }
        });
    }

    const getEmailTemplateTypes = () => {
        setLoading(true);
        GET_EMAIL_TEMPLATES_TYPES(pageCount).then((res: any) => {
            if (res?.status === "fail") {
                toast.error(res.message, { position: 'top-right' })
                setLoading(false);
            } else {
                if (res) {
                    const data: any = []
                    res?.data.forEach((element: any) => {
                        data.push({
                            label: element,
                            value: element
                        })
                    });
                    const categoryFormData = formData;
                    categoryFormData[1].option = data;
                    setFormData([...formData])
                    setLoading(false);
                }
            }
        });
    }


    const onFormSubmitEvent = (event: any) => {
        const data = event;
        if (data?.type === "add") {
            const formData = data?.formData;
            const tempElement = document.createElement("div");
            tempElement.innerHTML = formData?.body;
            const plainText = tempElement.textContent || tempElement.innerText || "";
            formData.plain_body = plainText;
            formData.format = "text";
            formData.type = "promotional";
            console.log(plainText);
            delete formData['undefined']
            // formData.finalPrice = data?.formData?.price - data?.formData?.discount_amount
            console.log('formData', formData);

            setLoading(true);
            CREATE_EMAIL_TEMPLATE(formData).then((res: any) => {
                if (res.status === "fail") {
                    toast.error(res.error, { position: "top-right" });
                    setLoading(false);
                } else {
                    if (res) {
                        navigation('../');
                        toast.success(res.message, { position: 'top-right', })
                        setLoading(false);
                    }
                }
            });
        } else if (data?.type === "edit") {
            const formData = data?.formData;
            const tempElement = document.createElement("div");
            tempElement.innerHTML = formData?.body;
            const plainText = tempElement.textContent || tempElement.innerText || "";
            formData.plain_body = plainText;
            formData.format = "text";
            formData.type = "promotional";
            console.log(plainText);
            delete formData['undefined']
            UPDATE_EMAIL_TEMPLATE(params?.uuid ? params?.uuid : '', formData).then((res: any) => {
                if (res.status === "fail") {
                    toast.error(res.error, { position: "top-right" });
                    setLoading(false);
                } else {
                    if (res) {
                        toast.success(res.message, { position: 'top-right', })
                        setLoading(false);
                        navigation('../');
                    }
                }
            });
        } else if (data?.type === "cancel") {
            navigation('../');
        }
    }

    return (
        <div className='container-fluid content-bg-color'>
            <div className='row border-bottom-primary'>
                <div className='col-md-9 text-start d-flex align-items-center px-0'>
                    <h4>Email Templates</h4>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-12 px-0'>
                    <Loader LoadingReducer={loading} />
                    <DynamicForm
                        userFormData={formData}
                        columns={2}
                        isEdit={isEdit}
                        onFormSubmitEvent={(e) => onFormSubmitEvent(e)}
                        customClass={"display-block"}
                        isNotEmptyFormAfterSubmit={true} />
                </div>

            </div>
        </div>
    )
}
