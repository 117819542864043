import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { VehiclesDataGridCols } from './vehicles-data-grid-cols';
import { Search } from '../../../../../components/search/search';
import { Loader } from '../../../../../components/loader';
import { DataTable } from '../../../../../components/data-table';
import { VehicleResponse } from '../../../../../app/model/vehicles-response';
import { GetParamsData } from '../../../../../app/model/common';
import toast from 'react-hot-toast';
import { GET_VEHICLES } from '../../../../../app/services/vehicles.service';

export const Vehicles = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [tableListData, setTableListData] = useState<VehicleResponse[]>([]);
    const navigation = useNavigate();
    const params = useParams();
    const [pageCount, setPageCount] = useState<GetParamsData>({
        limit: 10,
        page: 0,
        user_uuid: params?.user_uuid,
        expand:"service_type"
    });

    const staticTableData = {
        total: 10,
        rows: [
            {
                id: "14",
                vehicle_make: "BMW",
                vehicle_type: "Car",
                license_plate: "7ABC123",
                vin_number: "1HGCM82633A123456",
                service_type: "New Registration"
            },
            {
                id: "15",
                vehicle_make: "Ferrari",
                vehicle_type: "Car",
                license_plate: "8XYZ789",
                vin_number: "2FTRX18W1XCA12345",
                service_type: "Replace title"
            },
        ]
    }

    useEffect(() => {
        getUserVehicles(pageCount);
    }, [])

    const getUserVehicles = (pageData: any) => {
        setLoading(true);
        GET_VEHICLES(pageData).then((res: any) => {
            if (res?.status === "fail") {
                toast.error(res.message, { position: 'top-right' })
                setLoading(false);
            } else {
                if (res) {
                    res?.data?.data?.forEach((el:any) =>{
                        el.service_type = el?.service_type?.name
                    })
                    setTableListData(res?.data);
                    setLoading(false);
                }
            }
        });
    }

    const onPageChange = (data: any) => {
        const pageData = pageCount;
        pageData.limit = data;
        setPageCount(pageData);
        getUserVehicles(pageData);
    };

    const onSelectPage = (data: any) => {
        const pageData = pageCount;
        pageData.page = data;
        setPageCount(pageData);
        getUserVehicles(pageData);
    };

    const onSearch = (value: any) => {
        const pageData = pageCount;
        pageData.search = value;
        setPageCount(pageData);
        getUserVehicles(pageData);
    }

    const onActions = (data: any) => {
        if (data?.type === "link") {
            navigation(`/dashboard/users/${params?.user_uuid}/vehicles/${data?.data?.uuid}/orders`)
        }
    }

    return (
        <div className='row px-3'>
            <div className='col-12 w-100 mt-4 pt-1'>
                <Search onSearch={(e) => onSearch(e)} customSearchCss={"col-md-3"} />
            </div>
            <div className='col-md-12'>
                <Loader LoadingReducer={loading} />
                <DataTable
                    tableData={tableListData}
                    TableCols={VehiclesDataGridCols}
                    onActions={(e: any) => onActions(e)}
                    onPageChange={(e: any) => onPageChange(e)}
                    onSelectPageChange={(e) => onSelectPage(e)}
                    pageCount={pageCount}
                />
            </div>
        </div>
    )
}
