import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { Search } from '../../../components/search/search';
import { Loader } from '../../../components/loader';
import { DataTable } from '../../../components/data-table';
import { RolesDataGridCols } from './roles-data-grid-cols';
import { DynamicForm } from '../../../components/form-builder/form';
import { RolePrivilegesDataGridCols } from './privileges-data-grild-cols';
import { GetParamsData } from '../../../app/model/common';
import { FormField } from '../../../app/model/form-fields';
import { RolesResponse } from '../../../app/model/roles.response';
import { CREATE_ROLE, DELETE_ROLE, GET_ACCESS_OBJECT_BY_ROLE, GET_ROLES, UPDATE_ROLE } from '../../../app/services/roles.service';
import { GET_ACCESS_OBJECTS } from '../../../app/services/privileges.service';
import { ACCESS_PERMISSION } from '../../../app/services/access-permissions.service';

export const Roles = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [tableListData, setTableListData] = useState<RolesResponse[]>([]);
    const [privilegesTableData, setPrivilegesTableData] = useState<RolesResponse[]>([]);

    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
    const [showCreatePopup, setShowCreatePopup] = useState<boolean>(false);
    const [showPrivilegesPopup, setShowPrivilegesPopup] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [selectedData, setSelectedData] = useState<any>([]);
    const [checkedAccessObject, setCheckedAccessObject] = useState<any>([]);

    const navigation = useNavigate();
    const [pageCount, setPageCount] = useState<GetParamsData>({
        limit: 10,
        page: 0,
    });
    const [formData, setFormData] = useState<FormField[]>([
        {
            title: "input",
            type: "text",
            label: "Name",
            name: "name",
            formFloating: false,
            value: '',
            regex: "",
            required: true,
            placeholder: "Please enter name",
            error: "Please enter name",
            hideMandatoryStar: false,
        },
        {
            title: "input",
            type: "text",
            label: "Code",
            name: "code",
            formFloating: false,
            value: '',
            regex: '',
            required: true,
            placeholder: "Please enter code",
            error: "Please enter code",
            hideMandatoryStar: true,
        },
        {
            title: "dropdown",
            type: "dropdown",
            label: "Status",
            name: "status",
            formFloating: false,
            value: '',
            option: [
                { label: 'ACTIVE', value: 1 },
                { label: 'IN ACTIVE', value: 0 }
            ],
            required: false,
            placeholder: "Please select status",
            error: "Please select status",
            hideMandatoryStar: false,
        },
        {
            title: "textarea",
            type: "text",
            label: "Description",
            name: "notes",
            formFloating: false,
            value: '',
            regex: "",
            required: false,
            placeholder: "Please enter description",
            error: "Please enter description",
            hideMandatoryStar: true,
        },
        {
            type: "button",
            class: "col-md-12 d-flex justify-content-end my-2",
            buttons: [
                {
                    title: 'Create',
                    action: 'add',
                    isCheckFormValidation: true,
                    class: 'btn btn-primary px-4 btn-sm f16 border-0 me-1'
                },
                {
                    title: 'Cancel',
                    action: 'cancel',
                    class: 'btn btn-outline-primary px-4 btn-sm f16'
                }
            ]
        },
    ])

    const staticTableData = {
        total: 10,
        rows: [
            {
                name: "Super Admin",
                code: "SUPER_ADMIN",
                description: "super admin",
                status: 1,
            },
            {
                name: "Admin",
                code: "ADMIN",
                description: "admin",
                status: 0,
            },
        ]
    }

    useEffect(() => {
        getRoles(pageCount);
        getPrivileges(pageCount);
    }, [])

    const getRoles = (pageData: any) => {
        setLoading(true);
        GET_ROLES(pageData).then((res: any) => {
            if (res?.status === "fail") {
                toast.error(res.message, { position: 'top-right' })
                setLoading(false);
            } else {
                if (res) {
                    setTableListData(res?.data);
                    setLoading(false);
                }
            }
        });
    }

    const getPrivileges = (pageData: any) => {
        setLoading(true);
        GET_ACCESS_OBJECTS(pageData).then((res: any) => {
            if (res?.status === "fail") {
                toast.error(res.message, { position: 'top-right' })
                setLoading(false);
            } else {
                if (res) {
                    // setPrivilegesTableData(res?.data);
                    setLoading(false);
                }
            }
        });
    }

    const getAccessObjectByRoleId = (data: any) => {
        const role_uuid = data?.data?.uuid;
        const preparedData = {
            uuid: role_uuid,
            expand: "objects"
        }
        setLoading(true);
        GET_ACCESS_OBJECT_BY_ROLE({ ...pageCount, ...preparedData }).then((res: any) => {
            if (res?.status === "fail") {
                toast.error(res.message, { position: 'top-right' })
                setLoading(false);
            } else {
                if (res) {
                    const data: any = { total: res?.data?.data[0]?._objects?.length, data: res?.data?.data[0]?._objects }
                    setPrivilegesTableData(data);
                    setLoading(false);
                }
            }
        });
    }

    // const staticPrivilegesTableData = {
    //     total: 10,
    //     rows: [
    //         {
    //             id: "1",
    //             name: "User Edit",
    //             code: "USER_EDIT",
    //             p_read: "true",
    //             p_write: "true",
    //         },
    //         {
    //             id: "2",
    //             name: "User Delete",
    //             code: "USER_DELETE",
    //             p_read: "true",
    //             p_write: "true",
    //         },
    //     ]
    // }

    const onShowCreatePopup = () => {
        setIsEdit(false);
        setShowCreatePopup(true);
        const categoryFormData = formData;
        categoryFormData?.forEach((element: any) => {
            if (element?.type === "button") {
                for (let i = 0; i < element?.buttons?.length; i++) {
                    const ele = element?.buttons;
                    ele[0].title = "Create";
                    ele[0].action = "add";
                }
            }
            element.value = "";
        })
        setFormData([...formData])
    }

    const onActions = (data: any) => {
        setSelectedData(data?.data)
        if (data?.type === "Edit") {
            setIsEdit(true);
            setShowCreatePopup(true);
            const formDataObj = formData;
            formDataObj?.forEach((element: any) => {
                if (element?.type === "button") {
                    for (let i = 0; i < element?.buttons?.length; i++) {
                        const ele = element?.buttons;
                        ele[0].title = "Update";
                        ele[0].action = "edit";
                    }
                }
                element.value = data?.data[element.name]
            });
            setFormData([...formDataObj]);
        } else if (data?.type === "Delete") {
            setShowDeletePopup(true);
        } else if (data?.type === "Privileges") {
            getAccessObjectByRoleId(data)
            setShowPrivilegesPopup(true);
        }
    }

    const onPageChange = (data: any) => {
        const pageData = pageCount;
        pageData.limit = data;
        setPageCount(pageData);
        getRoles(pageData)
    };

    const onSelectPage = (data: any) => {
        const pageData = pageCount;
        pageData.page = data;
        setPageCount(pageData);
        getRoles(pageData);
    };

    const onSearch = (value: any) => {
        const pageData = pageCount;
        pageData.search = value;
        setPageCount(pageData);
        getRoles(pageData)
    }

    const onDeleteUserConfirmation = () => {
        setLoading(true);
        DELETE_ROLE(selectedData?.uuid).then((res: any) => {
            if (res.status === "error") {
                toast.error(res.error, { position: "top-right" });
                setLoading(false);
            } else {
                if (res) {
                    setShowDeletePopup(false);
                    toast.success(res.message, { position: 'top-right', })
                    setLoading(false);
                    getRoles(pageCount);
                }
            }
        });
        setShowDeletePopup(false);
    }

    const onFormSubmitEvent = (event: any) => {
        const data = event;
        if (data?.type === "add") {
            const formData = data?.formData
            formData.status = Number(data?.formData.status)
            delete formData['undefined']
            setLoading(true);
            CREATE_ROLE(formData).then((res: any) => {
                if (res.status === "fail") {
                    toast.error(res.error, { position: "top-right" });
                    setLoading(false);
                } else {
                    if (res) {
                        setShowCreatePopup(false);
                        toast.success(res.message, { position: 'top-right', })
                        setLoading(false);
                        getRoles(pageCount);
                    }
                }
            });
        } else if (data?.type === "edit") {
            const formData = data?.formData;
            formData.status = Number(data?.formData.status)
            formData.session_timeout = 60
            delete formData['undefined']
            setLoading(true);
            UPDATE_ROLE(selectedData?.uuid, formData).then((res: any) => {
                if (res.status === "fail") {
                    toast.error(res.error, { position: "top-right" });
                    setLoading(false);
                } else {
                    if (res) {
                        setShowCreatePopup(false);
                        toast.success(res.message, { position: 'top-right', })
                        setLoading(false);
                        getRoles(pageCount);
                    }
                }
            });
        } else if (data?.type === "cancel") {
            setShowCreatePopup(false)
        }
    }

    const onPrivilegeActions = (data: any) => {
        // checkedAccessObject,
        console.log("items on privilege actions :", data);
        setCheckedAccessObject([...data?.items])
    }

    const onSavePrivileges = () => {
        let selectedDataObjects: any = [];
        checkedAccessObject.forEach((element: any) => {
            selectedDataObjects.push({
                object_uuid: element?.uuid,
                p_read: element?.p_read ? 1 : 0,
                p_write: element?.p_write ? 1 : 0
            })
        });
        // selectedDataObjects = selectedDataObjects.filter((item: any) => item?.p_read || item?.p_write);
        selectedDataObjects = selectedDataObjects.filter((item: any) => {
            return item.p_read !== 0 || item.p_write !== 0 || (item.p_read === 0 && item.p_write === 0);
        });
        const objectData = {
            "role_uuid": selectedData?.uuid,
            "objects": selectedDataObjects
        }
        console.log("objects payload data : ", objectData)
        ACCESS_PERMISSION(objectData).then((res: any) => {
            if (res.status === "fail") {
                toast.error(res.error, { position: "top-right" });
                setLoading(false);
            } else {
                if (res) {
                    setShowPrivilegesPopup(false);
                    toast.success(res.message, { position: 'top-right', })
                    setLoading(false);
                    getRoles(pageCount);
                }
            }
        });
    }

    return (
        <div className='container-fluid content-bg-color'>
            <div className='row border-bottom-primary'>
                <div className='col-md-9 text-start d-flex align-items-center px-0'>
                    <h4>Roles</h4>
                </div>
                <div className='col-md-3 text-end my-2 px-0'>
                    <button className='btn btn-primary border-0' onClick={onShowCreatePopup}>Create Role</button>
                </div>
            </div>
            <div className='col-12 w-100 mt-4 pt-1'>
                <Search onSearch={(e) => onSearch(e)} customSearchCss={"col-md-3 px-0"} />
            </div>
            <div className='row'>
                <div className='col-md-12 px-0'>
                    <Loader LoadingReducer={loading} />
                    <DataTable
                        tableData={tableListData}
                        TableCols={RolesDataGridCols}
                        onActions={(e) => onActions(e)}
                        onPageChange={(e: any) => onPageChange(e)}
                        onSelectPageChange={(e) => onSelectPage(e)}
                        pageCount={pageCount}
                    />
                </div>
                {showCreatePopup && (
                    <div className={`modal modal-custom fade ${showCreatePopup ? "show" : "false"}  ${showCreatePopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
                        <Loader LoadingReducer={loading} />
                        <div className="modal-dialog modal-lg modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header border-bottom-primary mx-4 px-0">
                                    <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">{isEdit ? "Edit" : "Create"} Role</strong></h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowCreatePopup(false)}></button>
                                </div>
                                <div className="modal-body px-4 text-start">
                                    <div className='row'>
                                        <DynamicForm
                                            userFormData={formData}
                                            columns={2}
                                            isEdit={isEdit}
                                            onFormSubmitEvent={(e) => onFormSubmitEvent(e)}
                                            customClass={"display-block"}
                                            isNotEmptyFormAfterSubmit={true} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {showDeletePopup && (
                    <>
                        <div className={`modal modal-custom fade ${showDeletePopup ? "show" : "false"}  ${showDeletePopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
                            <Loader LoadingReducer={loading} />
                            <div className="modal-dialog modal-md modal-dialog-centered">
                                <div className="modal-content text-start">
                                    <div className="modal-header border-bottom-primary mx-4 px-0">
                                        <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">Delete</strong></h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowDeletePopup(false)}></button>
                                    </div>
                                    <div className="modal-body pb-5">
                                        <div className="row px-2">
                                            <div className="col-md-12">
                                                <div className="mb-3">
                                                    <p className="f16 p-2">Are you sure you want to delete <span className='fw-bold '>{selectedData?.name || ""}</span> ?</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='px-2 text-end'>
                                            <button type="button" className="btn btn-primary px-4 btn-sm f16 border-0 me-3" onClick={onDeleteUserConfirmation}>Yes</button>
                                            <button type="button" className="btn btn-outline-primary px-4 btn-sm f16 text-primary-medium border-primary-medium" onClick={() => setShowDeletePopup(false)}>No</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {showPrivilegesPopup && (
                    <>
                        <div className={`modal modal-custom fade ${showPrivilegesPopup ? "show" : "false"}  ${showPrivilegesPopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
                            <div className="modal-dialog modal-xl modal-dialog-centered">
                                <div className="modal-content text-start">
                                    <div className="modal-header border-bottom-primary mx-4 px-0">
                                        <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">Privileges</strong></h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowPrivilegesPopup(false)}></button>
                                    </div>
                                    <div className="modal-body pb-5">
                                        <div className="row px-2">
                                            <div className='col-md-12'>
                                                <Loader LoadingReducer={loading} />
                                                <DataTable
                                                    tableData={privilegesTableData}
                                                    TableCols={RolePrivilegesDataGridCols}
                                                    isHidePagination={true}
                                                    onActions={(e: any) => onPrivilegeActions(e)}
                                                    onPageChange={(e: any) => onPageChange(e)}
                                                    onSelectPageChange={(e) => onSelectPage(e)}
                                                    pageCount={pageCount}
                                                />
                                            </div>
                                            <div className='col-md-12 px-2 text-end'>
                                                <button type="button" className="btn btn-primary px-4 btn-sm f16 me-3 border-0" onClick={onSavePrivileges}>Save</button>
                                                <button type="button" className="btn btn-outline-primary px-4 btn-sm f16 text-primary-medium border-primary-medium" onClick={() => setShowPrivilegesPopup(false)}>Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}
