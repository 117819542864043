import React, { useEffect, useState } from 'react';
import { Bar, Doughnut, Pie } from 'react-chartjs-2';
import Chart from "react-apexcharts";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from 'chart.js';

// Register required components
ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title);

export const DashboardContent = () => {
    // Dummy data for Doughnut chart
    const totalFeeds = {
        labels: ['Completed orders', 'Pending orders'],
        datasets: [
            {
                data: [300, 50],
                backgroundColor: ['green', 'red',],
                hoverBackgroundColor: ['green', 'red',]
            }
        ]
    };

    // Dummy data for Pie chart
    const totalPostsPlatForm = {
        labels: ['Daily vehicle search', 'Daily orders'],
        datasets: [
            {
                data: [200, 150],
                backgroundColor: ['blue', 'green'],
                // backgroundColor: ['#FF6384', '#36A2EB'],
                hoverBackgroundColor: ['blue', 'green']
            }
        ]
    };

    const [optionsMixedChart] = useState({
        chart: {
            id: "basic-bar",
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                columnWidth: "50%",
            },
        },
        stroke: {
            width: [4, 0, 0],
        },
        xaxis: {
            categories: [
                "15-06-2024",
                "16-06-2024",
                "17-06-2024",
                "18-06-2024",
                "19-06-2024",
                "20-06-2024",
                "21-06-2024",
                "22-06-2024",
            ],
        },
        markers: {
            size: 6,
            strokeWidth: 3,
            fillOpacity: 0,
            strokeOpacity: 0,
            hover: {
                size: 8,
            },
        },
        yaxis: {
            tickAmount: 5,
            min: 0,
            max: 100,
        },
        plugins: {
            legend: {
                position: "top", // Placing legend at the top
            },
        },
    });

    const [seriesMixedChart, setSeriesMixedChart] = useState([
        {
            name: "New Subscription",
            type: "line",
            data: [30, 40, 25, 50, 49, 21, 70, 51],
        },
        {
            name: "Unsubscription",
            type: "column",
            data: [23, 12, 54, 61, 32, 56, 81, 19],
        },
        {
            name: "Renewals",
            type: "column",
            data: [62, 12, 45, 55, 76, 41, 23, 43],
        },
        {
            name: "Total Revenue",
            type: "column",
            data: [62, 12, 45, 55, 76, 41, 23, 43],
        },
    ]);

    useEffect(() => {
        getDashboardStatistics();
        getDashboardCharts();
    }, [])

    const getDashboardStatistics = () => {
        // setLoading(true);
        // DASHBOARD_STATISTICS(pageCount).then((res: any) => {
        //     if (res?.status === "fail") {
        // toast.error(res.message, { position: 'top-right' })
        //         setLoading(false);
        //     } else {
        //         if (res) {
        //             setTableListData(res?.data);
        //             setLoading(false);
        //         }
        //     }
        // });
    }
    const getDashboardCharts = () => {
        // setLoading(true);
        // DASHBOARD_CHARTS(pageCount).then((res: any) => {
        //     if (res?.status === "fail") {
        // toast.error(res.message, { position: 'top-right' })
        //         setLoading(false);
        //     } else {
        //         if (res) {
        //             setTableListData(res?.data);
        //             setLoading(false);
        //         }
        //     }
        // });
    }

    return (
        <div className='container-fluid content-bg-color'>
            <div className='row mx-auto'>
                <div className='col-md-6 p-3 mx-auto'>
                    <h5 className='text-start'>Daily Orders</h5>
                    <div style={{ height: '300px', margin: 'auto 10%' }} className="text-center d-flex justify-content-center">
                        {totalPostsPlatForm && <Pie data={totalPostsPlatForm} />}
                    </div>
                </div>
                <div className='col-md-6 p-3 mx-auto'>
                    <h5 className='text-start'>Orders</h5>
                    <div style={{ height: '300px', margin: 'auto 10%' }} className="text-center d-flex justify-content-center">
                        {totalFeeds && <Doughnut data={totalFeeds} />}
                    </div>
                </div>
                <div className='col-md-12 my-3'>
                    <h5 className='text-start'>Monthly Orders</h5>
                    <div className="row">
                        <div className="col-md-12">

                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mixed-chart">
                        <div className="card-header text-start py-3 ">
                            <i className="bi bi-postcard"></i>{" "}
                        </div>
                        <Chart
                            series={seriesMixedChart}
                            options={optionsMixedChart}
                            type="line"
                            height={500}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
