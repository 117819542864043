import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import { DynamicForm } from '../../components/form-builder/form';
import Logo from './../../assets/images/logo.svg'
import toast from 'react-hot-toast';
import { FORGOT_PASSWORD } from '../../app/services/common.service';

export const ForgotPassword = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [showOTPPage, setShowOTPPage] = useState<boolean>(false);
    const [otpResponseData, setOtpResponseData] = useState<any>();
    const [otpData, setOtpData] = useState<any>([]);
    const [mobileNumber, setMobileNumber] = useState<any>();
    const [showResetPassword, setShowResetPassword] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
    const navigation = useNavigate();

    const [forgotPasswordFormData, setForgotPasswordFormData] = useState<any>([
        {
            title: "input",
            control: "",
            type: "text",
            label: "Email",
            name: "email",
            value: "",
            required: true,
            regex: /^[a-zA-Z0-9._%+-]+@[a-z]{3,10}.[a-z]{2,5}$/,
            maxLength: "",
            placeholder: "Please enter email",
            error: "Please enter email",
            hideMandatoryStar: false,
        },
        {
            type: "button",
            class: "col-md-12 d-flex justify-content-center my-2 mt-3",
            buttons: [
                {
                    title: 'Send OTP',
                    action: 'add',
                    isCheckFormValidation: true,
                    class: 'btn btn-black border-0 w-25'
                },
            ]
        }
    ]);

    const [resetPasswordFormData, setResetPasswordFormData] = useState<any>([
        {
            title: "input",
            control: "",
            type: "password",
            label: "New Password",
            name: "new_password",
            value: "",
            required: true,
            maxLength: 10,
            regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/,///^[a-zA-Z0-9._%+-]+@[a-z]{3,10}.[a-z]{2,5}$/,
            placeholder: "Please enter new password",
            error: "Please enter new password",
            submitButtonName: "Login",
            hideMandatoryStar: true,
        },
        {
            title: "input",
            control: "",
            type: "password",
            label: "Confirm Your Password",
            name: "confirm_password",
            value: "",
            required: true,
            maxLength: 10,
            regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/,///^[a-zA-Z0-9._%+-]+@[a-z]{3,10}.[a-z]{2,5}$/,
            placeholder: "Please enter confirm password",
            error: "Please enter confirm password",
            submitButtonName: "Login",
            hideMandatoryStar: true,
        },
        {
            type: "button",
            class: "col-md-12 mx-auto d-flex justify-content-center my-2",
            buttons: [
                {
                    title: 'Submit',
                    action: 'add',
                    isCheckFormValidation: true,
                    class: 'btn btn-dark px-4 btn-sm f16 me-1 text-uppercase fw-bold py-1'
                },
            ]
        }
    ]);

    const [otpFormData, setOtpFormData] = useState<any>([
        {
            title: "input",
            control: "",
            type: "text",
            label: "OTP",
            name: "otp_value",
            value: "",
            required: true,
            maxLength: 6,
            regex: /^\d{6}$/,///^[a-zA-Z0-9._%+-]+@[a-z]{3,10}.[a-z]{2,5}$/,
            placeholder: "Please enter otp",
            error: "Please enter otp",
            submitButtonName: "Login",
            hideMandatoryStar: true,
        },
        {
            type: "button",
            class: "col-md-12 mx-auto d-flex justify-content-center my-2",
            buttons: [
                {
                    title: 'Verify OTP',
                    action: 'add',
                    isCheckFormValidation: true,
                    class: 'btn btn-dark px-4 btn-sm f16 me-1 text-uppercase fw-bold py-1'
                },
            ]
        }
    ]);

    const onFormSubmitEvent = (event: any) => {
        const data = event;
        if (data?.type === "add") {
            setEmail(data?.formData?.email)
            const formData = event?.formData;
            formData.reference_uuid = "";
            formData.type = "email";
            formData.mobile_country_code = "";
            formData.mobile_number = "";
            formData.password = "";
            delete formData['undefined']
            console.log("formData : ", formData)
            setLoading(true);
            FORGOT_PASSWORD(formData).then((res: any) => {
                if (res.status === "fail") {
                    toast.error(res.error, { position: "top-right" });
                    setLoading(false);
                    setShowOTPPage(false);
                } else {
                    if (res) {
                        setShowOTPPage(true)
                        console.log("response of otp data : ", res)
                        setOtpResponseData(res?.data)
                        const otpData = otpFormData
                        otpData[0].value = res?.data?.otp_value;
                        setOtpFormData([...otpData])
                        toast.success(res.message, { position: 'top-right', })
                        setLoading(false);
                    }
                }
            });
            // if (formData?.mobile_number) {
            //     setShowOTPPage(true)
            //     toast.success("OTP Sent to you mobile number successfully!", { position: 'top-right' })
            // }
        }
    }

    const onOTPFormSubmitEvent = (event: any) => {
        const data = event;
        if (data?.type === "add") {
            const formData = event?.formData;
            if (formData?.otp_value === otpResponseData?.otp_value) {
                setShowResetPassword(true);
                setShowOTPPage(false);
                toast.success("OTP Verified successfully", { position: 'top-right' })
            } else {
                setShowOTPPage(true);
                setShowResetPassword(false);
                toast.error("Invalid OTP", { position: 'top-right' })
            }
        }
    }

    const onResetPasswordFormSubmitEvent = (event: any) => {
        const data = event;
        if (data?.type === "add") {
            const formData = event?.formData;
            formData.reference_uuid = otpResponseData?.uuid
            formData.type = "email"
            formData.email = email
            formData.mobile_country_code = "";
            formData.mobile_number = "";
            formData.password = formData?.confirm_password;
            delete formData['undefined']
            console.log("form data for email change : ", formData)
            if (formData?.new_password === formData?.confirm_password) {
                // toast.success("Your password updated successfully", { position: 'top-right' })
                // navigation("/");
                setLoading(true);
                FORGOT_PASSWORD(formData).then((res: any) => {
                    if (res.status === "fail") {
                        toast.error(res.error, { position: "top-right" });
                        setLoading(false);
                        setShowResetPassword(true);
                    } else {
                        if (res) {
                            console.log("response of otp data : ", res)
                            toast.success(res.message, { position: 'top-right', })
                            navigation("/");
                            setLoading(false);
                        }
                    }
                });
            } else {
                setShowResetPassword(true);
                toast.error("Both passwords should be same", { position: 'top-right' })
            }
        }
    }

    return (
        <div className="modal" style={{ display: "block" }}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="bg_yellow">
                        <div className="rounded-3 d-flex flex-column justify-content-center">
                            <div className="">
                                {!showOTPPage && !showResetPassword && (
                                    <form className="m-4">
                                        <div className="Login-form-content mt-5">
                                            <h2 className="Login-form-title login_logo text-center fw-bold mt-5 p-5">
                                                <img src={Logo} alt="" className="my-4" />
                                            </h2>
                                            <h4 className="Login-form-title text-center fw-bold my-4">FORGOT PASSWORD</h4>
                                            <DynamicForm
                                                userFormData={forgotPasswordFormData}
                                                columns={1}
                                                isEdit={false}
                                                onFormSubmitEvent={(e) => onFormSubmitEvent(e)}
                                                customClass={"display-block"}
                                                loading={loading}
                                                isNotEmptyFormAfterSubmit={true}
                                            />
                                            <div>
                                                <button className='fw-bold bg-transparent border-0'>Back to <NavLink to="/" className="text-decoration-none"><span className='fw-bold text-primary'>Login</span></NavLink></button>
                                            </div>
                                        </div>
                                    </form>
                                )}
                                {showOTPPage && (
                                    <form className="m-4">
                                        <div className="Login-form-content mt-5">
                                            <h2 className="Login-form-title login_logo text-center fw-bold mt-5 p-5">
                                                <img src={Logo} alt="" className="my-4" />
                                            </h2>
                                            <h4 className="Login-form-title text-center fw-bold my-4">One Time Password (OTP)</h4>
                                            <DynamicForm
                                                userFormData={otpFormData}
                                                columns={1}
                                                isEdit={false}
                                                onFormSubmitEvent={(e) => onOTPFormSubmitEvent(e)}
                                                customClass={"display-block"}
                                                loading={loading}
                                                isNotEmptyFormAfterSubmit={true}
                                            />
                                            <div>
                                                <button className='fw-bold bg-transparent border-0'>Back to <NavLink to="/" className="text-decoration-none"><span className='fw-bold text-primary'>Login</span></NavLink></button>
                                            </div>
                                        </div>
                                    </form>
                                )}
                                {showResetPassword && (
                                    <form className="m-4">
                                        <div className="Login-form-content mt-5">
                                            <h2 className="Login-form-title login_logo text-start fw-bold mt-5 p-5">
                                                <img src={Logo} alt="" className="my-4" />
                                            </h2>
                                            <h4 className="Login-form-title text-center fw-bold my-4">RESET PASSWORD</h4>
                                            <DynamicForm
                                                userFormData={resetPasswordFormData}
                                                columns={1}
                                                isEdit={false}
                                                onFormSubmitEvent={(e) => onResetPasswordFormSubmitEvent(e)}
                                                customClass={"display-block"}
                                                loading={loading}
                                                isNotEmptyFormAfterSubmit={true}
                                            />
                                            {/* <div>
                                                <button className='fw-bold bg-transparent border-0'>Back to <NavLink to="/" className="text-decoration-none"><span className='fw-bold text-primary'>Login</span></NavLink></button>
                                            </div> */}
                                        </div>
                                    </form>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
