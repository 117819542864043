import React, { useEffect, useState } from 'react'
import { OrdersDataGridCols } from './orders-data-grid-cols';
import { DataTable } from '../../../../../../components/data-table';
import { Loader } from '../../../../../../components/loader';
import { Search } from '../../../../../../components/search/search';
import { useNavigate, useParams } from 'react-router-dom';
import { UserVehicleOrdersResponse } from '../../../../../../app/model/user-vehicle-order.response';
import { GetParamsData } from '../../../../../../app/model/common';
import toast from 'react-hot-toast';
import { GET_ORDERS } from '../../../../../../app/services/orders.service';
import { BreadCrumb } from '../../../../../../components/bread-crumb';
import { GET_VEHICLES } from '../../../../../../app/services/vehicles.service';

export const Orders = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [tableListData, setTableListData] = useState<UserVehicleOrdersResponse[]>([]);
    const navigation = useNavigate();
    const params = useParams();
    const [pageCount, setPageCount] = useState<GetParamsData>({
        limit: 10,
        page: 0,
        user_uuid: params?.user_uuid,
        user_vehicle_info_uuid:params?.user_vehicle_uuid
    });

    const [breadcrumbInfo, setBreadcrumbInfo] = useState<any>([
        // {
        //     label: "Users",
        //     value: "Users",
        //     routerLink: "/dashboard/users",
        //     isActive: false,
        // },
        // {
        //     label: "User Name",
        //     value: "Customer : ",
        //     routerLink: `/dashboard/users/${params?.user_uuid}/address`,
        //     isActive: false,
        // },
        {
            label: "Vehicles",
            value: "Vehicles",
            routerLink: `/dashboard/users/${params?.user_uuid}/vehicles`,
            isActive: false,
        },
        {
            label: "Vehicle Name",
            value: "Vehicle : ",
            routerLink: `/dashboard/users/${params?.user_uuid}/vehicles`,
            isActive: false,
        },
        {
            label: "Orders",
            value: "Orders",
            routerLink: `/dashboard/users/${params?.user_uuid}/vehicles/${params?.user_vehicle_uuid}/orders`,
            isActive: true,
        },
    ]);

    const staticTableData = {
        total: 10,
        rows: [
            {
                id: "201",
                order_id: "2302323323",
                price: "1000",
                service: "Active",
                date: "2024-06-25",
                order_status: "Completed",
                tag: "test tag one"
            },
            {
                id: "202",
                order_id: "2302323444",
                price: "1500",
                service: "In Active",
                date: "2024-05-05",
                order_status: "Pending",
                tag: "test tag two"
            },
        ]
    }
    useEffect(() => {
        getUserVehicleOrders(pageCount);
        getUserVehicleDetailsById(params?.user_vehicle_uuid)
    }, [])

    const getUserVehicleOrders = (pageData: any) => {
        setLoading(true);
        GET_ORDERS(pageData).then((res: any) => {
            if (res?.status === "fail") {
                toast.error(res.message, { position: 'top-right' })
                setLoading(false);
            } else {
                if (res) {
                    setTableListData(res?.data);
                    setLoading(false);
                }
            }
        });
    }

    const getUserVehicleDetailsById = (userVehicleUuid: any) => {
        setLoading(true);
        GET_VEHICLES({ uuid: userVehicleUuid }).then((res: any) => {
            if (res?.status === "fail") {
                toast.error(res.message, { position: 'top-right' })
                setLoading(false);
            } else {
                if (res) {
                    const response = res?.data?.data[0]
                    const data = breadcrumbInfo;
                    data[1].value = `${response?.vehicle_vin_number} - ${response?.vehicle_license_plate}`
                    setBreadcrumbInfo([...data])
                    setLoading(false);
                }
            }
        });
    }

    const onPageChange = (data: any) => {
        const pageData = pageCount;
        pageData.limit = data;
        setPageCount(pageData);
        getUserVehicleOrders(pageData);
    };

    const onSelectPage = (data: any) => {
        const pageData = pageCount;
        pageData.page = data;
        setPageCount(pageData);
        getUserVehicleOrders(pageData);
    };

    const onSearch = (value: any) => {
        const pageData = pageCount;
        pageData.search = value;
        setPageCount(pageData);
        getUserVehicleOrders(pageData);
    }

    const onActions = (data: any) => { }

    return (
        <div className='row px-3'>
            <div className='col-md-12 px-4 py-3 fs-5'>
                <BreadCrumb breadCrumbList={breadcrumbInfo}></BreadCrumb>
            </div>
            <div className='col-12 w-100 pt-1'>
                <Search onSearch={(e) => onSearch(e)} customSearchCss={"col-md-3"} />
            </div>
            <div className='col-md-12'>
                <Loader LoadingReducer={loading} />
                <DataTable
                    tableData={tableListData}
                    TableCols={OrdersDataGridCols}
                    onActions={(e: any) => onActions(e)}
                    onPageChange={(e: any) => onPageChange(e)}
                    onSelectPageChange={(e) => onSelectPage(e)}
                    pageCount={pageCount}
                />
            </div>
        </div>
    )
}
