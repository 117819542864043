import { DataTableCol } from "../../../components/data-table/types";

export const AbandonedCartDataGridCols: DataTableCol[] = [
    {
        title: 'Order ID',
        control: 'order_id',
        isLink: true,
        sortable: true,
        canShowColumn: true,
    },
    {
        title: 'Customer Name',
        control: 'customer_name',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Customer Email',
        control: 'customer_email',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Customer Mobile Number',
        control: 'customer_mobile_number',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Service Type',
        control: 'service_type',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Date',
        control: 'created_dt',
        isDateTimeFormat: true,
        sortable: true,
        canShowColumn: true
    },
    // {
    //     title: 'Tag',
    //     control: 'tag',
    //     sortable: true,
    //     canShowColumn: true
    // },
    {
        title: 'Address',
        control: 'address',
        sortable: true,
        canShowColumn: true
    },
    // {
    //     title: 'Flow Status',
    //     control: 'flow_status',
    //     isLink: true,
    //     sortable: true,
    //     canShowColumn: true
    // },
    {
        title: 'Status',
        control: 'status',
        isUserActivityStatus: true,
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Actions',
        control: 'both',
        sortable: false,
        isSend: true,
        isHistory: true,
        isView: false,
        canShowColumn: true
    },
];