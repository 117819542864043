import { DataTableCol } from "../../../../components/data-table/types";

export const ServiceWiseReportsDataGridCols: DataTableCol[] = [
    {
        title: 'Service Name',
        control: 'service_name',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Users Count',
        control: 'users_count',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Price',
        control: 'price',
        sortable: true,
        canShowColumn: true,
        isPrice: true,
    },
    {
        title: 'Status',
        control: 'status',
        isStatus: true,
        sortable: true,
        canShowColumn: true
    },
];