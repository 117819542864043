import { DataTableCol } from "../../../components/data-table/types";

export const DiscountsDataGridCols: DataTableCol[] = [
    {
        title: 'Service Type',
        control: 'service_type',
        sortable: true,
        canShowColumn: true,
    },
    {
        title: 'Discount Type',
        control: 'name',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Start Date',
        control: 'start_dt',
        isDateFormat: true,
        sortable: true,
        canShowColumn: true,
    },
    {
        title: 'End Date',
        control: 'end_dt',
        isDateFormat: true,
        sortable: true,
        canShowColumn: true,
    },
    {
        title: 'Discount Price',
        control: 'discount_price',
        sortable: true,
        canShowColumn: true,
        isPrice: true,
    },
    {
        title: 'Status',
        control: 'status',
        isUserActivityStatus: true,
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Actions',
        control: 'both',
        sortable: false,
        isEdit: true,
        isDelete: true,
        isView: false,
        canShowColumn: true
    },
];