import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { DashboardContent } from '../pages/dashboard/dashboard-content/dashboard-content'
import { Users } from '../pages/dashboard/users/users'
import { Roles } from '../pages/dashboard/roles/roles'
import { Privileges } from '../pages/dashboard/privileges/privileges'
import { Discounts } from '../pages/dashboard/discounts/discounts'
import { Orders } from '../pages/dashboard/orders/orders'
import { AbandonedCart } from '../pages/dashboard/abandoned-cart/abandoned-cart'
import { ReminderTracker } from '../pages/dashboard/reminder-tracker/reminder-tracker'
import { Offers } from '../pages/dashboard/offers/offers'
import { Services } from '../pages/dashboard/services/services'
import { Tickets } from '../pages/dashboard/tickets/tickets'
import { EmailTemplates } from '../pages/dashboard/email-templates/email-templates'
import { Coupons } from '../pages/dashboard/coupons/coupons'
import { Refunds } from '../pages/dashboard/refunds/refunds'
import { AuditLogs } from '../pages/dashboard/audit-logs/audit-logs'
import { ReportsTabInfo } from '../pages/dashboard/reports/reports-tab-info'
import { UserTabInfo } from '../pages/dashboard/users/users-tab-info/user-tab-info'
import { NotFoundPage } from '../pages/dashboard/not-found-page/notfound-page'
import { PriceConfig } from '../pages/dashboard/price-config/price-config'
import { ConfigurationTabsInfo } from '../pages/dashboard/configuration/configuration-tabs-info'
import { Configurations } from '../pages/dashboard/configuration/configurations'
import { OrderDetailsPage } from '../pages/dashboard/abandoned-cart/abandoned-cart-order-details/abandoned-order-details'
import { EmailTemplatesForm } from '../pages/dashboard/email-templates/email-templates-form'
import { EmailTemplatesPage } from '../pages/dashboard/email-templates'


export const DashboardRoutes = () => {
    return (
        <div>
            <Routes>
                <Route path="/" element={<DashboardContent />}></Route>
                <Route path="/view" element={<DashboardContent />}></Route>
                <Route path="/users" element={<Users />}></Route>
                <Route path="/users/:user_uuid/*" element={<UserTabInfo />}></Route>
                <Route path="/roles" element={<Roles />}></Route>
                <Route path="/privileges" element={<Privileges />}></Route>
                <Route path="/discounts" element={<Discounts />}></Route>
                <Route path="/orders" element={<Orders />}></Route>
                <Route path="/abandoned-cart" element={<AbandonedCart />}></Route>
                <Route path="/abandoned-cart/:cart_order_uuid" element={<OrderDetailsPage />}></Route>
                <Route path="/reminder-tracker" element={<ReminderTracker />}></Route>
                <Route path="/offers" element={<Offers />}></Route>
                <Route path="/services" element={<Services />}></Route>
                <Route path="/configuration/*" element={<Configurations />}></Route>
                <Route path="/services/:service_uuid" element={<PriceConfig />}></Route>
                <Route path="/price-config" element={<PriceConfig />}></Route>
                <Route path="/tickets" element={<Tickets />}></Route>
                <Route path="/email-templates/*" element={<EmailTemplatesPage />}></Route>
                {/* <Route path="/email-templates/form" element={<EmailTemplatesForm />}></Route> */}

                <Route path="/coupons" element={<Coupons />}></Route>
                <Route path="/refunds" element={<Refunds />}></Route>
                <Route path="/audit-logs" element={<AuditLogs />}></Route>
                <Route path="/reports/*" element={<ReportsTabInfo />}></Route>
                {/* Catch-all route for unknown URLs */}
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
        </div>
    )
}
