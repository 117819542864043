import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { DataTable } from '../../../../components/data-table';
import { Loader } from '../../../../components/loader';
import { ServiceWiseReportsDataGridCols } from './service-wise-reports-data-grid-cols';
import { Search } from '../../../../components/search/search';
import { ServiceWiseReportsResponse } from '../../../../app/model/service-wise.response';
import { GetParamsData } from '../../../../app/model/common';

export const ServiceWiseReports = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [tableListData, setTableListData] = useState<ServiceWiseReportsResponse[]>([]);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [selectedData, setSelectedData] = useState<any>([]);
    const navigation = useNavigate();
    const [pageCount, setPageCount] = useState<GetParamsData>({
        limit: 10,
        page: 0,
    });


    const staticTableData = {
        total: 10,
        rows: [
            {
                id: "1",
                service_name: "New vehicle registration",
                transaction_id: "1223829823",
                price: "100",
                date: "2024-4-01",
                users_count: "10",
                status: "PENDING"
            },
            {
                id: "2",
                service_name: "Vehicle renewal",
                name: "Ravi",
                transaction_id: "4223829328",
                price: "500",
                date: "2024-5-02",
                users_count: "20",
                status: "COMPLETED"
            },
        ]
    }

    useEffect(() => {
        getServiceWiseReports();
    }, [])

    const getServiceWiseReports = () => {
        // setLoading(true);
        // GET_REFUND_REPORTS(pageCount).then((res: any) => {
        //     if (res?.status === "fail") {
        // toast.error(res.message, { position: 'top-right' })
        //         setLoading(false);
        //     } else {
        //         if (res) {
        //             setTableListData(res?.data);
        //             setLoading(false);
        //         }
        //     }
        // });
    }


    const onActions = (data: any) => {
        setSelectedData(data?.data)
    }

    const onPageChange = (data: any) => {
        const pageData = pageCount;
        pageData.limit = data;
        setPageCount(pageData);
    };

    const onSelectPage = (data: any) => {
        const pageData = pageCount;
        pageData.page = data;
        setPageCount(pageData);
    };

    const onSearch = (value: any) => {
        const pageData = pageCount;
        pageData.search = value;
        setPageCount(pageData);
    }
    
    return (
        <div className='container-fluid content-bg-color'>
            <div className='col-12 w-100 mt-2 pt-2'>
                <Search onSearch={(e) => onSearch(e)} customSearchCss={"col-md-3"} />
            </div>
            <div className='row'>
                <div className='col-md-12'>
                    <Loader LoadingReducer={loading} />
                    <DataTable
                        tableData={staticTableData}
                        TableCols={ServiceWiseReportsDataGridCols}
                        onActions={(e: any) => onActions(e)}
                        onPageChange={(e: any) => onPageChange(e)}
                        onSelectPageChange={(e) => onSelectPage(e)}
                        pageCount={pageCount}
                    />
                </div>
            </div>
        </div>
    )
}
