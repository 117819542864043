import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { EmailTemplates } from '../pages/dashboard/email-templates/email-templates'
import { EmailTemplatesForm } from '../pages/dashboard/email-templates/email-templates-form'

export const EmailTemplatesRoutes = () => {
    return (
        <div>
            <Routes>
                <Route path="/" element={<EmailTemplates />}></Route>
                <Route path="/templates" element={<EmailTemplates />}></Route>
                <Route path="/form" element={<EmailTemplatesForm />}></Route>
                <Route path="/form/:uuid" element={<EmailTemplatesForm />}></Route>
            </Routes>
        </div>
    )
}