import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { PaymentReports } from '../pages/dashboard/reports/payment-reports/payment-reports'
import { GaReports } from '../pages/dashboard/reports/ga-reports/ga-reports'
import { RefundReports } from '../pages/dashboard/reports/refund-reports/refund-reports'
import { ServiceWiseReports } from '../pages/dashboard/reports/service-wise-reports/service-wise-reports'
import { DmvErrorReports } from '../pages/dashboard/reports/dmv-error-reports/dmv-error-reports'

export const ReportTabRoutes = () => {
    return (
        <div>
            <Routes>
                <Route path="/" element={<PaymentReports />}></Route>
                <Route path="/payment-reports" element={<PaymentReports />}></Route>
                <Route path="/ga-reports/*" element={<GaReports />}></Route>
                <Route path="/refund-reports" element={<RefundReports />}></Route>
                <Route path="/service-wise-reports" element={<ServiceWiseReports />}></Route>
                <Route path="/dmv-error-reports" element={<DmvErrorReports />}></Route>
            </Routes>
        </div>
    )
}