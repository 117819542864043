import { DataTableCol } from "../../../components/data-table/types";

export const CouponsDataGridCols: DataTableCol[] = [
    {
        title: 'Coupon Name',
        control: 'name',
        sortable: true,
        // isProfileImage:true,
        canShowColumn: true,
    },
    {
        title: 'Code',
        control: 'code',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Start Date',
        control: 'start_dt',
        isDateFormat: true,
        sortable: true,
        canShowColumn: true,
    },
    {
        title: 'Expiry Date',
        control: 'expiry_date',
        isDateFormat: true,
        sortable: true,
        canShowColumn: true,
    },
    {
        title: 'Status',
        control: 'status',
        isUserActivityStatus: true,
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Actions',
        control: 'both',
        sortable: false,
        isEdit: true,
        isDelete: true,
        isView: false,
        canShowColumn: true
    },
];